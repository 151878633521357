import React from "react";
import PropTypes from "prop-types";
import {Select} from "antd";
import CustomSelect from "../Select";
import MultiSelect from "../MultiSelect";
import {Translate} from "react-localize-redux";
import {tripTypeArray} from "../../../../models/TripType";

const {Option} = Select;

const TripTypeSelect = props => {
    const {onChange, value, disabled, mutliple, allowClear} = props;

    if (mutliple) return <MultiSelect placeholder={<Translate id="common.select.triptypes"/>}
                                      selectedArray={value}
                                      array={tripTypeArray}
                                      handleChange={onChange}/>;
    return (
        <CustomSelect value={value} allowClear={allowClear} placeholder={<Translate id="common.select.triptype"/>}
                      handleChange={onChange}
                      disabled={disabled}>
            {tripTypeArray.map(tt => (
                <Option key={tt.id} value={tt.id}>
                    <Translate id={tt.translateKey}/>
                </Option>
            ))}
        </CustomSelect>
    );
};

TripTypeSelect.defaultProps = {
    disabled: false,
    mutliple: false,
};

// TripTypeSelect.propTypes = {
//     onChange: PropTypes.func.isRequired,
//     value: PropTypes.string,
//     disabled: PropTypes.bool,
//     multiple: PropTypes.bool,
// };

export default TripTypeSelect;
