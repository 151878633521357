import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Translate} from "react-localize-redux";

import {bindActionCreators} from "redux";
import {Select} from "antd";

import CustomSelect from "../Select";
import {requestVehicleById, requestVehicles} from "../../../../actions/actionVehicle";
import MultiSelect from "../MultiSelect";

import {getIdsFromGroupValues} from "../../../../managers/groupManager";
import {maxRowsPerPage, usePagination} from "../../../../hooks/usePagination";

const {Option, OptGroup} = Select;

const VehicleSelect = props => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isPreviousVehicleAvailable, setIsPreviousVehicleAvailable] = useState(true);
    const {page, rowsPerPage} = usePagination(maxRowsPerPage);
    const {onChange, vehicles, value, disabled, multiple, showGroups, filter, tree, allowClear, showArchived, reservationModal, vehicleId} = props;
    const availableVehicles = useSelector(state => state.vehicles.reservation.entities);

    useEffect(() => {
        if (value && !multiple) props.requestVehicleById(value);
    }, [value, multiple]);

    useEffect(() => {
        if (multiple) setSearchTerm("");

    }, [value]);

    useEffect(() => {
         if(reservationModal) { 
            setIsPreviousVehicleAvailable(true);
        }
    }, [vehicleId]);

    useEffect(() => {
        if(reservationModal) {
           setIsPreviousVehicleAvailable(Boolean(availableVehicles.find((availableVehicle) => availableVehicle.id === vehicleId)));
        }
      }, [availableVehicles]);

    useEffect(() => {
        requestVehicles();
    }, [filter, searchTerm, page, rowsPerPage, showArchived]);

    useEffect(() => {
       reservationModal && !isPreviousVehicleAvailable && (onChange(null));

    }, [isPreviousVehicleAvailable]);
    
    function requestVehicles() {
        const groups = filter.groups;
        const filterValueGroup = groups ? {...filter, groups: getIdsFromGroupValues(tree, groups)} : {...filter};
        const filterValueGroupSearchTerm = {
            ...filterValueGroup,
            searchTerm,
            page: page + 1,
            perPage: rowsPerPage,
            IsArchived: showArchived
        };
        props.requestVehicles(filterValueGroupSearchTerm);
    }


    if (multiple) {
        return (
            <MultiSelect placeholder={<Translate id="common.select.vehicles"/>}
                         selectedArray={value}
                         array={vehicles}
                         handleChange={onChange}
                         handleSearch={setSearchTerm}
                         customMinWidth={props.customMinWidth}
                         customMaxTagCount={props.customMaxTagCount} />
        );
    }

    if (showGroups) {
        const carsWithoutDrivebox = vehicles.filter(c => !c.drivebox);
        const carsWithDrivebox = vehicles.filter(c => c.drivebox);
        return (
            <CustomSelect value={value}
                          placeholder={<Translate id="common.select.vehicle"/>}
                          handleChange={onChange}
                          handleSearch={setSearchTerm}
                          allowClear={allowClear}
                          disabled={disabled}>

                <OptGroup label={<Translate id="common.select.withoutDrivebox"/>}>
                    {carsWithoutDrivebox.map(vehicle => (
                        <Option key={vehicle.id} value={vehicle.id}>
                            {vehicle.name}
                        </Option>
                    ))}
                </OptGroup>
                <OptGroup label={<Translate id="common.select.withDrivebox"/>}>
                    {carsWithDrivebox.map(vehicle => (
                        <Option key={vehicle.id} value={vehicle.id}>
                            {vehicle.name}
                        </Option>
                    ))}
                </OptGroup>
            </CustomSelect>
        );
    }

    if(reservationModal) {
        return(<CustomSelect value={isPreviousVehicleAvailable ? vehicleId : null}
                      allowClear={allowClear}
                      placeholder={<Translate id="reservation.addReservationModal.pageTwo.selectVehiclePlaceholder"/>}
                      handleChange={onChange}
                      handleSearch={setSearchTerm}
                      >
            {(availableVehicles.length) && availableVehicles.map(vehicle => {
                    return(<Option key={vehicle.id} value={vehicle.id}>
                        {vehicle.name}
                    </Option>)
                
            })}
        </CustomSelect>)
    }

    return (
        <CustomSelect value={value}
                      allowClear={allowClear}
                      placeholder="Vehicle"
                      handleChange={onChange}
                      handleSearch={setSearchTerm}
                      disabled={disabled}>
            {vehicles.map(vehicle => (
                <Option key={vehicle.id} value={vehicle.id}>
                    {vehicle.name}
                </Option>
            ))}
        </CustomSelect>
    );

};

VehicleSelect.defaultProps = {
    disabled: false,
    multiple: false,
    filter: {},
    showGroups: false,
    showArchived: false,
};

// VehicleSelect.propTypes = {
//     onChange: PropTypes.func,
//     value: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.array
//     ]),
//     disabled: PropTypes.bool,
//     multiple: PropTypes.bool,
//     filter: PropTypes.object,
//     showGroups: PropTypes.bool,
//     allowClear: PropTypes.bool,
//     showArchived: PropTypes.bool,
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({requestVehicles, requestVehicleById}, dispatch);
}

function mapStateToProp(state) {
    return {
        vehicles: state.vehicles.entities,
        tree: state.tree,
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(VehicleSelect);