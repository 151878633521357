const languages = {
    German: "de-AT",
    English: "en",
};

const languageArray = [
    { id: languages.German, name: "German", translateKey: "model.language.german" },
    { id: languages.English, name: "English", translateKey: "model.language.english" }];

export { languages, languageArray };
