import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {Select} from "antd";
import {Translate} from "react-localize-redux";

import CustomSelect from "../Select";
import {requestMandators, requestMandatorById} from "../../../../actions/actionMandators";

const {Option} = Select;

const MandatorSelect = props => {
    const [searchTerm, setSearchTerm] = useState("");
    const {onChange, mandators, value, disabled, allowClear} = props;

    useEffect(() => {
        if (value) props.requestMandatorById(value);
    }, [value]);

    useEffect(() => {
        props.requestMandators({searchTerm, isArchived: false, page: 1, perPage: 20});
    }, [searchTerm]);

    return (
        <CustomSelect value={value}
                      placeholder={<Translate id="common.select.mandators"/>}
                      handleChange={onChange}
                      handleSearch={setSearchTerm}
                      allowClear={allowClear}
                      disabled={disabled}>
            {mandators.map(mandator => (
                <Option key={mandator.id} value={mandator.id}>{mandator.name}</Option>
            ))}
        </CustomSelect>
    );

};

MandatorSelect.defaultProps = {
    disabled: false,
};

// MandatorSelect.propTypes = {
//     onChange: PropTypes.func.isRequired,
//     value: PropTypes.string,
//     disabled: PropTypes.bool,
//     allowClear: PropTypes.bool,
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({requestMandators, requestMandatorById}, dispatch);
}

function mapStateToProp(state) {
    return {
        mandators: state.mandators.entities,
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(MandatorSelect);
