import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { bindActionCreators } from "redux";
import { Select } from "antd";

import CustomSelect from "../Select";
import MultiSelect from "../MultiSelect";
import { requestCostcenters, requestCostcenterById } from "../../../../actions/actionCostcenter";
import {maxRowsPerPage, usePagination} from "../../../../hooks/usePagination";

const { Option } = Select;

const CostCenterSelect = props => {
    const [searchTerm, setSearchTerm] = useState("");
    const {page, rowsPerPage} = usePagination(maxRowsPerPage);
    const { onChange, costcenters, value, filter, disabled, multiple, allowClear, showArchived, reservationModal, onClear } = props;

    const computedCostcenter = costcenters.map(costcenter => {
        if (costcenter.id == undefined) return costcenter;
        costcenter.name = costcenter.foreignId;
        return costcenter.foreignId;
    });

    useEffect(() => {
        for (let index = 0; index < costcenters.length; index++) {
            if(costcenters[index].id === value || costcenters[index].name == value) {
                if (value && !multiple) props.requestCostcenterById(costcenters[index].id);
                reservationModal && onChange(costcenters[index].id);
            }
        }   
    }, [value]);

    useEffect(() => {
        if (multiple) setSearchTerm("");
    }, [value]);

    useEffect(() => requestCostcenters(), [filter, searchTerm, page, rowsPerPage]);
    
    function requestCostcenters() {
        const filterValueGroupSearchTerm = {
            searchTerm,
            IsArchived: showArchived,
            page: page + 1,
            perPage: rowsPerPage,
        };
        props.requestCostcenters(filterValueGroupSearchTerm);
    }

    if (multiple)
        return (
            <MultiSelect placeholder={<Translate id="common.select.costCenters"/>} 
                        selectedArray={value} 
                        array={costcenters}
                        handleChange={onChange}
                        handleSearch={setSearchTerm}>
            </MultiSelect>
        );
        
    return (
        <CustomSelect value={value} 
                      placeholder={<Translate id="common.select.costCenter"/>}
                      handleChange={onChange}
                      disabled={disabled}
                      onClear={onClear && onClear}
                      allowClear={allowClear}
                      handleSearch={setSearchTerm}>
            {costcenters.map(costcenter => (
                <Option key={costcenter.id} value={costcenter.id}>
                    {costcenter.name}           
                </Option>
            ))}
        </CustomSelect>
    );

};

CostCenterSelect.defaultProps = {
    disabled: false,
    multiple: false,
    allowClear:true
};

// CostCenterSelect.propTypes = {
//     onChange: PropTypes.func.isRequired,
//     value: PropTypes.string.isRequired,
//     disabled: PropTypes.bool,
//     multiple: PropTypes.bool,
//     allowClear: PropTypes.bool
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ requestCostcenters, requestCostcenterById }, dispatch);
}

function mapStateToProp(state) {
    return {
        costcenters: state.costcenters.entities,
        costcenter: state.costcenters.current,
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(CostCenterSelect);
