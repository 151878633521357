import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Select} from "antd";
import {Translate} from "react-localize-redux";

import CustomSelect from "../Select";

import { requestConnectorById } from "../../../../actions/qualityCharging/actionConnectors";


const {Option} = Select;

const ConnectorSelect = ({ connectorNumber, setConnectorNumber }) => {
    const dispatch = useDispatch();
    const chargeStation = useSelector(state => state.chargeStations.current);

    useEffect(() => {
        if (connectorNumber !== null && chargeStation?.id) {
            dispatch(requestConnectorById({chargeStationId: chargeStation?.id, connectorNumber: connectorNumber}));
        }
    }, [connectorNumber]);

    return (
        <CustomSelect value={connectorNumber}
                      placeholder={<Translate id="common.select.connectors"/>}
                      handleChange={setConnectorNumber}
                      allowClear={false}
                      >
            {chargeStation?.connectors?.map(connector => (
                <Option key={connector.id} value={connector.number}>{connector.number}</Option>
            ))}
        </CustomSelect>
    );

};

export default ConnectorSelect;
