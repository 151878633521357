import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { Select } from "antd";
import CustomSelect from "../Select";
import { languageArray } from "../../../../models/Language";

const { Option } = Select;

const LanguageSelect = props => {
    const { onChange, value, disabled } = props;
    return (
        <CustomSelect value={value}
                      placeholder={<Translate id="common.select.language"/>}
                      handleChange={onChange}
                      allowClear={false}
                      disabled={disabled}>
            {languageArray.map(l => (
                <Option key={l.id} value={l.id}>
                    <Translate id={l.translateKey}/>
                </Option>
            ))}
        </CustomSelect>
    );
};

LanguageSelect.defaultProps = {
    disabled: false,
};

// LanguageSelect.propTypes = {
//     onChange: PropTypes.func.isRequired,
//     value: PropTypes.string.isRequired,
//     disabled: PropTypes.bool,
// };

export default LanguageSelect;
