import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import { Button, Typography } from "antd";
import ConfirmModal from "../../common/modals/ConfirmModal";
import { differenceInDays } from "date-fns";
import { useModalStatus } from "../../../hooks/useModalStatus";
import { makeStyles } from "@material-ui/core/styles";
import { usePagination } from "../../../hooks/usePagination";
import { setFilter } from "../../../actions/actionFilter";
import {
  requestTrips,
  requestSetReadyToExportByMonth,
} from "../../../actions/actionTrips";
import { requestTripReport } from "../../../actions/actionReport";
import { updateFilterAPICall } from "../../../actions/actionFilter";
import DashboardOpenTripsTable from "./DashboardOpenTripsTable";
import LoadingIndicator from "../../common/LoadingIndicator";

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 275,
    margin: "3px 5px",
    height: "400px",
    overflowY: "scroll",
  },
  title: {
    fontSize: 20,
    margin: "10px",
  },
  div2: {
    float: "right",
  },
  translate: {
    color: "green",
  },
}));

const DashboardOpenTrips = (props) => {
  const classes = useStyles();
  const { filter, date, total, trip, loading } = props;
  const {
    privateTripCount,
    businessTripCount,
    driveToWorkTripCount,
    openTripsCount,
    lockedTripsCount,
    readyForExportCount,
  } = trip;
  const tripCount = privateTripCount + businessTripCount + driveToWorkTripCount;
  const { updatedAt } = filter;
  const { page, rowsPerPage, changePage, changeRowPerPage } = usePagination();

  const {
    modal: releaseModal,
    open: openReleaseModal,
    close: closeReleaseModal,
  } = useModalStatus();
  const exportDayLeft = differenceInDays(Date.parse(date.to), new Date());

  const [hasExported, setHasExported] = useState(false);

  useEffect(() => {
    props.requestTrips({
      ...filter.api,
      from: date.from,
      to: date.to,
      page: page + 1,
      perPage: rowsPerPage,
      isArchived: false,
      isLocked: false,
      isReadyForExport: true,
    });
  }, [page, rowsPerPage, date]);

  useEffect(() => {
    if (hasExported && !loading) {
      closeReleaseModal();
    }
  }, [hasExported, loading]);

  const handleSetReadyToExportTrips = () => {
    props.requestSetReadyToExportByMonth({ from: date.from, to: date.to });
    setHasExported(true);
  };

  const btnReadyForExport =
    (readyForExportCount === tripCount && exportDayLeft <= 0) ||
    (lockedTripsCount - readyForExportCount === 0 && exportDayLeft <= 0) ? (
      <></>
    ) : (
      [
        exportDayLeft <= 0 ? (
          <Button
            onClick={openReleaseModal}
            className={classes.div2}
            variant="contained"
            color="primary"
          >
            <Translate id="dashboard.openTrips.lockTripsForRelease" />
          </Button>
        ) : (
          <div className={classes.div2}>
            In {exportDayLeft} Tagen können Sie dieses Monat zum Export
            freigeben
          </div>
        ),
      ]
    );

  const tripsCountText =
    readyForExportCount === tripCount && readyForExportCount != 0 ? (
      <label className={classes.translate}>
        <Translate
          id="dashboard.openTrips.titleAllReadyForExport"
          data={{ totalExported: readyForExportCount }}
        />
      </label>
    ) : (
      [
        openTripsCount === 0 ? (
          <Translate
            id="dashboard.openTrips.titleNoOpenTrips"
            data={{
              total: tripCount - readyForExportCount,
              totalExported: readyForExportCount,
            }}
          />
        ) : (
          <Translate
            id="dashboard.openTrips.titleOpenTrips"
            data={{
              totalOpen: openTripsCount,
              totalLocked: lockedTripsCount - readyForExportCount,
              totalExported: readyForExportCount,
            }}
          />
        ),
      ]
    );

  const text =
    "Sind Sie sicher, dass Sie alle gesperrten Fahrten für den ausgewählten Monat für den Export freigeben wollen?";
  const loadingText = (
    <>
      <LoadingIndicator />
      Ihre Fahrten werden soeben freigegeben. Bitte schließen Sie das Fenster
      nicht bevor der Prozess beendet ist
    </>
  );

  return (
    <>
      {btnReadyForExport}
      {releaseModal && (
        <ConfirmModal
          open={releaseModal}
          title="Fahrten zum Export freigeben"
          text={loading ? loadingText : text}
          onConfirm={handleSetReadyToExportTrips}
          onClose={closeReleaseModal}
          disabled={loading}
        />
      )}
      <Typography className={classes.title}>{tripsCountText}</Typography>
      <DashboardOpenTripsTable
        page={page}
        rowsPerPage={rowsPerPage}
        archive={false}
        handleChangePage={changePage}
        handleChangeRowsPerPage={changeRowPerPage}
      />
    </>
  );
};

function mapStateToProp(state) {
  return {
    trips: state.trips,
    trip: state.reports.trip,
    filter: state.filter,
    total: state.trips.total,
    loading: state.exportTrips.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setFilter,
      requestTrips,
      requestSetReadyToExportByMonth,
      requestTripReport,
      updateFilterAPICall,
    },
    dispatch
  );
}

export default connect(mapStateToProp, mapDispatchToProps)(DashboardOpenTrips);
