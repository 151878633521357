import { useState, useEffect } from "react";
import {Menu, MenuItem} from "@material-ui/core";
import {connect} from "react-redux";
import {Translate} from "react-localize-redux";

const AddContextMenu = props => {
    const [shouldMenuItemDisabled, setShouldMenuItemDisabled] = useState(true);
    const {anchorEl, onClose, onAddGroup, onAddCar, onAddUser, group, isInMainNode} = props;

    useEffect(() => {
        if(isInMainNode) {
            setShouldMenuItemDisabled(true);
        } else {
            setShouldMenuItemDisabled(!Boolean(group.id));
        }
    }, [isInMainNode]);

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: "top", horizontal: "left"}}
            transformOrigin={{vertical: "top", horizontal: "left"}}
            open={Boolean(anchorEl)}
            onClose={onClose}>
            <MenuItem onClick={onAddGroup}><Translate id="group.contextmenu.addGroup"/></MenuItem>
            <MenuItem onClick={onAddUser} disabled={shouldMenuItemDisabled}><Translate id="group.contextmenu.addUser"/></MenuItem>
            <MenuItem onClick={onAddCar} disabled={shouldMenuItemDisabled}><Translate id="group.contextmenu.addVehicle"/></MenuItem>
        </Menu>
    );
};

// AddContextMenu.propTypes = {
//     anchorEl: PropTypes.string,
//     onClose: PropTypes.func.isRequired,
//     onAddGroup: PropTypes.func.isRequired,
//     onAddCar: PropTypes.func.isRequired,
//     onAddUser: PropTypes.func.isRequired,
// };

function mapStateToProp(state) {
    return {
        group: state.group,
    };
}

export default connect(
    mapStateToProp,
)(AddContextMenu);
