import { Grid, makeStyles, Tooltip, IconButton } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { exchangeLocallyGeofenceVehicles } from '../../../../../actions/actionGeofences';
import ArrowIcon from '@material-ui/icons/Forward';
import { geofenceVehicles } from '../../../../../models/GroupingGeofenceVehicles';
import { Translate } from 'react-localize-redux';

const useStyles = makeStyles(theme => ({
    rotateLeft: {
        transform: "scaleX(-1)",
        fontSize: "50px"
    },
    fontSize: {
        fontSize: "50px"
    }
}));

const ExchangeButtons = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    return <Grid item>
        <Tooltip title={<Translate id="geofenceVehicles.assign" />} placement="top">
            <IconButton
                variant="text"
                color="primary"
                onClick={() => dispatch(exchangeLocallyGeofenceVehicles({
                    fromModule: geofenceVehicles.unAssignedGeofenceVehicles,
                    toModule: geofenceVehicles.assignedGeofenceVehicles
                }))}>
                {/* <Translate id="geofenceVehicles.assign" /> */}
                <ArrowIcon className={classes.fontSize} />
            </IconButton >
        </Tooltip>
        <br />
        <br />
        <Tooltip title={<Translate id="geofenceVehicles.unAssign" />} placement="top">
            <IconButton
                variant="text"
                color="secondary"
                onClick={() => dispatch(exchangeLocallyGeofenceVehicles({
                    fromModule: geofenceVehicles.assignedGeofenceVehicles,
                    toModule: geofenceVehicles.unAssignedGeofenceVehicles
                }))}>
                {/* <Translate id="geofenceVehicles.unAssign" /> */}
                <ArrowIcon className={classes.rotateLeft} />
            </IconButton >
        </Tooltip>
    </Grid>

}

export default ExchangeButtons;