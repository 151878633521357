import React from "react";
import PropTypes from "prop-types";
import {TablePagination} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {Translate} from "react-localize-redux";

const styles = theme => ({
    pageNavigationInformation: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "contents",
        },
    },
});

const GenericTablePagination = ({classes, rowsPerPage, rowCount, page, onChangePage, onChangeRowsPerPage}) => {

    function displayRows(from, to, count, translate) {
        return from + "-" + (to == -1 ? count : to) + " " + translate("common.table.of") + " " + count;
    }

    return (
        <Translate>
            {({translate}) =>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    count={rowCount ? rowCount : 0}
                    labelDisplayedRows={({from, to, count}) => displayRows(from, to, count, translate)}
                    rowsPerPage={rowsPerPage}
                    component="div"
                    labelRowsPerPage={<Translate className={classes.pageNavigationInformation}
                                                 id="common.table.rowPerPage"/>}
                    page={page}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                />
            }
        </Translate>

    );
};

// GenericTablePagination.propTypes = {
//     rowCount: PropTypes.number,
//     rowsPerPage: PropTypes.number.isRequired,
//     page: PropTypes.number.isRequired,
//     onChangePage: PropTypes.func.isRequired,
//     onChangeRowsPerPage: PropTypes.func.isRequired,
// };

export default withStyles(styles, {withTheme: true})(GenericTablePagination);
