
import { Translate } from "react-localize-redux";
import TableTypes from "../../models/TableTypes";

import GenericTable from "../common/table/GenericTable";
import { PAGE_NAME } from "../common/container/Page";

const MultiplePlugoffsTable = () => {
    const columnInformations = [
        {
          title: <Translate id="trip.table.driver" />,
          key: "mappedDriver",
        },
        {
          title: <Translate id="trip.table.vehicle" />,
          key: "mappedVehicle",
        },
        {
          title: <Translate id="trip.table.date" />,
          key: "mappedDate",
          type: TableTypes.date,
        },
        {
          title: <Translate id="trip.table.startAt" />,
          key: "startedAt",
          type: TableTypes.time,
        },
        {
          title: <Translate id="trip.table.finishAt" />,
          key: "finishedAt",
          type: TableTypes.time,
        },
        {
          title: <Translate id="trip.table.duration" />,
          key: "mappedDuration",
          noBr: true,
        },
        {
          title: <Translate id="trip.table.startAddress" />,
          key: "mappedStartAddress",
        },
        {
          title: <Translate id="trip.table.destinationAddress" />,
          key: "mappedDestinationAddress",
        },
        {
          title: <Translate id="trip.table.mileage" />,
          key: "mappedMileage",
          noBr: true,
        },
        {
          title: <Translate id="trip.table.tripType" />,
          key: "mappedTripType",
        } 
      ];
    
      return (
        <GenericTable
          data={[]}
          pageName={PAGE_NAME.faultyTrips}
          columnInformations={columnInformations}
        />
      );
}

export default MultiplePlugoffsTable;