import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { Translate } from "react-localize-redux";

import Button from "@material-ui/core/Button";
import ExportSelect from "../../common/selects/specificSelects/ExportSelect";
import EditModal from "../../common/modals/CustomModal";

import {
  requestTripExportPdf,
  requestTripExportXlsx,
} from "../../../actions/actionExport";

import Grid from "@material-ui/core/Grid";
import { exportFormat } from "../../../models/ExportFormat";
import GroupingSelect from "../../common/selects/specificSelects/GroupingSelect";
import { grouping, groupingArray } from "../../../models/Grouping";
import VehicleSelect from "../../common/selects/specificSelects/VehicleSelect";
import UserSelect from "../../common/selects/specificSelects/UserSelect";
import PeriodDatePicker from "../../common/selects/datePickers/PeriodDatePicker";
import { periods } from "../../../models/Period";
import { updateFilterAPICall } from "../../../actions/actionFilter";
import { fromToByPeriod } from "../../../managers/timeManager";
import { subMonths } from "date-fns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {
  allowedRoles,
  isAuthorized,
  isViewer,
} from "../../../managers/authManager";
import { packages } from "../../../models/Package";
import { roles } from "../../../models/Role";
import CostCenterSelect from "../../common/selects/specificSelects/CostCenterSelect";
import TreeSelect from "../../common/selects/specificSelects/TreeSelect";
import { getIdsFromGroupValues } from "../../../managers/groupManager";

const styles = (theme) => ({
  exportButton: {
    marginTop: theme.spacing(1),
  },
});

const LogBookExportModal = (props) => {
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.filter.api);
  const me = useSelector((state) => state.users.me);
  const tree = useSelector((state) => state.tree);

  const [chosenExportFormat, setExportFormat] = useState(exportFormat.pdf);
  const [chosenGrouping, setGrouping] = useState(grouping.vehicle);
  const [showTimesOfPrivateTrips, setShowTimesOfPrivateTrips] = useState(true);

  const { onClose, onExport, classes } = props;

  const defaultRange = fromToByPeriod(
    periods.month.id,
    subMonths(new Date(), 1)
  );

  useEffect(() => {
    dispatch(
      updateFilterAPICall({
        grouping: chosenGrouping,
        export: chosenExportFormat,
      })
    );
  }, [chosenExportFormat, chosenGrouping]);

  function handleExport() {
    let vehicleIds =
      chosenGrouping === grouping.vehicle ? filter.vehicleIds : undefined;
    let driverIds =
      chosenGrouping === grouping.driver ? filter.driverIds : undefined;
    let costCenterIds =
      chosenGrouping === grouping.costCenter ? filter.costCenterIds : undefined;
    const groupIds =
      chosenGrouping === grouping.groups
        ? getIdsFromGroupValues(tree, filter.groupIds)
        : undefined;

    switch (chosenExportFormat) {
      case exportFormat.exl:
        dispatch(
          requestTripExportXlsx({
            ...filter,
            vehicleIds,
            driverIds,
            costCenterIds,
            groupIds,
            from: filter.from ? filter.from : defaultRange.from.toISOString(),
            to: filter.to ? filter.to : defaultRange.to.toISOString(),
            ShowPrivateTripTimes: showTimesOfPrivateTrips,
          })
        );
        break;
      case exportFormat.pdf:
        dispatch(
          requestTripExportPdf({
            ...filter,
            vehicleIds,
            driverIds,
            costCenterIds,
            groupIds,
            from: filter.from ? filter.from : defaultRange.from.toISOString(),
            to: filter.to ? filter.to : defaultRange.to.toISOString(),
            ShowPrivateTripTimes: showTimesOfPrivateTrips,
          })
        );
        break;
    }
    onExport();
    onClose();
  }

  function handleDateChange(period, from, to) {
    dispatch(
      updateFilterAPICall({
        period,
        from: new Date(from).toISOString(),
        to: new Date(to).toISOString(),
      })
    );
  }

  function handleVehiclesChange(vehicleIds) {
    dispatch(updateFilterAPICall({ vehicleIds }));
  }

  function handleUsersChange(driverIds) {
    dispatch(updateFilterAPICall({ driverIds }));
  }

  function handleGroupsChange(groupIds) {
    dispatch(updateFilterAPICall({ groupIds }));
  }

  function handleCostCentersChange(costCenterIds) {
    dispatch(updateFilterAPICall({ costCenterIds }));
  }

  const costCenterInformation = isAuthorized(me, allowedRoles(roles.Driver), [
    packages.CostCenter,
  ])
    ? chosenGrouping === grouping.costCenter && (
        <CostCenterSelect
          multiple
          showArchived={false}
          onChange={handleCostCentersChange}
          value={filter.costCenterIds}
        />
      )
    : chosenGrouping === grouping.costCenter &&
      "Das Modul Kostenstellen ist nur in DRiVEBOX BASIC+ und DRiVEBOX PRO verfügbar!";

  const content = (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        Zeitraum
      </Grid>

      <Grid item xs={9}>
        <PeriodDatePicker
          period={filter.period || periods.month.id}
          onDateChange={handleDateChange}
          startDate={
            filter.from
              ? new Date(filter.from).toISOString()
              : defaultRange.from
          }
          endDate={
            filter.to ? new Date(filter.to).toISOString() : defaultRange.to
          }
        />
      </Grid>

      <Grid item xs={3}>
        Exportformat
      </Grid>

      <Grid item xs={9}>
        <ExportSelect onChange={setExportFormat} value={chosenExportFormat} />
      </Grid>

      <Grid item xs={3}>
        Gruppierung
      </Grid>

      <Grid item xs={9}>
        <GroupingSelect
          onChange={setGrouping}
          value={chosenGrouping}
          groupArray={groupingArray}
        />
      </Grid>
      <Grid item xs={3}>
        {chosenGrouping === grouping.vehicle && "Fahrzeuge"}
        {chosenGrouping === grouping.driver && "Fahrer"}
        {chosenGrouping === grouping.costCenter && "Kostenstellen"}
        {chosenGrouping === grouping.groups && "Gruppen"}
      </Grid>
      <Grid item xs={9}>
        {chosenGrouping === grouping.vehicle && (
          <VehicleSelect
            multiple
            onChange={handleVehiclesChange}
            value={filter.vehicleIds}
          />
        )}

        {chosenGrouping === grouping.driver && (
          <UserSelect
            multiple
            onChange={handleUsersChange}
            value={filter.driverIds}
          />
        )}

        {costCenterInformation}
        {chosenGrouping === grouping.groups && (
          <TreeSelect
            multiple
            value={filter.groupIds}
            onChange={handleGroupsChange}
          />
        )}
      </Grid>
      <Grid item xs={3}>
        Zeige Zeiten von privaten Fahrten an
      </Grid>
      <Grid item xs={9}>
        <Switch
          checked={showTimesOfPrivateTrips}
          onChange={(e) => setShowTimesOfPrivateTrips(e.target.checked)}
          name="checkedB"
          color="primary"
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={handleExport}
          disabled={
            (chosenGrouping === grouping.vehicle &&
              (!filter.vehicleIds || filter.vehicleIds.length === 0)) ||
            (chosenGrouping === grouping.driver &&
              (!filter.driverIds || filter.driverIds.length === 0)) ||
            (chosenGrouping === grouping.costCenter &&
              (!filter.costCenterIds || filter.costCenterIds.length === 0))
          }
          className={classes.exportButton}
          variant="contained"
          color="primary"
          fullWidth
        >
          <Translate id="trip.exportmodal.export" />
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <EditModal
      onClose={onClose}
      title={<Translate id="trip.exportmodal.title" />}
      contents={[content]}
    />
  );
};

// LogBookExportModal.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   onExport: PropTypes.func.isRequired,
// };

function mapStateToProp(state) {
  return {
    filter: state.filter.api,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { requestTripExportPdf, requestTripExportXlsx },
    dispatch
  );
}

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(LogBookExportModal));
