import React from "react";
import PropTypes from "prop-types";
import {Card, CardHeader, Checkbox, Divider, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Translate} from "react-localize-redux";

const useStyles = makeStyles(theme => ({
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 500,
        height: 500,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    }
}));

const CustomTransferList = props => {
    const classes = useStyles();
    const {title, items, checked, onToggle, onToggleAll, numberOfChecked} = props;

    return (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={onToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{'aria-label': 'Alle ausgewählt'}}
                    />
                }
                title={title}
                subheader={<Translate id="common.list.transfer.selected" 
                                      data={{numberOfChecked:numberOfChecked(items),length:items.length}}/>}
            />
            <Divider/>
            <List className={classes.list} dense component="div" role="list">
                {items.map(value => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem key={value} role="listitem" button onClick={onToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{'aria-labelledby': labelId}}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.name}/>
                        </ListItem>
                    );
                })}
                <ListItem/>
            </List>
        </Card>
    );
};

// CustomTransferList.propTypes = {
//     title: PropTypes.string.isRequired,
//     items: PropTypes.array.isRequired,
//     checked: PropTypes.func.isRequired,
//     onToggle: PropTypes.func.isRequired,
//     onToggleAll: PropTypes.func.isRequired,
//     numberOfChecked: PropTypes.func.isRequired,
// };

export default CustomTransferList;
