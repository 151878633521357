import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect, useDispatch, useSelector} from "react-redux";
import {requestPluggedEvents} from "../../actions/actionDriveboxes";

import CustomModal from "../common/modals/CustomModal";
import {Translate} from "react-localize-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {format} from "date-fns";

const PluggedEventsModal = props => {
    const dispatch = useDispatch();
    const events = useSelector(state => state.driveboxes.events);

    const {onClose, id} = props;

    useEffect(() => {
        if (id) dispatch(requestPluggedEvents(id));
    }, [id]);

    const content = <div>
        <List dense>
            {events.map(e => <ListItem>
                <ListItemText
                    primary={e.name}
                    secondary={format(new Date(e.happenedAt), "dd.MM.yy H:mm")}
                />
            </ListItem>)}

        </List>

        {events.map(e => {

        })}
    </div>;


    return <CustomModal title={<Translate id="drivebox.eventsmodal.title"/>}
                        action="none"
                        contents={[content]}
                        onClose={onClose}/>

};

// PluggedEventsModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     id: PropTypes.string.isRequired,
// };


export default PluggedEventsModal;
