import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import classNames from "classnames"
import { makeStyles } from "@material-ui/core/styles";
import LoadingIndicator from "../../../common/LoadingIndicator";
import { Card, CardActions, CardContent, CardHeader, IconButton, Grid, Typography, Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/HelpOutline";
import { Translate } from "react-localize-redux";

import useResize from "../../../../hooks/useResize";
import FontAwesomeIcon from "../../../common/views/FontAwesomeIcon";

const useStyles = makeStyles(theme => ({
    clickable: {
        cursor: "pointer !important"
    },
    card: {
        minWidth: 275,
        margin: "0 2px",
        padding: "15px 15px 15px 15px",
        minHeight: "72px"
    },
    headerIcon: {
        margin: "15px 15px 15px 15px"
    },
    title: {
        fontSize: props => props.condensed ? 13 : 20,
    },
    itemTitle: {
        fontSize: props => props.condensed ? 11 : 16,
    },
    itemValue: {
        fontSize: props => props.condensed ? 10 : 14,
    },
    icon: {
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        marginRight: 15,
        marginTop: -10
    },
    condensedCardContent: {
        padding: "8px 16px !important"
    },
    text: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    helpIcon: {
        float: "right",
        marginLeft: "auto",
        marginBottom: "auto",
        marginTop: "-8px",
        marginRight: "-15px"
    }
}));

const GeofenceAnalysisHeaderCard = ({ title, value, icon, loading, condensed, from, to, geofences, vehicles }) => {
    const classes = useStyles({ condensed: condensed });
    const ref = useRef(null);
    const { width } = useResize(ref);

    return <div className={classNames(classes.card)}>
        <Card className={classes.card} sx={{ minWidth: 275 }}>
            <CardContent class={classes.title}>
                <FontAwesomeIcon fontSize={30}>far fa-clipboard</FontAwesomeIcon>
                {title}
            </CardContent>
            <CardActions>
                <div class={classes.itemTitle}>
                    {<Translate id="geofenceAnalysis.headercard.geofences" />}
                    <br />
                    <div class={classes.itemValue}>
                        {geofences}
                    </div>
                </div>
            </CardActions>
            <CardActions>
                <div class={classes.itemTitle}>
                    {<Translate id="geofenceAnalysis.headercard.vehicles" />}
                    <br />
                    <div class={classes.itemValue}>
                        {vehicles}
                    </div>
                </div>
            </CardActions>
            <CardActions>
                <div class={classes.itemTitle}>
                    {<Translate id="geofenceAnalysis.headercard.from" />}
                </div>
                <div class={classes.itemValue}>
                    {from}
                </div>
            </CardActions>
            <CardActions>
                <div class={classes.itemTitle}>
                    {<Translate id="geofenceAnalysis.headercard.to" />}
                </div>
                <div class={classes.itemValue}>
                    {to}
                </div>
            </CardActions>
        </Card>
        </div>
};

GeofenceAnalysisHeaderCard.defaultProps = {
    condensed: false
};

GeofenceAnalysisHeaderCard.propTypes = {
    title: PropTypes.object.isRequired,
    value: PropTypes.any,
    icon: PropTypes.object,
    condensed: PropTypes.bool,
    from: PropTypes.string,
    to: PropTypes.string,
    geofences: PropTypes.any,
    vehicles: PropTypes.any,
};

export default (GeofenceAnalysisHeaderCard);
