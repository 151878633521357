import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import GenericTable from "../common/table/GenericTable";
import Comparator from "../../models/Comparator";
import {Translate} from "react-localize-redux";
import {isViewer} from "../../managers/authManager";

const DriveboxTable = props => {
    const {driveboxes, total, page, rowsPerPage, onEdit, handleChangePage, handleChangeRowsPerPage, onPlugged, me} = props;

    const viewer = isViewer(me);

    const columnInformations = [
        {title: <Translate id="drivebox.table.imei"/>, key: "imei"},
        {
            title: <Translate id="drivebox.table.vehicle"/>,
            key: "vehiclename",
            color: {value: "red", comparator: new Comparator(["hasVehicle"], (hasVehicle) => !hasVehicle)},
        },
        {title: <Translate id="drivebox.table.model"/>, key: "model"},
    ];
    const actions = [{title: <Translate id="drivebox.table.edit"/>, event: onEdit}, {
        title: <Translate id="drivebox.table.pluggedEvents"/>, event: onPlugged
    }];

    const mappedDriveBoxes = driveboxes.map(d => {
        d.vehiclename = d.vehicle ? d.vehicle.name : <Translate id="drivebox.table.noVehicleAssigned"/>;
        d.hasVehicle = Boolean(d.vehicle);
        return d;
    });

    return (
        <GenericTable
            data={mappedDriveBoxes}
            columnInformations={columnInformations}
            actions={actions}
            showActions={!viewer}
            rowCount={total}
            pageNavigation
            page={page}
            rowsPerPage={rowsPerPage}
            onEdit={viewer ? () => console.log("") : onEdit}
            onRowClick={viewer ? () => console.log("") : onEdit}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            numSelected={driveboxes.filter(d => d.isSelected).length}
        />
    );
};

DriveboxTable.defaultProps = {
    page: 0,
};

// DriveboxTable.propTypes = {
//     page: PropTypes.number,
//     rowsPerPage: PropTypes.number.isRequired,
//     onEdit: PropTypes.func.isRequired,
//     onPlugged: PropTypes.func.isRequired
// };

function mapStateToProp(state) {
    return {
        driveboxes: state.driveboxes.entities,
        total: state.driveboxes.total,
        me: state.users.me,
    };
}

export default connect(mapStateToProp)(DriveboxTable);
