import React from "react";
import PropTypes from "prop-types";
import {MobileDatePicker, MobileDateTimePicker} from "@material-ui/pickers";
import {Translate} from "react-localize-redux";
import CustomDatePicker from "./CustomDatePicker";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    inputStyle: {
        '& .MuiInputBase-input': {
          color: 'black !important',
          fontWeight: '400 !important'
        },
      },
    dateTimeLabel: {
        '& .MuiTypography-overline': {
            fontSize: '15px'
        },
        '& .MuiDateTimePickerToolbar-timeContainer': {
            marginLeft: '20px',
        },
        '& .MuiTypography-h3': {
            fontSize: '32px'
        }
    }
})
const CustomDateTimePicker = ({views, onChange, value, minDate, disableFuture, disablePast, format, renderDay, labelFunc, variant}) => {
    const viewProp = views && views.length > 0 ? {views: views} : {};
    const classes = useStyles();
    return (
        <MobileDateTimePicker
            {...viewProp}
            value={value}
            minDate={minDate}
            toolbarTitle="Datum auswählen"
            renderInput={props => <TextField style={{width: "100%"}} className={classes.inputStyle}  variant={variant} {...props} />}
            onChange={onChange}
            minDateMessage={<Translate id="common.datePicker.minDateMessage"/>}
            maxDateMessage={<Translate id="common.datePicker.maxDateMessage"/>}
            invalidDateMessage={<Translate id="common.datePicker.invalidDateMessage"/>}
            renderDay={renderDay}
            labelFunc={labelFunc}
            format={format}
            autoOk
            style={{width: "100%"}}
            disablePast={disablePast}
            disableFuture={disableFuture}
            className={classes.dateTimeLabel}
            />
            
    );
};
CustomDateTimePicker.defaultProps = {
    variant: "outlined"
};
// CustomDateTimePicker.propTypes = {
//     views: PropTypes.array.isRequired,
//     value: PropTypes.string,
//     minDate: PropTypes.object,
//     onChange: PropTypes.func,
//     disableFuture: PropTypes.bool,
//     disablePast: PropTypes.bool,
//     minDate: PropTypes.string,
//     format: PropTypes.string,
//     renderDay: PropTypes.element,
//     labelFunc: PropTypes.func,
//     variant: PropTypes.string
// };
export default CustomDateTimePicker;