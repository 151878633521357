import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import { Translate } from "react-localize-redux";
import CustomSelect from "../Select";
import MultiSelect from "../MultiSelect";
import { packageArray } from "../../../../models/Package";

const { Option } = Select;

const PackageSelect = ({ onChange, value, disabled, multiple }) => {
    if (multiple) return <MultiSelect placeholder={<Translate id="common.select.packages"/>} selectedArray={value}
                                      array={packageArray}
                                      handleChange={onChange}/>;
    return (
        <CustomSelect value={value} placeholder={<Translate id="common.select.package"/>} handleChange={onChange}
                      disabled={disabled}>
            {packageArray.map(vp => (
                <Option key={vp.id} value={vp.id}>
                    {vp.name}
                </Option>
            ))}
        </CustomSelect>
    );
};

PackageSelect.defaultProps = {
    disabled: false,
    multiple: false,
};

// PackageSelect.propTypes = {
//     onChange: PropTypes.func.isRequired,
//     value: PropTypes.string.isRequired,
//     disabled: PropTypes.bool,
//     multiple: PropTypes.bool,
// };

export default PackageSelect;
