import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import Modal from "../../common/modals/FormModal";
import { getIdsFromGroupValues } from "../../../managers/groupManager";
import { setFilter } from "../../../actions/actionFilter";
import TreeSelect from "../../common/selects/specificSelects/TreeSelect";
import VehicleProfileSelect from "../../common/selects/specificSelects/VehicleProfileSelect";
import VehicleSelect from "../../common/selects/specificSelects/VehicleSelect";
import FormBuilder from "../../../managers/formBuilder";
import { Translate } from "react-localize-redux";
import { MODAL_TYPES } from "../../common/modals/modal.utils";

const LiveLocationFilterModal = props => {
    const { onClose, tree } = props;
    const { groups, vehicleProfiles, vehicles } = props.filter.initial ? props.filter.initial : {};
    const [fields, setFields] = useState({groups, vehicleProfiles, vehicles});

    const handleInputChange = (name, value) => {
        setFields({ ...fields, [name]: value });
       };

    function handleSave() {
        const apiFilter = {
            vehicles: fields.vehicles,
            vehicleTypes: fields.vehicleProfiles,
            groups: getIdsFromGroupValues(tree, fields.groups),
        };

        props.setFilter(fields, apiFilter);
        onClose();
    }


    const inputs = [
        new FormBuilder.FormInput(<Translate id="livelocation.filtermodal.groups"/>, <TreeSelect value={fields.groups} onChange={(value) => handleInputChange("groups", value)} />)
            .withValue(groups)
            .withKey("groups")
            .build(),
        new FormBuilder.FormInput(<Translate id="livelocation.filtermodal.vehicleProfile"/>, <VehicleProfileSelect value={fields.vehicleProfiles} onChange={(value) => handleInputChange("vehicleProfiles", value)}
            multiple/>)
            .withValue(vehicleProfiles)
            .withKey("vehicleProfiles")
            .build(),
        new FormBuilder.FormInput(<Translate id="livelocation.filtermodal.vehicles"/>, <VehicleSelect multiple value={fields.vehicles} onChange={(value) => handleInputChange("vehicles", value)} />)
            .withValue(vehicles)
            .withKey("vehicles")
            .build(),
    ];

    return <Modal onSave={handleSave} formType={MODAL_TYPES.withoutValidation} onClose={onClose} title={<Translate id="livelocation.filtermodal.title"/>}
                  inputs={inputs}/>;
};

// LiveLocationFilterModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setFilter }, dispatch);
}

function mapStateToProp(state) {
    return { tree: state.tree, filter: state.filter, vehicles: state.vehicles.entities };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(LiveLocationFilterModal);
