import { getTranslation } from "../../translations/translations";
import TableTypes from "../../models/TableTypes";

import GenericTable from "../common/table/GenericTable";
import { PAGE_NAME } from "../common/container/Page";

const MeteringPointTable = ({ meteringPoints }) => {
  const columnInformations = [
    {
      title: getTranslation("qualityCharging.tableItems.meteringPoints.type"),
      key: "type",
    },
    {
      title: getTranslation("qualityCharging.tableItems.meteringPoints.timestamp"),
      key: "timestamp",
      type: TableTypes.dateTime
    },
    {
      title: getTranslation("qualityCharging.tableItems.meteringPoints.value"),
      key: "value",
    },
    {
      title: getTranslation("qualityCharging.tableItems.meteringPoints.context"),
      key: "context",
    },
    {
      title: getTranslation("qualityCharging.tableItems.meteringPoints.location"),
      key: "location",
    },
    {
      title: getTranslation("qualityCharging.tableItems.meteringPoints.phase"),
      key: "phase",
    },
    {
      title: getTranslation("qualityCharging.tableItems.meteringPoints.unit"),
      key: "unit",
    },
    {
      title: getTranslation("qualityCharging.tableItems.meteringPoints.evseId"),
      key: "evseId",
    },
  ];

  return (
    <GenericTable
      data={meteringPoints}
      pageName={PAGE_NAME.qualityCharging}
      columnInformations={columnInformations}
    />
  );
};

export default MeteringPointTable;
