import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import classNames from "classnames"
import {makeStyles} from "@material-ui/core/styles";
import LoadingIndicator from "../../common/LoadingIndicator";
import {Card, CardContent, CardHeader, IconButton, Grid, Typography, Tooltip} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/HelpOutline";

import useResize from "../../../hooks/useResize";
import FontAwesomeIcon from "./FontAwesomeIcon";

const useStyles = makeStyles(theme => ({
    clickable: {
        cursor: "pointer !important"
    },
    card: {
        margin: "0 2px",
        minHeight: "72px"
    },
    title: {
        fontSize: props => props.condensed ? 13 : 20,
    },
    icon: {
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        marginRight: 15,
        marginTop: -10
    },
    condensedCardContent: {
        padding: "8px 16px !important"
    },
    text: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    helpIcon: {
        float: "right",
        marginLeft: "auto",
        marginBottom: "auto",
        marginTop: "-8px",
        marginRight: "-15px"
    }
}));

const ReportCard = ({title, value, icon, loading, condensed, helpText, onClick}) => {
    const classes = useStyles({condensed: condensed});
    const ref = useRef(null);
    const {width} = useResize(ref);

    return <div onClick={onClick} className={classNames(classes.card, {
        [classes.clickable]: typeof onClick !== "undefined",
    })}>
        <Card className={classes.card}>
            {!loading && (
                <div ref={ref}>
                    <CardContent className={classNames({[classes.condensedCardContent]: condensed})}>
                        <Grid container>
                            {width >= 250 && <Grid item className={classes.icon}>
                                {icon}
                            </Grid>}
                            <Grid item className={classes.text}>
                                <Typography variant="h5" component={condensed ? "h5" : "h2"}>
                                    {value}
                                </Typography>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    {title}
                                </Typography>
                            </Grid>
                            {helpText && <div className={classes.helpIcon}>
                                <Tooltip title={helpText}>
                                    <IconButton aria-label="help" size="small">
                                        <FontAwesomeIcon fontSize={17}>far fa-question-circle</FontAwesomeIcon>
                                    </IconButton>
                                </Tooltip>
                            </div>}

                        </Grid>
                    </CardContent>
                </div>
            )}
            {loading && <LoadingIndicator/>}
        </Card>
    </div>
};

ReportCard.defaultProps = {
    condensed: false
};

// ReportCard.propTypes = {
//     title: PropTypes.object.isRequired,
//     value: PropTypes.any,
//     icon: PropTypes.object.isRequired,
//     condensed: PropTypes.bool,
//     helpText: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.object,
//     ]),
//     onClick: PropTypes.func,
// };

export default (ReportCard);
