import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { Translate } from "react-localize-redux";
import { useDispatch } from "react-redux";
import { searchLocallyGeofenceVehicles } from "../../../../../actions/actionGeofences";
import ToolbarSearch from "../../../../common/toolbar/ToolbarSearch";
import GeofenceVehicleList from "./VehicleList.js/GeofenceVehicleList";

const useStyles = makeStyles(theme => ({
    searchStyle: {
        paddingTop: '5px',
        marginLeft: '-7px'
    },
    assignedUnassigned: {
        borderBottom: '2px solid black'
    }
}));

const VehiclesItem = (props) => {
    const dispatch = useDispatch();

    const module = props.module;
    const translationId = props.translationId;
    const classes = useStyles();

    return <Grid item>
        <span className={classes.assignedUnassigned}>
            <Translate id={translationId} />
        </span>
        <div className={classes.searchStyle}>
            <ToolbarSearch onSearch={searchTerm => dispatch(searchLocallyGeofenceVehicles({
                searchTerm,
                module: module
            }))} />
        </div>
        <GeofenceVehicleList module={module} />
    </Grid>
}

export default VehiclesItem;