import { Translate } from "react-localize-redux";

export const getTranslationForStatus = (reservation) => {
    const { state } = reservation; 

    switch(state) {
        case "New":
            return <Translate id="reservation.tableItems.stateValue"  data={{ stateInEnglish: "New" , stateInGerman: "Neu" }}/>
        case "Approved":
            return <Translate id="reservation.tableItems.stateValue"  data={{ stateInEnglish: "Approved" , stateInGerman: "Genehmigt" }}/>
        case "Declined":
            return <Translate id="reservation.tableItems.stateValue"  data={{ stateInEnglish: "Declined" , stateInGerman: "Abgelehnt" }}/>
        default:
            return null;
    }
}