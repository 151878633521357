import React, { useEffect, useState } from "react";
import { menu } from "../../../managers/pathManager";
import Page from '../../common/container/Page';
import { Grid, Card, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Tooltip, makeStyles, Snackbar, Paper, Tabs, Tab } from '@material-ui/core';
import { useModalStatus, useSnackbar } from "../../../hooks/useModalStatus";
import FilterToolbar from "../../common/toolbar/FilterToolbar";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { settingKeys, userKey, vehicleKey } from "../../../managers/localStorageManager";
import DayViewExportModal from "../../reporting/modals/DayViewExportModal";
import { Translate } from "react-localize-redux";
import { Divider } from "rc-menu";
import { usePagination } from "../../../hooks/usePagination";
import TripTable from "../../trips/TripTable";
import { deselectAllTrips, loadTrip, requestLockTrips, requestTrips, selectAllTrips, selectTrip } from "../../../actions/actionTrips";
import { updateFilterAPICall } from "../../../actions/actionFilter";
import { requestAllUsers } from "../../../actions/actionUser";
import { requestAllVehicles, requestOpenTrack, requestVehicleById } from "../../../actions/actionVehicle";
import { requestTripReport } from "../../../actions/actionReport";
import { requestGeofenceSubscriptionDate } from "../../../actions/actionGeofences";
import { roles } from "../../../models/Role";
import { allowedRoles, isAuthorized } from "../../../managers/authManager"; 
import { orderBy } from "../../../models/OrderBy";
import GeofenceToolbar from "./toolbars/GeofenceToolbar";
import { requestGeofencesEventTrips, requestGeofencesNameId, requestGeofencesOfCurrentVehicle } from "../../../actions/actionGeofences"; 
import GeofencesTripIntersectionModal from "./modals/GeofencesTripIntersectionModal";
import { Link } from "react-router-dom";
import { packages } from "../../../models/Package"; 
import StreetviewIcon from '@material-ui/icons/Streetview';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import CarGeofencesModal from "./modals/CarGeofencesModal"; 
import LiveLocationGeofencesModal from "./modals/LiveLocationGeofencesModal";
import { requestLiveLocation, receiveLiveLocation } from "../../../actions/actionLiveLocation";
import GeoFenceAnalysisTooltip from "../generic/GeofenceAnalysisTooltip";

const useStyles = makeStyles(theme => ({
    vehicleCardContainer: {
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    vehicleCard: {
        marginRight: "4px",

    },
    vehicleList: {
        overflowY: "auto",
        maxHeight: "calc( " + window.innerHeight + "px - 270px)",
        height: "calc( " + window.innerHeight + "px - 270px)",
    },
    selectList: {
        borderTop: "1px solid #ccc",
    },
    contentContainer: {
        marginLeft: "1px"
    }
}));

const GeofencesReport = props => {
    const dispatch = useDispatch();

    const filter = useSelector(state => state.filter);
    const users = useSelector(state => state.users.allEntities);
    const geofences = useSelector(state => state.geofences.geofencesNameId);
    const vehicles = useSelector(state => state.vehicles.allEntities);
    const geofenceSubscriptionDate = useSelector(state => state.geofences.geofenceSubscriptionDate);
    const me = useSelector(state => state.users.me);
    const liveVehiclesLocation = useSelector(state => state.lastVehiclesLocation.entities);

    const { api, updatedAt } = filter;

    const classes = useStyles();
    const [geofenceMode, setGeofenceMode] = useState(false);
    const [trip, setTrip] = useState(""); 

    const { modal: exportModal, open: openExportModal, close: closeExportModal } = useModalStatus();
    const { modal: geofenceTripsTrackModal, open: openGeofenceTripsTrackModal, close: closeGeofenceTripsTrackModal } = useModalStatus();
    const { modal: vehicleGeofencesModal, open: openVehicleGeofencesModal, close: closeVehicleGeofencesModal } = useModalStatus();
    const { modal: liveLocationGeofencesModal, open: openLiveLocationGeofencesModal, close: closeLiveLocationGeofencesModal } = useModalStatus();
    const { page, rowsPerPage, changePage, changeRowPerPage } = usePagination();
    const { open: exportSnackbar, show: showExportSnackbar, hide: hideExportSnackbar } = useSnackbar(); 
    const [value, setValue] = React.useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { value: settings, set: setSettings } = useLocalStorage(settingKeys.tripPage, {
        orderBy: orderBy.desc,
        showArchived: false,
    });


    const { value: savedUser, set: setSavedUser } = useLocalStorage(userKey);
    const { value: savedVehicle, set: setSavedVehicle } = useLocalStorage(vehicleKey);

    const [user, setUser] = useState(savedUser ? savedUser : "");
    const [vehicle, setVehicle] = useState(
        (filter.api.tripbookVehicle) ? filter.api.tripbookVehicle : savedVehicle ? savedVehicle : "");
    const [vehicleIdDriver, setVehicleIdDriver] = useState(
        (filter.api.tripbookVehicle) ? filter.api.tripbookVehicle : savedVehicle ? savedVehicle : "");

    const handleGeofenceMode = mode => {
        setGeofenceMode(mode);
    }

    const toolbar = <GeofenceToolbar
        onExport={openExportModal}
        vehicle = {vehicle}
        setVehicle = {setVehicle}
        settings = {settings}
        setSettings = {setSettings}
        geofenceMode={geofenceMode}
        setGeofenceMode={handleGeofenceMode}
    />;


    useEffect(() => {
        if (vehicle) setSavedVehicle(vehicle);
    }, [vehicle]);

    useEffect(() => {
        if (user) setSavedUser(user);
    }, [user]);

    //if current vehicle/user is not loaded, select fist vehicle of list
    useEffect(() => {
        if (!vehicle && vehicles.length > 0) {
            setVehicle(vehicles[0].id);
        }
    }, [vehicles]);

    useEffect(() => {
        if (!user && users.length > 0) {
            setUser(users[0].id);
        }
    }, [users]);

    useEffect(() => {
        requestTrip();
    }, [updatedAt, page, rowsPerPage]);

    useEffect(() => {
        return () => {
            dispatch(deselectAllTrips());
        };
    }, []);

    //preload vehicle or userlist
    useEffect(() => {
        dispatch(requestAllVehicles({ page: 1, perPage: 99999 }));
        dispatch(requestGeofencesNameId())
        if (isAuthorized(me, allowedRoles(roles.GroupLeaderViewer))) {
            dispatch(requestAllUsers({ page: 1, perPage: 99999 }));
        }
    }, []);

    useEffect(() => {
        if (!vehicle && (!api.vehicleIds || api.vehicleIds.length === 0) && vehicles && vehicles.length > 0) {
            setVehicle(vehicles[0].id);
        }
    }, [updatedAt]);

    useEffect(() => {
        if (vehicle && !geofenceMode) dispatch(updateFilterAPICall({ vehicleIds: [vehicle], driverIds: [] }));
        if (vehicle && !geofenceMode) dispatch(requestVehicleById(vehicle));
        if (vehicle && !geofenceMode) dispatch(requestAllVehicles({
            page: 1,
            perPage: 99999
        }));
    }, [vehicle, geofenceMode]);

    useEffect(() => {
        if (user && geofenceMode) dispatch(updateFilterAPICall({ vehicleIds: [], driverIds: [user] }));
        if (user && geofenceMode) dispatch(requestGeofencesNameId());
    }, [user, geofenceMode]);

    useEffect(() => {
        dispatch(requestLiveLocation({}));
        dispatch(requestGeofenceSubscriptionDate());
    }, [])

    useEffect(() => {
        if(isAuthorized(me, allowedRoles(roles.Admin), packages.Geofences) && !liveLocationGeofencesModal && !vehicleGeofencesModal && !geofenceTripsTrackModal) { 
            let liveLocationTimer = setTimeout(() => {
                dispatch(requestLiveLocation({}));
            }, me.liveLocationIntervalInSeconds * 1000 || 30000);
    
            return () => { 
                clearTimeout(liveLocationTimer);
            };
        } 
    }, [liveVehiclesLocation, liveLocationGeofencesModal, vehicleGeofencesModal, geofenceTripsTrackModal])
 

    function handleLockTrip(trip) {
        dispatch(requestLockTrips({ tripIds: [trip.id] }));
    }

    function requestTrip() {
        if (typeof vehicle !== "undefined") {
            if (api.from && api.to && vehicle) {
                dispatch(requestTripReport(api));
                dispatch(requestOpenTrack(vehicle));
                dispatch(requestGeofencesEventTrips({
                    ...api,
                    page: page + 1,
                    perPage: rowsPerPage,
                    orderAsc: settings.orderBy === orderBy.asc
                }));
                
            }
        }
    }


    function getDateWithLocalTimezoneOffset(utcTime) {
        var localtime = new Date(utcTime);
        localtime.setMinutes(localtime.getMinutes() - localtime.getTimezoneOffset());

        return localtime;
    }

    function renderTablePage() {
        return <TripTable
            isInGeofenceReport
            onlyShowBusinessTrips={true}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={changePage}
            handleChangeRowsPerPage={changeRowPerPage}
            onSelect={selectTrip}
            onSelectAll={selectAllTrips}
            onLock={handleLockTrip}
            onTrack={trip => {
                setTrip(trip);
                openGeofenceTripsTrackModal();
            }}
        />
    }
    const geofencesReport = <Tab aria-label="donate" component={Link} to={"/geofences/report"} label="geofencesReport" />;

    return (
        <Page activePage={menu.geofences.key} >
           
            <Paper square style={{ marginTop: '50px' }}>
            {isAuthorized(me, allowedRoles(roles.Admin), packages.Geofences) ? 
                <Tabs
                    value={value}
                    onChange={handleChange}   
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon tabs example"
                >

                    <Tab aria-label="foldertree" component={Link} to={"/geofences"} label="geofences"/>
                    {geofencesReport}
                    <GeoFenceAnalysisTooltip />

                </Tabs>: null
            }
            </Paper> 
            {toolbar}
            <FilterToolbar />
            <Grid container>
                <Grid item md={2} xs={12} className={classes.vehicleCardContainer}>
                    <Card className={classes.vehicleCard}>
                        <List dense className={classes.vehicleList}>
                            {!geofenceMode && vehicles.map((v, i) => {
                                let index = liveVehiclesLocation.findIndex(el => el.id === v.id);
                                let color = "red";
                                if(index != -1) {
                                    color = "orange";
                                    if(liveVehiclesLocation[index].speed > 0) {
                                        color = "green";
                                    }
                                }
                                return (
                                    <ListItem
                                        className={classes.selectList}
                                        button
                                        key={"TripPage#li#" + i}
                                        selected={v.id === vehicle}
                                        onClick={() => {
                                            if (vehicle !== v.id) {
                                                dispatch(loadTrip());
                                                setVehicle(v.id);
                                            }
                                        }}>
                                        <ListItemText
                                            primary={v.name || ""}
                                            secondary={v.licensePlate || ""}
                                        />
                                        <ListItemSecondaryAction>
                                            {isAuthorized(me, allowedRoles(roles.Admin), packages.Geofences) ?
                                                <Tooltip title={<Translate id="common.menu.liveLocation" />}>
                                                    <IconButton edge="start" disabled={color == "red" ? true : false} onClick={() => {
                                                        if (vehicleIdDriver !== v.id) {
                                                            setVehicleIdDriver(v.id);
                                                        }
                                                        openLiveLocationGeofencesModal();
                                                        dispatch(receiveLiveLocation([]));
                                                        dispatch(requestLiveLocation({ vehicles: [v.id] }));
                                                    }}>
                                                        <TrackChangesIcon style={{ color: color }} />
                                                    </IconButton>
                                                </Tooltip> : null}
                                            <Tooltip title={<Translate id="geofenceReport.assignedGeofences" />}>
                                                <IconButton edge="end" onClick={() => {
                                                    if (vehicleIdDriver !== v.id) {
                                                        setVehicleIdDriver(v.id);
                                                    }
                                                    openVehicleGeofencesModal();
                                                }}>
                                                    <StreetviewIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })}
                            {geofenceMode && geofences.map((v, i) =>
                                <ListItem
                                    className={classes.selectList}
                                    button
                                    key={"TripPage#li#" + i}
                                    selected={v.id === user}
                                    onClick={() => {
                                        if (user !== v.id) {
                                            dispatch(loadTrip());
                                            setUser(v.id)
                                        }
                                    }}>
                                    <ListItemText
                                        primary={v.name || ""}
                                        secondary={v.name || ""}
                                    />
                                    <Divider variant="inset" component="li" />
                                </ListItem>
                            )}
                        </List>
                    </Card>
                </Grid>

                <Grid item md={10} xs={12}>
                    <div className={classes.contentContainer}>
                        {renderTablePage()}

                    </div>
                </Grid>
            </Grid>
            {geofenceTripsTrackModal && <GeofencesTripIntersectionModal id={trip.id} vehicleId={trip.vehicle.id} onClose={closeGeofenceTripsTrackModal} />}
            {vehicleGeofencesModal && <CarGeofencesModal  vehicleId={vehicleIdDriver} onClose={closeVehicleGeofencesModal} />}
            {liveLocationGeofencesModal && <LiveLocationGeofencesModal vehicleId={vehicleIdDriver} onClose={closeLiveLocationGeofencesModal} />}
            {exportModal && (
                <DayViewExportModal
                    isTripGeofenceIntersection
                    minDate={getDateWithLocalTimezoneOffset(geofenceSubscriptionDate)}
                    onClose={closeExportModal}
                    onExport={showExportSnackbar} />
            )}
            <Snackbar translateKey="trip.page.snackbarExport"
                onClose={hideExportSnackbar}
                open={exportSnackbar} />

        </Page>
    )
}

export default GeofencesReport;