import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { LayersControl, MapContainer, ScaleControl, TileLayer } from "react-leaflet";
import { createControlComponent } from '@react-leaflet/core'

// import Control from "react-leaflet-control";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import L from "leaflet";
 import { Control, DomUtil } from 'leaflet'

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import LoadingIndicator from "../LoadingIndicator";
import { Translate } from "react-localize-redux";
import ReactDOM from 'react-dom'

import {
    Map as MapIcon,
    Public as WorldIcon,
    Lock as LockIcon,
    LockOpen as UnlockIcon
} from "@material-ui/icons";

import "../../../styling/leaflet.css"
import MapTrack from "./MapTrack";

const { BaseLayer } = LayersControl;

const useStyles = makeStyles(theme => ({
    container: {
        minHeight: "calc(100vh - 420px)",
        height: props => props.containerHeight,
        position: "relative"
    },
    containerModal: {
        height: "calc(100vh - 280px)",
        position: "relative"
    },
    hide: {
        display: "none",
    },
    loading: {
        position: "absolute",
        zIndex: "5000",
        top: "40%",
        left: "50%",
        textAlign: "center",
    },
    mapIcon: {
        backgroundColor: "white",
        borderRadius: "5px",
        cursor: "pointer",
        padding: "2px",
        boxShadow: "0 0 20px #000000a1",
    },
    loadingWrapper: {
        height: "80vh",
        display: "flex",
        justifyContent: "center"
    }
}));

const lockedIcon = '<svg id="svg1" class="MuiSvgIcon-root lockIcon" focusable="false" viewBox="0 0 24 24" aria-hidden="true" aria-label="lock" style=" "><path fill="none" d="M0 0h24v24H0z"></path><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path></svg>';
const unlockedIcon = '<svg class="MuiSvgIcon-root lockIcon" focusable="false" viewBox="0 0 24 24" aria-hidden="true" aria-label="unlock"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z"></path></svg>';

const CustomMap = props => { 
    const [bounds, setBounds] = useState({});
    const [zoom, setZoom] = useState(14);
    const [locked, lockMap] = useState(false);
    const [satelliteViewSelected, setSatelliteViewSelected] = useState(false);
    const [map, setMap] = useState(null); 
    const {
        children, vehicles, modal, showLockAction, automaticHeight, trips, loading, settings, showTrackStartStopMarker, setLeafletMap
    } = props;
    useEffect(() => {
        if (!locked || Object.keys(bounds).length === 0) calculateMapBounds();
    }, [vehicles, trips, locked]);

    const [containerHeight, setContainerHeight] = useState("calc(100vh - 210px)");
    const classes = useStyles({ containerHeight: containerHeight });
    const mapContainerId = "mapContainerId";

    // control for locking
    Control.Lock = Control.extend({ 
        onAdd: function (prop) { 
            const div = DomUtil.create("div", "leaflet-control");
            const a = DomUtil.create("a", "leaflet-control-zoom-out");
            L.DomEvent.addListener(a,"click", changeLock);
            a.title = "Sperren";
            a.innerHTML = locked ? lockedIcon
            : unlockedIcon;
            div.append(a);
            return div;
        },
    
        onRemove: function (map) { 

         },
    
    });

    // control for centralizing
    Control.Centralize = Control.extend({ 
        onAdd: function (prop) { 
            const div = DomUtil.create("div", "leaflet-control")
            const a = DomUtil.create("a", "leaflet-control-zoom-out");
            L.DomEvent.addListener(a,"click", zoomToBounds);
            a.title = "Zentrieren";
            a.innerHTML = '<svg id="svg2" class="MuiSvgIcon-root mapIcon" focusable="false" viewBox="0 0 24 24" aria-hidden="true" aria-label="Zoom to fit"><path fill="none" d="M0 0h24v24H0z"></path><path d="M3 5v4h2V5h4V3H5c-1.1 0-2 .9-2 2zm2 10H3v4c0 1.1.9 2 2 2h4v-2H5v-4zm14 4h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4zm0-16h-4v2h4v4h2V5c0-1.1-.9-2-2-2z"></path></svg>'
            div.append(a);
            return div;
        },
    
        onRemove: function (map) {  
        },
    });
    const [LockControl, setLockScreen] = useState( () => createControlComponent(
        (props) => new Control.Lock({ position: "bottomright" }))
        );
    
    const [CentralizeControl, setCentralizeControl] = useState(()=>createControlComponent(
        (props) => new Control.Centralize({ position: "bottomright" })
    ));
    
    useEffect(() => {
        // need to override default behavior to remove the rectangles that are shown by default in v3 of leaflet
        var icons = document.getElementsByClassName("leaflet-div-icon"); 
        if(icons.length > 0){
            for(let i=0;i<icons.length; i++){
                icons[i].style.background = "transparent";
                icons[i].style.border = "none";
            }
        } 
    }, [bounds])  
    
    const lockSc = useRef(null);
    const centerSc = useRef(null);
    
    // useEffect(() => {
    //     setLockScreen(createControlComponent(
    //         (props) => new Control.Lock({ position: "bottomright" })));
    //         setCentralizeControl(createControlComponent(
    //             (props) => new Control.Centralize({ position: "bottomright" })));
    // }, [/*bounds, locked*/])

    // used for showing at the bottom right the controllers
    // and changing the icon and onclick event manually
    useEffect(() => {
        if (lockSc.current != null) {
            let anchor = lockSc.current._container.firstChild;
            let newA = anchor.cloneNode(true);
            newA.addEventListener('click', changeLock);
            if (locked) {
                newA.innerHTML = lockedIcon;
            } else {
                newA.innerHTML = unlockedIcon;
            }
            anchor.replaceWith(newA);
        }
        if (centerSc.current != null) {
            let domNode2 = ReactDOM.findDOMNode(centerSc?.current._container.firstChild);
            domNode2.addEventListener('click', zoomToBounds);
        }
    }, [locked, bounds])
 

    const changeLock = () => {
        lockMap(!locked);
    }
    const updateMapContainerHeight = () => {
        if (automaticHeight && document.querySelector(`#${mapContainerId}`)) {
            const mapHeight = window.innerHeight - document.querySelector(`#${mapContainerId}`).getBoundingClientRect().y - 40;
            setContainerHeight(mapHeight)
        } else {
            setContainerHeight("calc(100vh - 210px)");
        }
    };

    useLayoutEffect(() => {
        const timeOutKey = setTimeout(updateMapContainerHeight, 200);
        if (automaticHeight) {
            updateMapContainerHeight();
            window.addEventListener("resize", updateMapContainerHeight)
        }
        return () => {
            if (automaticHeight) {
                clearTimeout(timeOutKey);
                window.removeEventListener("resize", updateMapContainerHeight)
            }
        };
    });

    function calculateMapBounds() { 
        const { vehicles } = props;
 
        if (trips && trips.length > 0) {
            const firstTrip = trips[0];
            let positions = mergePositionsOfTracks();

            if (positions.length === 0) {
                positions = [
                    {
                        latitude: firstTrip.startLatitude,
                        longitude: firstTrip.startLongitude
                    },
                    {
                        latitude: firstTrip.destinationLatitude,
                        longitude: firstTrip.destinationLongitude
                    }
                ];
            }

            if (positions &&
                positions.length > 0 &&
                positions[0].latitude &&
                positions[0].longitude) {
                const newBounds = L.latLngBounds(positions.map(position => [position.latitude, position.longitude])).pad(0.1);
                
                // fitting bounds of map only when initially shown  - livelocation
                if(props.showLiveLocations){
                    var fit = map?.fitBounds(newBounds);
                }
                setBounds(newBounds);
            }
        } else if (vehicles && vehicles.length > 0) {
            let resBounds = vehicles.map(pos => ({ lat: pos.latitude, lng: pos.longitude }));
            const newBounds = L.latLngBounds(resBounds).pad(0.1);
            // fitting bounds of map only when initially shown - livelocation    
            if(props.showLiveLocations){ 
              var fit = map?.fitBounds(newBounds);
            } 
            setBounds(newBounds);
        }

        // added for vehicleday which was behaving erratically (sometimes returning trips sometimes not returning!) 
        // TODO:// check if vehicleday is dependent on vehicles also!
        if(trips.length==0 && props.isVehicleDay && !props.isCarGeofences){ 
            setBounds(L.latLngBounds(L.latLng(60, 15), L.latLng(35, 40)));
        }
    }

    // useEffect(() => { 
    //    if(!props.showLiveLocations){
    //         var fit = map?.fitBounds(bounds);
    //     }
    // },[bounds])
    
    function mergePositionsOfTracks() {
        let positions = [];
        for (const trip of trips) {
            if (trip && trip.positions) trip.positions.forEach(position => positions.push(position))
        }
        return positions;
    }

    function zoomToBounds() {
        if (Object.keys(bounds).length !== 0) {
            let m = map?.fitBounds(bounds);
        }
    }

    function onMoveEnd() {
        if (map && map && map.zoomControl) {
            setZoom(map.zoomControl);
        }
    }

    function closePopups() {
        let closedPopups = map?.closePopup();
    }

    const lockIcon = locked
        ? <LockIcon className={classes.mapIcon} onClick={() => lockMap(!locked)} aria-label="lock" />
        : <UnlockIcon className={classes.mapIcon} onClick={() => lockMap(!locked)} aria-label="unlock" />;

    const emptyMapBounds = L.latLngBounds(L.latLng(60, 15), L.latLng(35, 40));
    const boundsToFit = Object.keys(bounds).length || bounds > 0 ? bounds : emptyMapBounds;
    if(!props.showLiveLocations && !props.isCarGeofences ){
        var fit = map?.fitBounds(boundsToFit);
    }

    // useMapEvent("moveend", (e) => {
    //     setZoom(e.target.getZoom());
    // });

    return (
        loading ?
        <div className={classes.loadingWrapper}>
            <LoadingIndicator/>
        </div>
        :
        <div className={modal ? classes.containerModal : classes.container} id={mapContainerId}>
            <div className={clsx({
                [classes.loading]: loading,
                [classes.hide]: !loading,
            })}>
                <LoadingIndicator />
            </div>

            <MapContainer
                style={{ height: "100%" }}
                scrollWheelZoom
                zoom={zoom}
                tap={L.Browser.safari && L.Browser.mobile}
                maxZoom={18}
                doubleClickZoom={false}
                //ref={map}
                bounds={boundsToFit}
                //onmoveend={onMoveEnd}
                whenCreated={(myMap) => {  
                    setMap(myMap)
                    if(setLeafletMap)
                        setLeafletMap(myMap);
                    myMap.zoomControl.remove();

                    if(Object.keys(bounds).length>0 && props.showLiveLocations){ 
                         myMap.fitBounds(bounds);
                     }
                    L.control.zoom({ position: 'bottomright', zoomInTitle: "Zoom in", zoomOutTitle: "Zoom out" }).addTo(myMap);
                }}
            >

                <div className="layers">
                    <div className={classNames("mapIcon", { "hidden-icon": !satelliteViewSelected })}
                        onClick={() => setSatelliteViewSelected(false)}>
                        <MapIcon />
                        <label><Translate id="trip.trackmodal.map" /></label>
                    </div>
                    <div className={classNames("mapIcon", { "hidden-icon": satelliteViewSelected })}
                        onClick={() => setSatelliteViewSelected(true)}>
                        <WorldIcon />
                        <label><Translate id="trip.trackmodal.satellite" /></label>
                    </div>
                </div>

                <LayersControl position="topright">
                    <BaseLayer checked={!satelliteViewSelected} name="Streets">
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </BaseLayer>
                    <BaseLayer checked={satelliteViewSelected} name="Satellite">
                        <TileLayer
                            attribution='Tiles &amp;copy <a href="http://www.esri.com/">Esri Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community</a> contributors'
                            url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        />
                   
                    {<CentralizeControl ref={centerSc}/>}
                    {showLockAction &&
                        <LockControl ref={lockSc}/>
                    }
                    </BaseLayer> 
             

                    {children} 
                    {(trips && trips.length > 0) && trips
                        .sort((t1, t2) => t1.startedAt < t2.startedAt ? -1 : 1)
                        .filter(trip => trip && trip.startAddress && trip.destinationAddress)
                        .map((trip, i) => (
                            <MapTrack
                                key={trip.id}
                                trip={trip}
                                closePopups={closePopups}
                                positions={trip.positions}
                                settings={settings}
                                zoom={zoom}
                                color={trip.color}
                                startAddress={trip.startAddress.street}
                                destinationAddress={trip.destinationAddress.street}
                                showMarker={trip.showMarker || showTrackStartStopMarker}
                                showEndPoint={trip.showEndPoint || (showTrackStartStopMarker && i === trips.length - 1)}
                                showStartPoint={trip.showStartPoint || showTrackStartStopMarker}
                                highlighted={trip.highlighted}
                                alwaysShowMarkers
                            />
                        ))}

                </LayersControl>
                <ScaleControl position="bottomleft" />
            </MapContainer> 
        </div>
    );
};

CustomMap.defaultProps = {
    modal: false,
    trips: [],
    showLockAction: false,
    automaticHeight: true,
    showTrackStartStopMarker: false,
};

// CustomMap.propTypes = {
//     modal: PropTypes.bool,
//     settings: PropTypes.object,
//     trips: PropTypes.array,
//     showLockAction: PropTypes.bool,
//     loading: PropTypes.bool,
//     automaticHeight: PropTypes.bool,
//     showTrackStartStopMarker: PropTypes.bool
// };

function mapStateToProp(state) {
    return {
        vehicles: state.lastVehiclesLocation.entities,
    };
}

export default connect(mapStateToProp)(CustomMap);
