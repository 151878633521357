import React from 'react'
import { BiRename } from 'react-icons/bi';
import DeleteIcon from '@material-ui/icons/Delete';
import ListAltIcon from '@material-ui/icons/ListAlt';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SettingsIcon from '@material-ui/icons/Settings';
import LockIcon from '@material-ui/icons/Lock';
import SearchIcon from '@material-ui/icons/Search';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import { Fade } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import PolygonIcon from '../../../../icons/Polygon.svg';
import CircleIcon from '../../../../icons/Circle.svg';
import RectangleIcon from '../../../../icons/Rectangle.svg';
import EditIcon from '../../../../icons/Edit.svg';
import DirectionsIcon from '@material-ui/icons/Directions';
import Search from '@material-ui/icons/Search';
import { ColorLens, LibraryAdd } from '@material-ui/icons';
import { Translate } from 'react-localize-redux';
import { customColors } from '../../generic/StylingOptions';
import VisibilityIcon from '@material-ui/icons/Visibility';
import StorageIcon from '@material-ui/icons/Storage';

function HelpTooltip(props) {

    const {setPopperKey, navValue} = props;
    const iconFontSize = 30;
    const imageSize = 40;

    return (<div className="helpWrapper" {...props}>
        <div className="helpContent">
            <Fade in={navValue === 0} timeout={0} unmountOnExit>
                <div className="helpPage">
                    <div className="helpRow">
                        <ListAltIcon style={{fontSize: iconFontSize, margin: "5px"}}></ListAltIcon>
                        <div className="helpTooltip"><Translate id="help.history"></Translate></div>
                    </div>
                    <div className="helpRow">
                        <VisibilityIcon style={{fontSize: iconFontSize, margin: "5px"}}></VisibilityIcon>
                        <div className="helpTooltip"><Translate id="help.hide"></Translate></div>
                    </div>
                    <div className="helpRow">
                        <WbIncandescentOutlinedIcon style={{fontSize: iconFontSize, margin: "5px"}}></WbIncandescentOutlinedIcon>
                        <div className="helpTooltip"><Translate id="help.highlight"></Translate></div>
                    </div>
                    <div className="helpRow">
                        <BiRename style={{fontSize: iconFontSize, margin: "5px"}}></BiRename>
                        <div className="helpTooltip"><Translate id="help.rename"></Translate></div>
                    </div>
                    <div className="helpRow">
                        <StorageIcon style={{fontSize: iconFontSize, margin: "5px"}}></StorageIcon>
                        <div className="helpTooltip"><Translate id="help.metadata"></Translate></div>
                    </div>
                    <div className="helpRow">
                        <DeleteIcon style={{fontSize: iconFontSize, margin: "5px"}}></DeleteIcon>
                        <div className="helpTooltip"><Translate id="help.deleteFence"></Translate></div>
                    </div>
                </div>
            </Fade>

            <Fade in={navValue === 1} timeout={0} unmountOnExit>
                <div className="helpPage">
                    <div className="helpRow">
                        <LockIcon style={{fontSize: iconFontSize, margin: "5px"}} />
                        <div className="helpTooltip"><Translate id="help.lock"></Translate></div>
                    </div>
                    <div className="helpRow">
                        <LockOpenIcon style={{fontSize: iconFontSize, margin: "5px"}}></LockOpenIcon>
                        <div className="helpTooltip"><Translate id="help.unlock"></Translate></div>
                    </div>
                    <div className="helpRow">
                        <SwapVertIcon style={{fontSize: iconFontSize, margin: "5px"}}></SwapVertIcon>
                        <div className="helpTooltip"><Translate id="help.toggle"></Translate></div>
                    </div>
                    <div className="helpRow">
                        <DeleteIcon style={{fontSize: iconFontSize, margin: "5px"}}></DeleteIcon>
                        <div className="helpTooltip"><Translate id="help.deleteSelect"></Translate></div>
                    </div>
                    <div className="helpRow">
                        <LibraryAdd style={{fontSize: iconFontSize, margin: "5px"}}></LibraryAdd>
                        <div className="helpTooltip"><Translate id="help.addPreset"></Translate></div>
                    </div>
                    
                    <div className="helpRow">
                        <Search style={{fontSize: iconFontSize, margin: "5px"}}></Search>
                        <div className="helpTooltip"><Translate id="help.search"></Translate></div>
                    </div>
                    <div className="helpRow">
                        <ColorLens style={{fontSize: iconFontSize, margin: "5px"}}></ColorLens>
                        <div className="helpTooltip"><Translate id="help.colorPicker"></Translate></div>
                    </div>
                </div>
            </Fade>

            <Fade in={navValue === 2} timeout={0} unmountOnExit>
                <div className="helpPage">
                <div className="helpRow">
                        <SearchIcon style={{fontSize: iconFontSize, margin: "5px"}}></SearchIcon>
                        <div className="helpTooltip"><Translate id="help.searchPoly"></Translate></div>
                    </div>
                    <div className="helpRow">
                        <img src={PolygonIcon} height={imageSize} width={imageSize} alt="Polygon Icon"></img>
                        <div className="helpTooltip"><Translate id="help.polygon"></Translate></div>
                    </div>
                    <div className="helpRow">
                        <img src={RectangleIcon} height={imageSize} width={imageSize} alt="Rectangle Icon"></img>
                        <div className="helpTooltip"><Translate id="help.rectangle"></Translate></div>
                    </div>
                    <div className="helpRow">
                        <img src={CircleIcon} height={imageSize} width={imageSize} alt="Circle Icon"></img>
                        <div className="helpTooltip"><Translate id="help.circle"></Translate></div>
                    </div>
                    <div className="helpRow">
                        <img src={EditIcon} height={imageSize} width={imageSize} alt="Edit Icon"></img>
                        <div className="helpTooltip"><Translate id="help.edit"></Translate></div>
                    </div>
                    <div className="helpRow">
                        <DirectionsIcon style={{fontSize: iconFontSize, margin: "5px"}}></DirectionsIcon>
                        <div className="helpTooltip"><Translate id="help.route"></Translate></div>
                    </div>
                </div>
            </Fade>
        </div>
        

        <BottomNavigation
            style={{ height: '60px', width: '100%', paddingTop: '5px', backgroundColor: customColors.bright, filter: 'drop-shadow(0 -2px 2px rgba(0,0,0,0.25))' }}
            value={navValue} showLabels onChange={(e, newValue) => {
            setPopperKey(newValue)}}>
            <BottomNavigationAction label={<Translate id="help.creationPoint"></Translate>} value={2} icon={<FormatShapesIcon />} />
            <BottomNavigationAction label={<Translate id="help.listPoint"></Translate>} value={0} icon={<SettingsIcon />} />
            <BottomNavigationAction label={<Translate id="help.lockPoint"></Translate>} value={1} icon={<LockIcon />} />
        </BottomNavigation>
    </div>)

}

export default React.memo(HelpTooltip, isEqual);

function isEqual(prevProps, nextProps) {
    return true;
}