import { Grid, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import Map from '../../../common/map/Map';
import useTrackModalStyle from "../../../trips/tripsModals/useTrackModalStyle";
import CustomModal from '../../../common/modals/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { requestGeofencesOfCurrentVehicle, searchLocallyGeofences } from '../../../../actions/actionGeofences';
import { Typography } from 'antd';
import { Polygon, Tooltip } from 'react-leaflet';
import ToolbarSearch from '../../../common/toolbar/ToolbarSearch';
import L from "leaflet";

const CarGeofencesModal = props => {
    const { onClose, id, vehicleId, isLiveLocation } = props;

    const geofencesOfCurrentVehicle = useSelector(state => state.geofences.geofencesOfCurrentVehicle);
    const dispatch = useDispatch();

    const [geofencesOfVehicle, setGeofencesOfVehicle] = useState();
    // used to fly to bounds
    const [leafletMap, setLeafletMap] = useState();
    const [newBounds, setNewBounds] = useState(null);
    const classes = useTrackModalStyle();


    useEffect(() => {
        dispatch(requestGeofencesOfCurrentVehicle({ vehicleId }))
    }, []);

    useEffect(() => {
        setGeofencesOfVehicle(geofencesOfCurrentVehicle?.geofences);

        if (Object.keys(geofencesOfCurrentVehicle).length !== 0) {
            let notHidden = geofencesOfCurrentVehicle?.geofences?.filter(el => !el.isHidden);

            if (notHidden && notHidden.length > 0) {
                setNewBounds(L.latLngBounds(notHidden
                    .map(geo => geo.coordinates.map(position => [position[0], position[1]]))).pad(0.1));
            }
        }

    }, [geofencesOfCurrentVehicle]);

    if (newBounds && !isLiveLocation) {
        var a = leafletMap?.fitBounds(newBounds);
    }

    const map = <Map isCarGeofences setLeafletMap={setLeafletMap} automaticHeight={false} showTrackStartStopMarker showLiveLocations={isLiveLocation} showLockAction={isLiveLocation}>
        {geofencesOfVehicle?.map(geofence => {
            return <Polygon
                positions={geofence.coordinates}
                //pathOptions={pathOptions}
                key={'poly_' + geofence.id}
                idGeoFence={geofence.id}
            >
                <Tooltip sticky>{geofence.title}</Tooltip>
            </Polygon>
        })}
        {props.children}
    </Map>


    const viewContent = translate => (
        <Grid container className={classes.innerContainer}>
            <Grid item xs={12}>

                <Typography variant="h3" component="h5" style={{ marginTop: "10px" }}>
                    <Translate id="geofenceReport.assignedGeofences" />
                </Typography>
                <div className={classes.searchStyle}>
                    <ToolbarSearch onSearch={searchTerm => {
                        dispatch(searchLocallyGeofences({
                            searchTerm,
                            module: "geofencesOfCurrentVehicle"
                        }))
                    }} />
                </div>
                <Paper>
                    <List>
                        {geofencesOfVehicle?.filter(geofence => !geofence.isHidden).map(geofence =>
                            <ListItem button
                                key={geofence.id}
                                style={{ border: "1px solid gray", borderRadius: "5px", marginBottom: "5px" }}
                                onClick={() => leafletMap?.flyToBounds(geofence.coordinates)}>
                                <ListItemText>{geofence.title}</ListItemText>
                            </ListItem>)}

                    </List>
                </Paper>
            </Grid>
            <Grid item xs={12} className={classes.bottomContainer}>

            </Grid>
        </Grid>
    );

    const contents = [
        <Translate>
            {({ translate }) =>
                <Grid container>
                    <Grid item xs={3} className={classes.leftColumn} style={{ overflow: "auto", height: "calc(100vh - 220px)" }}>
                        {viewContent(translate)}
                    </Grid>
                    <Grid item xs={9} style={{ height: "100%" }}>
                        {map}
                    </Grid>
                </Grid>}
        </Translate>,
    ];


    return <CustomModal largeModal
        onClose={onClose}
        showErrorToolbar={false}
        title={<Translate id="trip.trackmodal.title" />}
        contents={contents}
        className={classes.modal} />;
}

export default CarGeofencesModal;