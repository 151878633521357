import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";

import { Chip, Typography, Tab, Box, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dangerChip: {
    backgroundColor: theme.palette.errorBadge.main,
    color: "white",
    border: 0,
    fontSize: "9.4px",
    fontWeight: "550",
    maxHeight: "20px",
    marginBottom: "2px",
    padding: 0,
    "& .MuiChip-label, & .MuiChip-labelSmall": {
      padding: "0 6px",
    },
    "&:hover": {
      backgroundColor: theme.palette.errorBadge.secondary,
      transition: "background-color 0.3s ease",
    },
  },
  analysisTab: {
    padding: "10px 5px",
  },
  anlysisTabWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
}));

const GeoFenceAnalysisTooltip = () => {
  const classes = useStyles();

  return (
    <Box className={classes.anlysisTabWrapper}>
      <Tab
        className={classes.analysisTab}
        aria-label="donate"
        component={Link}
        to={"/geofences/analysis"}
        label="geofencesAnalysis"
      />
      <Tooltip
        title={
          <Typography variant="body2">
            <Translate id="geofence.geofenceAnalysisBadge" />
          </Typography>
        }
        arrow
      >
        <Chip
          className={classes.dangerChip}
          label="BETA"
          size="small"
          variant="filled"
        />
      </Tooltip>
    </Box>
  );
};

export default GeoFenceAnalysisTooltip;
