import React, { useEffect, useState } from "react";
import Page from "../common/container/Page";
import { menu } from "../../managers/pathManager";
import Toolbar from "../common/toolbar/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import { usePagination } from "../../hooks/usePagination";
import { Button, Snackbar, makeStyles } from "@material-ui/core";
import { useReservationPage } from "./reservationManager";
import MyReservationTable from "./MyReservationTable";
import { useModalStatus } from "../../hooks/useModalStatus";
import ReservationAddModal from "./newReservationAddModal/ReservationAddModal";
import { requestReservations, loadReservations, requestReservationReport } from "../../actions/actionReservation";
import { Translate } from "react-localize-redux";
import { customColors } from "../geofences/generic/StylingOptions";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import DateRangePicker from "../common/selects/datePickers/DateRangePicker";
import { fromToByPeriod } from "../../managers/timeManager";
import { periods } from "../../models/Period";
import FilterReservationsModal from "./FilterReservationsModal";
import Timeline from "../calendar/Timeline";
import ViewTypes from "../calendar/ViewTypes";
import LoadingIndicator from "../common/LoadingIndicator";
import { updateFilterAPICall } from "../../actions/actionFilter";
import { requestVehicles } from "../../actions/actionVehicle";
import { toggleReservationSuccessMsgVisibility } from "../../actions/actionReservation";
import { getTranslation } from "../../translations/translations";
import { ROLES } from "../../models/Rolets";
import DeleteReservationModal from "./DeleteReservationModal";
import { PACKAGES } from "../../models/Packagets";
import EditOrDeleteModal from "./EditOrDeleteModal";
import { RootState } from "../../store";

import { VehicleResponseInfo } from "../../types/vehicle/vehicleResponse.types";

import { PAGE_NAME } from "../common/container/Page";

import { ReservationResponseInfo } from "../../types/reservation/reservationResponse.types";

const useStyles = makeStyles({
  cardSize: {
    height: "100px",
    width: "200px",
    marginTop: "10px",
    marginBottom: "15px",
    marginRight: "10px",
  },
  addResrvationButton: {
    backgroundColor: customColors.ilogsBlue,
    color: "white",
  },
  noReservationContainer: {
    display: "flex",
    minHeight: "50vh",
    justifyContent: "center",
    alignItems: "center",
  },
  successAlert: {
    marginTop: "10px",
  },
});

function ReservationPage() {
  const classes = useStyles();
  const me = useSelector((state: RootState) => state.users.me);
  const error = useSelector((state: RootState) => state.error);
  const vehicles = useSelector((state: RootState) => state.vehicles.entities);

  const mappedVehicles = vehicles.map((v: VehicleResponseInfo) => {
    return { id: v.id, name: v.name };
  });

  const startTime = fromToByPeriod(periods.day.id).from;
  const endTime = fromToByPeriod(periods.day.id).to;

  const { pages, page: reservationPage } = useReservationPage();
  const dispatch = useDispatch();
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateISO = new Date(currentDate).toISOString();
  const reservationDetails = useSelector((state: RootState) => state.reservation);
  const { loading } = reservationDetails;
  const isSuccessMsgVisible = reservationDetails.isSuccessMsgVisible;
  const reservations = reservationDetails.entities;
  const noReservations = reservations.length === 0;
  const totalReservations = reservationDetails.total;
  const activeFilter = reservationDetails.activeFilter;

  let earliestPickupDate = new Date();
  let latestReturnDate= new Date();

  for (const res of reservations) {
    const pickupDate = new Date(res.pickupDate);
    const returnDate = new Date(res.returnDate);

    // Check for the earliest pickup date
    if (!earliestPickupDate || pickupDate < earliestPickupDate) {
      earliestPickupDate = pickupDate;
    }

    // Check for the latest return date
    if (!latestReturnDate || returnDate > latestReturnDate) {
      latestReturnDate = returnDate;
    }
  }

  function reservationTimeLineToolTip(t: ReservationResponseInfo) {
    const pickUpDate = moment(t.pickupDate).format("MMMM Do, YYYY [at] h:mm A");
    const returnDate = moment(t.returnDate).format("MMMM Do, YYYY [at] h:mm A");
    let costCenterSection = '';

    if (me.packages.includes(PACKAGES.CostCenter)) {
      costCenterSection = `
        <div style="display: flex;">
          <p style="margin-right: 5px; font-weight: bold">${getTranslation("reservation.tableItems.costCenter")}:</p>
          ${t.costcenterKey}
        </div>
      `;
    }
  
    return `<div style="width: 350px; padding:10px 10px 0px 10px; word-wrap: break-word">
            <div style="display: flex;">
              <p style="margin-right: 5px; font-weight: bold">${getTranslation("reservation.tableItems.driver")}:</p>
              ${t.driver.firstName}
              ${t.driver.lastName}
            </div>
    
            <div style="display: flex;">
              <p style="margin-right: 5px; font-weight: bold">${getTranslation("reservation.tableItems.vehicle")}:</p>
              ${t.vehicle.name}
            </div>
    
            <div style="display: flex;">
              <p style="margin-right: 5px; font-weight: bold">${getTranslation("reservation.tableItems.pickupDate")}:</p>
              ${pickUpDate}
            </div>
    
            <div style="display: flex;">
              <p style="margin-right: 5px; font-weight: bold">${getTranslation("reservation.tableItems.returnDate")}:</p>
              ${returnDate}
            </div>
    
            <div style="display: flex; margin-bottom: 5px">
              <p style="margin-right: 5px; font-weight: bold">${getTranslation("reservation.tableItems.pickupAddress")}:</p>
              ${t.pickupAddress.displayName}
            </div>
    
            <div style="display: flex; margin-bottom: 5px">
              <p style="margin-right: 5px; font-weight: bold">${getTranslation("reservation.tableItems.returnAddress")}:</p>
              ${t.returnAddress.displayName}
            </div>
    
            <div style="display: flex;">
              <p style="margin-right: 5px; font-weight: bold">${getTranslation("reservation.tableItems.purpose")}:</p>
              ${t.purpose}
            </div>
    
            ${costCenterSection}
    
            <div style="display: flex;">
              <p style="margin-right: 5px; font-weight: bold">${getTranslation("reservation.tableItems.comment")}:</p>
              ${t.comment}
            </div> 
        <div/>`;
  }

  const timeDifference = latestReturnDate.getTime() - earliestPickupDate.getTime();

  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24) + 1);

  const reservationTimelineDataSelector = reservations.map((reservation) => {
    // in the future, this should go to a selector
    return {
      reservation: reservation,
      resource: reservation.vehicle.id,
      id: reservation.id,
      text:
        reservation.driver.firstName.slice(0, 2) +
        "." +
        reservation.driver.lastName.slice(0, 2) +
        ".",
      start: moment(reservation.pickupDate)
        .local()
        .add(1, "minute")
        .format("YYYY-MM-DD HH:mm:ss"),
      end: moment(reservation.returnDate)
        .local()
        .subtract(1, "minute")
        .format("YYYY-MM-DD HH:mm:ss"),
      showPopover: false,
      bubbleHtml: reservationTimeLineToolTip(reservation),
    };
  });

  const { firstName, lastName, role } = me;
  const { page, rowsPerPage, changePage, changeRowPerPage } = usePagination();
  const [vehicleId, setVehicleId] = useState<string>();
  const [activeReservation, setActiveReservation] = useState<ReservationResponseInfo>();
  const [ cancelReservation, setCancelReservation ] = useState<ReservationResponseInfo>();
  const [showAddReservationSnackbar, setShowAddReservationSnackbar] =
    useState(false);
  const [activeButton, setActiveButton] = useState("list");
  const filter = useSelector((state: RootState) => state.filter);
  const { api } = filter;
  const [allowDelete, setAllowDelete] = useState(false);
  const [allowEdit, setAllowEdit] = useState(true);
  const addOneMoreDay = moment(filter.api.to)
  .local()
  .add(24, "hours")
  .format("YYYY-MM-DD HH:mm:ss");

  const fromDate = moment(filter.api.from); 
  const toDate = moment(addOneMoreDay);

  let n = fromDate;
  let daySum = 0;    

  while (n.isBefore(toDate) || n.isSame(toDate, 'day')) {
    daySum++;
    n.add(1, 'days');
  }

  const {
    modal: filterModal,
    open: openFilterModal,
    close: closeFilterModal,
  } = useModalStatus();

  const {
    modal: reservationModalState,
    open: openReservationModal,
    close: closeReservationModal,
  } = useModalStatus();

  const {
    modal: deleteReservationModal,
    open: openDeleteReservationModal,
    close: closeDeleteReservationModal
  } = useModalStatus();

  const {
    modal: askForEditOrDeleteModal,
    open: openaskForEditOrDeleteModal,
    close: closeaskForEditOrDeleteModal
  } = useModalStatus();


  useEffect(() => {
    dispatch(requestReservationReport());
  },[totalReservations])

  useEffect(() => {
    dispatch(
      updateFilterAPICall({
        from: currentDateISO,
        to: currentDateISO,
      })
    );
  },[])

  useEffect(() => {
    dispatch(loadReservations())
    dispatch(
      requestReservations({
        page: page + 1,
        perPage: rowsPerPage,
        isArchived: reservationPage === pages.archive,
        searchTerm: api.searchTerm,
        from: filter.api.from
          ? new Date(filter.api.from).toISOString()
          : new Date(startTime!).toISOString(),
        to: filter.api.to
          ? new Date(addOneMoreDay).toISOString()
          : new Date(endTime!).toISOString(),
      })
    );
    dispatch(requestVehicles({ isArchived: false, perPage: 1000 }));
  }, [reservationPage, api, page, rowsPerPage]);

  useEffect(() => {
    //Hide snackbar on error
    if (error.message) {
      setShowAddReservationSnackbar(false);
    }
  }, [error]);

  function handleSearch(searchTerm: string) {
    dispatch(updateFilterAPICall({ searchTerm }, false));
  }

  const newReservation = (vehicleId?: string) => {
    setAllowEdit(true)
    setVehicleId(vehicleId);
    setActiveReservation(undefined);
    openReservationModal();
  };

  const timeLineEditOrDeleteReservation = (reservation: ReservationResponseInfo) => {
    if(reservation.creatorId !== me.id){
      setAllowEdit(false);
      openReservationModal();
    } else {
      setAllowEdit(true);
      setActiveReservation(reservation);
      openaskForEditOrDeleteModal()
    }
  }

  const updateReservation = (reservation: ReservationResponseInfo) => {
    if(reservation.creatorId === me.id){
      setAllowEdit(true);
      } else {
      setAllowEdit(false)
      };
      setVehicleId(undefined);
      setActiveReservation(reservation);
      openReservationModal();
      };

  const deleteReservation = (reservation: ReservationResponseInfo) => {
    if(reservation.creatorId === me.id) {
      setAllowDelete(true);
      } else {
      setAllowDelete(false)
      }; 
      setCancelReservation(reservation);
      openDeleteReservationModal();
      };

  const reportsArray = [
    {
      reportNumber: reservationDetails.reservationReport.newReservationsCount,
      reportDescription: getTranslation("reservation.newReservations"),
    },
    {
      reportNumber: reservationDetails.reservationReport.reservationsOfCurrentDay,
      reportDescription: getTranslation("reservation.todaysReservations"),
    },
    {
      reportNumber: reservationDetails.reservationReport.reservationsOfCurrentWeek,
      reportDescription: getTranslation("reservation.weekReservations"),
    },
    {
      reportNumber: reservationDetails.reservationReport.reservationsOfCurrentMonth,
      reportDescription: getTranslation("reservation.monthReservations"),
    },
  ];

  function handleDateChange(from: Date, to: Date) {
    dispatch(
      updateFilterAPICall({
        from: new Date(from).toISOString(),
        to: new Date(to).toISOString(),
      })
    );
  }

  function handleActiveButton(active: string) {
    setActiveButton(active);
  }

  const handleSnackbarClose = (_: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAddReservationSnackbar(false);
  };

  function getButtonStyles(buttonName: string) {
    const isActive = activeButton === buttonName;
    const baseStyles = {
      backgroundColor: isActive ? customColors.ilogsBlue : "rgb(246, 246, 246)",
      color: isActive ? "white" : "black",
      borderTopLeftRadius: buttonName === "timeLine" ? 0 : "",
      borderBottomLeftRadius: buttonName === "timeLine" ? 0 : "",
      borderTopRightRadius: buttonName === "list" ? 0 : "",
      borderBottomRightRadius: buttonName === "list" ? 0 : "",
    };
    return baseStyles;
  }

  const pageTitle = getTranslation("reservation.title");
  const user = (role.name === ROLES.Driver || role.name === ROLES.DriverViewer) ? getTranslation("reservation.for") + " " + firstName + " " + lastName : " ";

  const toolbar = (
    <>
      <Toolbar
        title={`${pageTitle} ${user}`}     
        controls={[ 
        <DateRangePicker
        onDateChange={handleDateChange}
        horizontalInputs
        startDate={
          filter.api.from
            ? new Date(filter.api.from).toISOString()
            : currentDateISO
        }
        endDate={
          filter.api.to
            ? new Date(filter.api.to).toISOString()
            : currentDateISO
        }
        disableFuture={false}
        />
      ]}
        filter={openFilterModal}
        searchInput={handleSearch}
        disableToolBarStyle
        activeFilter={activeFilter}
        filterMessage={"Filter activ"}
      >
        <Grid container direction="row">
          {reportsArray.map((item, index) => {
            return (
              <Card key={index} className={classes.cardSize}>
                <CardContent>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <Typography variant="h3">{item.reportNumber ?? 0}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" color="textSecondary">
                        {item.reportDescription}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
        </Grid>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Button
              onClick={() => newReservation(undefined)}
              variant="outlined"
              className={classes.addResrvationButton}
            >
              <Translate id="reservation.addReservationButton" />
            </Button>
          </Grid>

          <Grid item xs={8} container style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={() => handleActiveButton("list")}
              style={getButtonStyles("list")}
            >
              <Translate id="reservation.list"/>
            </Button>
            <Button
              onClick={() => handleActiveButton("timeLine")}
              style={getButtonStyles("timeLine")}
            >
              <Translate id="reservation.timeLine"/>
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </>
  );

  return (
    <Page
      activePage={menu.reservation.key}
      toolbar={toolbar}
      isSuccessMsgVisible={isSuccessMsgVisible}
      successMsg={activeReservation ? <Translate id="successToolbar.reservationUpdated" /> : <Translate id="successToolbar.reservationAdded" />}
      toggleMsgVisibility={toggleReservationSuccessMsgVisibility}
    >
      {loading ? (
        <div className={classes.noReservationContainer}>
          <LoadingIndicator />
        </div>
      ) : noReservations ? (
        <div className={classes.noReservationContainer}>
          <Typography variant="h5">
            <Translate id="reservation.noReservation" />
          </Typography>
        </div>
      ) : activeButton === "list" ? (
        <MyReservationTable
          reservations={reservations}
          handleChangeRowsPerPage={changeRowPerPage}
          rowsPerPage={rowsPerPage}
          handleChangePage={changePage}
          page={page}
          onEdit={updateReservation}
          totalReservations={totalReservations}
          onDelete={deleteReservation}
          pageName={PAGE_NAME.reservation}
        />
      ) : daySum > 60 ?
       <div className={classes.noReservationContainer}>
          <Typography variant="h5">
            <Translate id="reservation.reservationSumMessage" />
          </Typography>
        </div>
      :
      (
        <Timeline
          items={reservationTimelineDataSelector}
          groups={mappedVehicles}
          from={earliestPickupDate}
          viewType={ViewTypes.Custom}
          countCustomDays={daysDifference}
          onTrip={timeLineEditOrDeleteReservation}
        />
      )}
      {reservationModalState && (
        <ReservationAddModal
          reservation={activeReservation}
          onClose={closeReservationModal}
          allowEdit={allowEdit}
        />
      )}
      { deleteReservationModal && (
        <DeleteReservationModal
          data={cancelReservation}
          onClose={closeDeleteReservationModal}
          allowDelete={allowDelete}
        />
      )}
      {showAddReservationSnackbar && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message="Eine neue Reservierung wurde angelegt."
        />
      )}
      {filterModal && <FilterReservationsModal onClose={closeFilterModal} />}
      {askForEditOrDeleteModal &&
        <EditOrDeleteModal 
        onUpdate={updateReservation}
        onDelete={deleteReservation}
        reservation={activeReservation}
        onClose={closeaskForEditOrDeleteModal} />
      }
    </Page>
  );
}

export default ReservationPage;
