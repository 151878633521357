import React from "react";
import { Link, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { menu } from "../../../managers/pathManager";
import LoadingIndicator from "../LoadingIndicator";
import { Fade, Grid } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import PropTypes from "prop-types";

const LoadingPage = props => {
    const { authorised, me, children } = props;
    const isLoggedIn = authorised && Object.entries(me).length;

    return (
        <div>
            {!authorised && <Redirect to={menu.login.path}/>}
            {isLoggedIn && children}
            {!isLoggedIn && (
                <Grid container justify="center" direction="column" alignItems="center">
                    <Grid item style={{ marginTop: "15%" }}>
                        <LoadingIndicator/>
                    </Grid>
                    <Grid item>
                        <h4><Translate id="common.loading"/></h4>
                    </Grid>
                    <Grid item>
                        <Fade in timeout={30000}>
                            <Link to={menu.login.path}>Sry somthing went wrong, go back to login</Link>
                        </Fade>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

// LoadingPage.propTypes = {
//     children: PropTypes.object.isRequired,
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

function mapStateToProp(state) {
    return {
        me: state.users.me,
        error: state.error,
        authorised: state.authorised,
        authentication: state.authentication,
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(LoadingPage);
