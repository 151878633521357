import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
    progress: {
        margin: theme.spacing(2),
    },
    center: {
        margin: "auto",
    },
});

const LoadingIndicator = ({ classes }) => {
    return (
        <div className={classes.center}>
            <CircularProgress className={classes.progress}/>
        </div>
    );
};

// LoadingIndicator.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

export default withStyles(styles)(LoadingIndicator);
