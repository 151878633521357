import React from "react";
import PropTypes from "prop-types";

import { InboxOutlined } from "@ant-design/icons";
import { Result } from "antd";
import { Button } from "@material-ui/core";
import { Translate } from "react-localize-redux";

const EmptyResult = ({ onClose, onAdd, title }) => {
  return (
    <Result
      icon={<InboxOutlined />}
      title={title || <Translate id="common.view.emptyResult.noEntriesFound" />}
      extra={[
        onClose && (
          <Button variant="contained" onClick={onClose} color="primary">
            <Translate id="common.view.emptyResult.close" />
          </Button>
        ),
        onAdd && (
          <Button variant="contained" onClick={onAdd} color="primary">
            <Translate id="common.view.emptyResult.add" />
          </Button>
        ),
      ]}
    />
  );
};

// EmptyResult.propTypes = {
//   onClose: PropTypes.func,
//   onAdd: PropTypes.func,
//   title: PropTypes.string,
// };

export default EmptyResult;
