const TableTypes = {
    string: "string",
    date: "date",
    time: "time",
    dateTime: "dateTime",
    role: "role",
    group: "group",
    package: "package",
    preferredGeolocationServiceProvider: "preferredGeolocationServiceProvider",
    averageFuel: "averageFuel",
    fuelAtStart: "fuelAtStart",
    fuelAtFinish: "fuelAtFinish",
    engineType: "engineType"
};

Object.freeze(TableTypes);
export default TableTypes;
