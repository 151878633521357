import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { Select, Spin, Tag } from "antd";

const CustomSelect = ({ placeholder, children, handleChange, handleSearch, value, disabled, allowClear, notFound, onClear }) => {
    return (
        <Select
            allowClear={allowClear}
            clearIcon={
                <Tag style={{ marginTop: "-20px" }} onClick={onClear && onClear} color="red">
                    <Translate id="common.select.delete"/>
                </Tag>
            }
            showSearch
            disabled={disabled}
            value={value}
            style={{ width: "100%", minWidth: "150px", color: 'black'}}
            placeholder={placeholder}
            optionFilterProp="children"
            onChange={handleChange}
            onSearch={handleSearch && handleSearch}
            defaultActiveFirstOption={false}
            notFoundContent={notFound ? <Spin size="small"/> : false}
            filterOption={false}
            dropdownMatchSelectWidth={false}
        >
            {children}
        </Select>
    );
};

CustomSelect.defaultProps = {
    disabled: false,
    allowClear: true,
};

// CustomSelect.propTypes = {
//     placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), 
//     handleChange: PropTypes.func,
//     handleSearch: PropTypes.func,
//     disabled: PropTypes.bool,
//     allowClear: PropTypes.bool,
//     notFound: PropTypes.bool,
// };

export default CustomSelect;
