import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import { addDays } from "date-fns";
import { Typography, makeStyles } from "@material-ui/core";
import CustomDatePicker from "./CustomDatePicker";
import { Translate } from "react-localize-redux";

import { CustomDatePickerProps } from "./date-picker.utils";

const styles = theme => ({
    rangePicker: {
        float: "left",
        display: "inline-block",
    },
    leftPeriodChooserPicker: {
        marginRight: "5px",
    },
});


const horizontalInputsStyles = makeStyles({
    horizontalInputWidth :{
        width: 110
    },
    horizontalInputText:{
        marginRight:5,
        marginLeft: 12
    }
})

const DateRangePicker = props => {

    const { minDate, startDate, endDate, classes, disableFuture = true, horizontalInputs, allowDateTime } = props;
    const today = new Date();
    const minDateValue = (typeof minDate !== "undefined") ? minDate : new Date(2016, 0, 1);
    const styles = horizontalInputsStyles();

    if(horizontalInputs){
        return (
            <div  style={{display:"flex", alignItems: "center"}}>
                <Typography className={styles.horizontalInputText}><Translate id="dateRangePicker.from"/></Typography>
                <div className={styles.horizontalInputWidth}>

                <CustomDatePicker minDate={minDateValue} maxDate={endDate} onChange={e => props.onDateChange(e, endDate)}
                    value={startDate} disableFuture={disableFuture} {...CustomDatePickerProps} style={{width: "100%"}}/>
                </div>
                <Typography className={styles.horizontalInputText} ><Translate id="dateRangePicker.to"/></Typography>
                <div className={styles.horizontalInputWidth}>
                <CustomDatePicker minDate={minDateValue}
                    maxDate={addDays(new Date(today.getFullYear(), today.getMonth(), today.getDate()), 1)}
                    onChange={e => props.onDateChange(startDate, e)}
                    value={endDate} disableFuture={disableFuture} {...CustomDatePickerProps} style={{width: "100%"}} />
                </div>
            </div>
        );
    } else {
        return (
            <div className={classes.rangePicker}>
                <CustomDatePicker minDate={minDateValue} maxDate={endDate} onChange={e => props.onDateChange(e, endDate)}
                    className={classes.leftPeriodChooserPicker} value={startDate} disableFuture={disableFuture} allowDateTime={allowDateTime} style={{width: "100%"}} {...CustomDatePickerProps}  />
                <CustomDatePicker minDate={startDate}
                    maxDate={addDays(new Date(today.getFullYear(), today.getMonth(), today.getDate()), 1)}
                    onChange={e => props.onDateChange(startDate, e)}
                    value={endDate} disableFuture={disableFuture} allowDateTime={allowDateTime} {...CustomDatePickerProps} style={{width: "100%"}} />
            </div>
        );
    }
  
};

export default withStyles(styles)(DateRangePicker);
