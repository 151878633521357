import React from "react";
import {connect, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Card, CardContent, Grid, Switch, Typography} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import {secondsToString} from "../../../managers/timeManager";
import ReportCard from "../../common/views/ReportCard";
import {bindActionCreators} from "redux";
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import {Translate} from "react-localize-redux";
import PropTypes from "prop-types";
import FontAwesomeIcon from "../../common/views/FontAwesomeIcon";

const useStyles = makeStyles({
    toolbar: {
        margin: "0 3px 4px 3px",
    },
    card: {
        marginTop: "2px",
        marginBottom: "2px",
    },
    cardContent: {
        padding: "8px 16px !important"
    },
    icon: {
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        marginRight: 15,
        marginTop: -10
    },
    changeModeButton: {
        marginTop: "auto",
        marginLeft: "auto",
    }
});

const TripSummaryReports = props => {
    const classes = useStyles(props);
    const {trip, mode, onDriver, onVehicle, onOpenTrack} = props;
    const openTrip = useSelector(state => state.vehicles.openTrip);
    const me = useSelector(state => state.users.me);
    const {mileageInMeters, businessTripCount, privateTripCount, driveToWorkTripCount, timeInSeconds, driverCount, vehicleCount} = trip;
    const tripCount = businessTripCount + privateTripCount + driveToWorkTripCount;

    const renderRunningTrip = (!me.isHWNOE) ?
            <ReportCard
                condensed
                title={<Translate id="trip.cards.runningTrip"/>}
                loading={false}
                onClick={openTrip.positions.length > 0 ? onOpenTrack : undefined}
                value={openTrip.positions.length > 0 ? <Translate id="trip.cards.currentTripConfirm"/> :
                    <Translate id="trip.cards.currentTripDenie"/>}
                icon={<FontAwesomeIcon fontSize={30} color="secondary">far fa-route</FontAwesomeIcon>}
            /> : 
            <ReportCard
                condensed
                title={<Translate id="trip.cards.runningTrip"/>}
                loading={false}
                value={openTrip.positions.length > 0 ? <Translate id="trip.cards.currentTripConfirm"/> :
                    <Translate id="trip.cards.currentTripDenie"/>}
                icon={<FontAwesomeIcon fontSize={30} color="secondary">far fa-route</FontAwesomeIcon>}
            />;

    return (
        <Grid container className={classes.toolbar} spacing={0}>
            <Grid item xs={12} sm={4} md={2} className={classes.card}>
                <ReportCard
                    condensed
                    title={mode ? <Translate id="trip.cards.driver"/> : <Translate id="trip.cards.vehicle"/>}
                    loading={typeof mileageInMeters == "undefined"}
                    value={mode ? props.user.firstName + " " + props.user.lastName : props.vehicle.name}
                    icon={
                        <FontAwesomeIcon fontSize={30}
                                         color="secondary">{mode ? "far fa-user" : "far fa-car"}</FontAwesomeIcon>
                    }
                />
            </Grid>
            <Grid item xs={12} sm={4} md={2} className={classes.card}>
                <ReportCard
                    condensed
                    title={<Translate id="trip.cards.trips"/>}
                    loading={typeof businessTripCount == "undefined"}
                    value={tripCount}
                    icon={<FontAwesomeIcon fontSize={30} color="secondary">far
                        fa-book</FontAwesomeIcon>}
                />
            </Grid>
            <Grid item xs={12} sm={4} md={2} className={classes.card}>
                <ReportCard
                    condensed
                    title={<Translate id="trip.cards.mileage"/>}
                    loading={typeof mileageInMeters == "undefined"}
                    value={Math.round(mileageInMeters / 1000)}
                    icon={<FontAwesomeIcon fontSize={30} minWidth={34} color="secondary">fal
                        fa-road</FontAwesomeIcon>}
                />
            </Grid>
            <Grid item xs={12} sm={4} md={2} className={classes.card}>
                <ReportCard
                    condensed
                    title={<Translate id="trip.cards.drivingTime"/>}
                    loading={typeof mileageInMeters == "undefined"}
                    value={secondsToString(timeInSeconds) || 0}
                    icon={<FontAwesomeIcon fontSize={30} color="secondary">far fa-stopwatch</FontAwesomeIcon>}
                />
            </Grid>

            <Grid item xs={12} sm={4} md={2} className={classes.card}>
                {renderRunningTrip}
            </Grid>
            {mode && <Grid item xs={12} sm={4} md={2} className={classes.card}>
                <ReportCard
                    condensed
                    onClick={vehicleCount ? onVehicle : undefined}
                    title={<Translate id="trip.cards.vehicle"/>}
                    loading={typeof vehicleCount == "undefined"}
                    value={vehicleCount || 0}
                    icon={<FontAwesomeIcon fontSize={30} color="secondary">far fa-car</FontAwesomeIcon>}
                />
            </Grid>}

            {!mode && <Grid item xs={12} sm={4} md={2} className={classes.card}>
                <ReportCard
                    condensed
                    onClick={driverCount ? onDriver : undefined}
                    title={<Translate id="trip.cards.driver"/>}
                    loading={typeof driverCount == "undefined"}
                    value={driverCount || 0}
                    icon={<FontAwesomeIcon fontSize={30} color="secondary">far fa-user</FontAwesomeIcon>}
                />
            </Grid>}
        </Grid>

    );
};

// TripSummaryReports.propTypes = {
//     mode: PropTypes.bool,
//     onDriver: PropTypes.func.isRequired,
//     onVehicle: PropTypes.func.isRequired,
//     onOpenTrack: PropTypes.func.isRequired,
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {},
        dispatch,
    );
}

function mapStateToProp(state) {
    return {
        trip: state.reports.trip,
        idleTime: state.reports.idleTime,
        vehicle: state.vehicles.current,
        user: state.users.current,
    };
}

export default connect(mapStateToProp, mapDispatchToProps)(TripSummaryReports);
