import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestGeofencesNameId } from "../../../../actions/actionGeofences";
import React from "react";
import { Translate } from "react-localize-redux";
import MultiSelect from "../MultiSelect";

const GeofenceSelect = (props) => {
    const [searchTerm, setSearchTerm] = useState("");

    const {value,  onChange} = props;
    const geofences = useSelector(state => state.geofences.geofencesNameId);
    const dispatch = useDispatch();

    useEffect(() => {
        setSearchTerm("");
    }, [value]);

    useEffect(() => {
        getGeofencesWhenSearchTermChanges();
    }, [searchTerm]);

    function getGeofencesWhenSearchTermChanges() {
        dispatch(requestGeofencesNameId({searchTerm}))
    }


    return (
        <MultiSelect placeholder={<Translate id="common.select.geofences" />}
            selectedArray={value}
            array={geofences}
            handleChange={onChange}
            handleSearch={setSearchTerm} 
            customMinWidth={props.customMinWidth}
            customMaxTagCount={props.customMaxTagCount} />
    )
}

export default GeofenceSelect;