import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import classNames from "classnames";
import { menu } from "../../../managers/pathManager";
//actions
import { requestLogout } from "../../../actions/actionAuthentication";
import { allowedRoles, isAuthorized } from "../../../managers/authManager";
import { roles } from "../../../models/Role";
// react-router
import { Link } from "react-router-dom";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
// Icons
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import EmailIcon from "@material-ui/icons/Email";
//Drawer
import Drawer from "./AppBarDrawer";
import { customColors } from "../../geofences/generic/StylingOptions";
import IconTextItem from "./IconTextItem";

import OrderBoxModal from "../modals/OrderBoxModal";
import OrderMoreBoxModal from "../modals/OrderMoreBoxModal";

const driveBoxContactInfo = {
  email: "office@drivebox.at",
  formattedPhoneNumber: "+43 463 26 77 82",
};

const drawerWidth = 240;

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "& .MuiToolbar-gutters": {
      paddingRight: 14,
    },
    "& .MuiToolbar-root": {
      paddingRight: 14,
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  brandName: {
    display: "flex",
    alignItems: "center",
  },
  emptyDrawer: {
    width: "25px",
  },
  disabledDrawerGrow: {
    marginLeft: "70px",
  },
  menuButton: {
    marginLeft: 4,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  sectionDesktop: {
    display: "flex",
  },
  helpIcon: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08) !important",
      color: "white !important",
    },
  },
  orderButton: {
    backgroundColor: "white",
    color: "black",
    height: "30px",
    alignSelf: "center",
    border: "1px solid black",
    cursor: "pointer",
    marginLeft: "10px",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: customColors.ilogsBlue,
      color: "white",
      borderColor: "white",
    },
  },
});

class CustomAppBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      anchorEl: null,
      isOrderBoxModalOpen: false,
    };

    this.openOrderBoxModal = this.openOrderBoxModal.bind(this);
    this.closeOrderBoxModal = this.closeOrderBoxModal.bind(this);
  }

  openOrderBoxModal() {
    this.setState({ isOrderBoxModalOpen: true });
  }

  closeOrderBoxModal() {
    this.setState({ isOrderBoxModalOpen: false });
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    this.setState({ anchorEl: null });
    this.props.requestLogout();
  };

  render() {
    const { me, classes, activePage, disableDrawer } = this.props;

    const { anchorEl, isOrderBoxModalOpen } = this.state;
    const isMenuOpen = Boolean(anchorEl);

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        {!disableDrawer && (
          <MenuItem
            component={Link}
            to={menu.settings.path}
            onClick={this.handleMenuClose}
          >
            Profile
          </MenuItem>
        )}
        <MenuItem
          component={Link}
          to={menu.login.path}
          onClick={this.handleLogout}
        >
          Logout
        </MenuItem>
      </Menu>
    );
    const renderOrderButton = () => {
      if (!me.isHWNOE && isAuthorized(me, [roles.Admin])) {
        return (
          <Button
            className={classes.orderButton}
            onClick={this.openOrderBoxModal}
          >
            <Translate id="common.menu.orderMore" />
          </Button>
        );
      }
    };

    return (
      <div>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar
            disableGutters={!this.state.open}
            className={classes.toolbar}
          >
            {disableDrawer && <div className={classes.disabledDrawerGrow} />}
            <Box display="flex">
              {!disableDrawer && (
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.handleDrawerOpen}
                  className={classNames(classes.menuButton, {
                    [classes.hide]: this.state.open,
                  })}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Fade in={!this.state.open} className={classes.brandName}>
                <Typography
                  className={classes.title}
                  variant="h6"
                  color="inherit"
                  noWrap
                >
                  DRiVEBOX
                </Typography>
              </Fade>
            </Box>
            <div className={classes.sectionDesktop}>
              {isAuthorized(me, allowedRoles(roles.AdminViewer)) && (
                <Box display="flex">
                  <IconTextItem
                    icon={EmailIcon}
                    text={driveBoxContactInfo.email}
                  />
                  <IconTextItem
                    icon={PhoneInTalkIcon}
                    text={driveBoxContactInfo.formattedPhoneNumber}
                    phoneNumber
                  />
                </Box>
              )}
              {renderOrderButton()}
              <Button
                aria-haspopup="true"
                target="_blank"
                href="https://drivebox.atlassian.net/servicedesk/customer/portals"
                color="inherit"
              >
                FAQ
              </Button>
              <IconButton
                aria-owns={isMenuOpen ? "material-appbar" : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {!disableDrawer && (
          <Drawer
            open={this.state.open}
            onDrawerClose={this.handleDrawerClose}
            activePage={activePage}
          />
        )}
        {disableDrawer && <div className={classes.emptyDrawer} />}
        {isOrderBoxModalOpen && (
          <OrderMoreBoxModal onClose={this.closeOrderBoxModal} isOpen={isOrderBoxModalOpen} currentUser={me} />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestLogout,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    me: state.users.me,
    authorised: state.authorised,
  };
}

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CustomAppBar));
