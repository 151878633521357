import { IconButton, Tooltip } from '@material-ui/core';
import DirectionsIcon from '@material-ui/icons/Directions';
import { customColors } from '../../generic/StylingOptions';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withLocalize, Translate } from 'react-localize-redux';
import { objAreEqual } from '../../utils/comparisonUtils';
import { useDispatch } from 'react-redux';
import { requestUpdateGeofenceVehicles } from '../../../../actions/actionGeofences';

const baseUrl = window.location.origin + "/api";

function RoutingToolBar(props) {
    let { selectedRoute, currentUser, setRouteMode, routeController,
        setRouteController, setDialog, addGeoFenceInState } = props;

    let [extended, setExtended] = useState(false);
    const dispatch = useDispatch();

    function toggleRoutingToolbar() {
        setExtended((x) => !x);
        setRouteMode((x) => !x);
    }

    function finishRouteDrawing() {
        try {
            setDialog({
                geofenceId: true,
                isOpen: true,
                type: 'select',
                title: props.translate('dialogs.createRoadTitle'),
                label: props.translate('dialogs.createRoadLabel'),
                defaultText: '',
                isAntiTheftEnabled: false,
                notificationEmail: '',
                notificationMobileNumber: '',
                btnConfirm: props.translate('dialogs.buttons.create'),
                confirm: (newTitle, selectedItem, isAntiTheft, notificationEmail, notificationMobileNumber) => {
                    let newObj = {
                        title: newTitle,
                        isAntiTheftEnabled: isAntiTheft,
                        notificationEmail: notificationEmail,
                        notificationMobileNumber: notificationMobileNumber,
                        points: selectedRoute.coordinates,
                        username: currentUser
                    }

                    let newStr = JSON.stringify(newObj);
                    newStr = newStr.replace(/"lng":/g, '"long":');

                    // create polygon with API
                    axios({
                        method: 'post',
                        url: `${baseUrl}/geoFences/road?roadType=${selectedItem}`,
                        data: newStr,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: currentUser
                        }
                    })
                        .then(res => {
                            let newCoords = JSON.parse(res.data.geoJson).coordinates;

                            for (let subArr of newCoords) {
                                for (let e of subArr) {
                                    let temp = e[0];
                                    e[0] = e[1];
                                    e[1] = temp;
                                }
                            }
                            dispatch(requestUpdateGeofenceVehicles(res.data.id));
                            addGeoFenceInState(res.data.id, newTitle, newCoords, false, true, res.data.isAntiTheftEnabled, res.data.notificationEmail, res.data.notificationMobileNumber);
                        })
                        .catch(err => {
                            alert(props.translate('errors.couldNotCreateGeofence'));
                            return;
                        })

                    setExtended(false);
                    setRouteMode(false);
                },
                cancel: () => {

                }
            });
        } catch (err) {
            alert(err);
            setExtended(false);
            setRouteMode(false);
        }
    }

    function deleteLastWaypoint() {
        if (routeController.getWaypoints().length > 2) {
            let length = routeController.getWaypoints().length;
            routeController.spliceWaypoints(length - 2, 1);
        }
    }

    function cancelRouteDrawing() {
        setExtended(false);
        setRouteMode(false);
        setRouteController(null);
    }

    function checkIfLessThanTwoWaypoints() {
        let on = false;
        if ((routeController?.getWaypoints().length <= 2) || selectedRoute.length === 0) {
            on = true;
        }
        return on;
    }

    return (
        <div className="routingToolBar">
            <div className="routingToolBarButtons">
                <Tooltip enterDelay={500} title={<Translate id="routing.route"></Translate>}>
                    <IconButton
                        id="menuButton_Delete"
                        style={{ color: customColors.dark, padding: '1px', borderRadius: '3px' }}
                        onClick={toggleRoutingToolbar}
                    >
                        <DirectionsIcon style={{ fontSize: 20, color: "#464646" }} />
                    </IconButton>
                </Tooltip>
            </div>
            {extended ?
                <div className="routingToolBarExtension">
                    <button onClick={finishRouteDrawing} disabled={selectedRoute.length === 0} className={selectedRoute.length === 0 ? "btnOff" : ""}>{<Translate id="routing.finish"></Translate>}</button>
                    <button disabled={checkIfLessThanTwoWaypoints()} className={checkIfLessThanTwoWaypoints() ? "btnOff" : ""} onClick={deleteLastWaypoint}>{<Translate id="routing.deleteLast"></Translate>}</button>
                    <button className="last" onClick={cancelRouteDrawing}>{<Translate id="routing.cancel"></Translate>}</button>

                    {/* <div className="routingDisplay">
                        {props.translate('routing.totalDistance')}: {selectedRoute.summary && (selectedRoute.summary.totalDistance / 1000).toFixed(2)} km
                    </div> */}
                </div> : <></>
            }
        </div>
    );
}

export default React.memo(withLocalize(RoutingToolBar), isEqual);

function isEqual(prevProps, nextProps) {
    if (objAreEqual(prevProps.selectedRoute, nextProps.selectedRoute)) {
        return true;
    }
    return false;
}