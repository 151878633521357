import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core'
import React, {useState} from 'react';
import PaletteIcon from '@material-ui/icons/Palette';
import { customColors } from '../../generic/StylingOptions';
import { useCookies } from 'react-cookie';
import { Translate } from 'react-localize-redux';
import { compareByReference } from '../../utils/comparisonUtils';
import { menu } from '../../../../managers/pathManager';

function PolygonColorMenu(props) {

    const { setPolygonColor, setColorPickerOpen, tooltip } = props;

    const [cookies, setCookie, removeCookie] = useCookies();

    const [anchorEl, setAnchorEl] = useState(null);

    const colors = [
        {
            name: "ocher",
            color: "#CC6600"
        },
        {
            name: "red",
            color: "#FF0000"
        },
        {
            name: "pink",
            color: "fuchsia"
        },
        {
            name: "purple",
            color: "purple"
        },
        {
            name: "blue",
            color: "#2222FF"
        },
        {
            name: "electricBlue",
            color: "#09588E"
        },
        {
            name: "gray",
            color: "#808080"
        },
        {
            name: "black",
            color: "#000000"
        }
    ];

    function handleClick(color) {
        setPolygonColor({color: color});
        setCookie("polygonColor", color, { expires: new Date(new Date().getFullYear() + 1, 11, 30), path: menu.geofences.path });
        
        setAnchorEl(null);
        setColorPickerOpen(false);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    function openColorPicker(e) {
        setColorPickerOpen(true);
        setAnchorEl(e.currentTarget);
    }

    return (
        <div>
            <Tooltip enterDelay={500} title={tooltip}>
                <IconButton onClick={openColorPicker} style={{color: customColors.dark, margin: '2px', padding: '15px', marginLeft: '-10px'}}>
                    <PaletteIcon />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {colors.map(c => {
                    return (
                        <MenuItem key={c.color} onClick={() => {handleClick(c.color)}}>
                            <div className="color-box" style={{backgroundColor: c.color}}></div>
                            <Translate id={"color." + c.name}></Translate>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    )
}

export default React.memo(PolygonColorMenu, isEqual);

function isEqual(prevProps, nextProps) {
    if (compareByReference(prevProps.tooltip, nextProps.tooltip))
    {
        return true;
    }
    return false;
}
