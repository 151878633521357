import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { Snackbar } from "@material-ui/core";

const CustomSnackbar = ({ translateKey, open, onClose }) => {
    return <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }}
                     autoHideDuration={3000}
                     open={open}
                     message={<Translate id={translateKey}/>}
                     onClose={onClose}/>;

};
// CustomSnackbar.propTypes = {
//     translateKey: PropTypes.string.isRequired,
//     open: PropTypes.bool.isRequired,
//     onClose: PropTypes.func.isRequired,
// };

export default CustomSnackbar;
