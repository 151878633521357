import React from "react";
import PropTypes from "prop-types";
import EmptyResult from "../views/EmptyResult";

const AddFirstEntry = ({onAdd}) => {
    return (
        <div style={{marginTop: "200px"}}>
            <EmptyResult onAdd={onAdd}/>
        </div>
    );
};

// AddFirstEntry.propTypes = {
//     classes: PropTypes.object.isRequired,
//     onAdd: PropTypes.func.isRequired,
// };

export default AddFirstEntry;
