import React from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";

import { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import FormModal from "../../common/modals/FormModal";

import TreeSelect from "../../common/selects/specificSelects/TreeSelect";
import VehicleProfileSelect from "../../common/selects/specificSelects/VehicleProfileSelect";
import CostCenterSelect from "../../common/selects/specificSelects/CostCenterSelect";
import { setFilter } from "../../../actions/actionFilter";
import { getIdsFromGroupValues } from "../../../managers/groupManager";
import FormBuilder from "../../../managers/formBuilder";
import { Translate } from "react-localize-redux";
import { MODAL_TYPES } from "../../common/modals/modal.utils";

import {
  allowedRoles,
  isAuthorized,
  isViewer,
} from "../../../managers/authManager";
import { packages } from "../../../models/Package";
import { roles } from "../../../models/Role";

const styles = {};

const VehicleFilterModal = (props) => {
  const { onClose, filter, tree, setFilter } = props;
  const { groups, vehicleProfiles, costcenters } = filter.initial;
  const [fields, setFields] = useState({groups, vehicleProfiles, costcenters});
  
  const handleInputChange = (name, value) => {
   setFields({ ...fields, [name]: value });
  };

  const me = useSelector((state) => state.users.me);

  function handleFilter() {
    const apiFilter = {
      groupIds: getIdsFromGroupValues(tree, fields.groups),
      vehicleTypes: fields.vehicleProfiles,
      costcenterIds: fields.costcenters,
    };
    setFilter(fields, apiFilter);
    onClose();
  }

  const inputs = [
    new FormBuilder.FormInput(
      <Translate id="vehicle.filtermodal.groups" />,
      <TreeSelect value={fields.groups} onChange={(value) => handleInputChange("groups", value)} />
    )
      .withValue(fields.groups)
      .withKey("groups")
      .build(),

    new FormBuilder.FormInput(
      <Translate id="vehicle.filtermodal.vehicleprofiles" />,
      <VehicleProfileSelect multiple value={fields.vehicleProfiles} onChange={(value) => handleInputChange("vehicleProfiles", value)} />
    )
      .withValue(fields.vehicleProfiles)
      .withKey("vehicleProfiles")
      .build(),
  ];

  if (isAuthorized(me, allowedRoles(roles.Driver), [packages.CostCenter]))
    inputs.push(
      new FormBuilder.FormInput(
        <Translate id="vehicle.filtermodal.costCenters" />,
        <CostCenterSelect multiple showArchived={false} value={fields.costcenters} onChange={(value) => handleInputChange("costcenters", value)} />
      )
        .withValue(fields.costcenters)
        .withKey("costcenters")
        .build()
    );

  return (
    <FormModal
      onSave={handleFilter}
      onClose={onClose}
      formType={MODAL_TYPES.withoutValidation}
      title="Fahrzeuge filtern"
      inputs={inputs}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setFilter }, dispatch);
}

function mapStateToProp(state) {
  return { tree: state.tree, filter: state.filter };
}

// VehicleFilterModal.propTypes = {
//   onClose: PropTypes.func.isRequired,
// };

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(VehicleFilterModal));
