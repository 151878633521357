import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import AddModal from "../../common/modals/AddModal";
import {makeStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import {usePagination} from "../../../hooks/usePagination";
import {bindActionCreators} from "redux";
import {requestUsers, selectAllUser, selectUser} from "../../../actions/actionUser";
import {requestUpdateGroup} from "../../../actions/actionGroups";
import {Grid, Button,} from "@material-ui/core"
import CustomTransferList from "./CustomTransferList";
import {useTransferList} from "../../../hooks/useTransferList";
import {Translate} from "react-localize-redux";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

const AddUserModal = props => {
    const classes = useStyles();
    const {
        checked,
        left,
        setLeft,
        right,
        numberOfChecked,
        onCheckedRight,
        onCheckedLeft,
        onToggle,
        onToggleAll,
        leftChecked,
        rightChecked,
    } = useTransferList();

    const mappedLeft = left.map(u => {
        u.name = u.firstName + " " + u.lastName;
        return u;
    });

    const mappedRight = right.map(u => {
        u.name = u.firstName + " " + u.lastName;
        return u;
    });

    const {group, onClose, users, total, selected} = props;


    useEffect(() => {
        props.requestUsers({page: 1, perPage: 999999, IsArchived: false});
    }, []);

    useEffect(() => {
        setLeft(users.filter(u => !group.users.some(g => g.id == u.id)));
    }, [users, group]);

    function addUsers() {
        const userIdsToAdd = right.map(u => u.id);
        const userIds = group.users.map(u => u.id).concat(userIdsToAdd);
        const vehicleIds = group.vehicles.map(u => u.id);
        props.requestUpdateGroup({name: group.name, id: group.id, vehicleIds, userIds});
        onClose();
    }

    return (
        <AddModal title={<Translate id="group.addUserModal.title"/>}
                  onAdd={addUsers}
                  onClose={onClose}
                  open
                  largeModal>
            <Grid container
                  spacing={2}
                  justify="center"
                  alignItems="center"
                  className={classes.root}>
                <Grid item>
                    <CustomTransferList title={<Translate id="group.addUserModal.user"/>}
                                        items={mappedLeft}
                                        checked={checked}
                                        onToggle={onToggle} onToggleAll={onToggleAll}
                                        numberOfChecked={numberOfChecked}/>
                </Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={onCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right">
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={onCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left">
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <CustomTransferList title={<Translate id="group.addUserModal.addToGroup"/>}
                                        items={mappedRight}
                                        checked={checked}
                                        onToggle={onToggle}
                                        onToggleAll={onToggleAll}
                                        numberOfChecked={numberOfChecked}/>
                </Grid>
            </Grid>
        </AddModal>
    );
};

// AddUserModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestUsers,
            selectUser,
            selectAllUser,
            requestUpdateGroup,
        },
        dispatch,
    );
}

function mapStateToProp(state) {
    return {
        users: state.users.entities,
        total: state.users.total,
        selected: state.users.selected,
        group: state.group,

    };
}

export default connect(mapStateToProp, mapDispatchToProps)(AddUserModal);
