import React from 'react'
import CustomModal from '../common/modals/CustomModal';
import { Translate } from 'react-localize-redux';

const VehiclesWithOverconsumptionModal = ({onClose, content}) => {
    return (
        <CustomModal
        contents={content}
        title={<Translate id="dashboard.vehiclesWithOverconsumeModal.title" />}
        onClose={onClose}

        />
    )
}

export default VehiclesWithOverconsumptionModal;