import React, {Component, useEffect, useState, useRef} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {clearGeoCode, requestAutocompleteAddress, requestLocationDetails} from "../../../../actions/actionGeoLocation";
import {Select} from "antd";
import {Translate} from "react-localize-redux";

import CustomSelect from "../Select";
import {addressToStr} from "../../../../managers/locationManager";

const {Option} = Select;

const keyStrokeTimeout = 500;

const AddressSelect = props => {
    const [timeout, setTimeout] = useState(0);
    const [loading, setLoading] = useState(true);
    const [waitForResponse, setWaiting] = useState(false);

    const {geoLocationAddress, possibleAddresses, onChange, value } = props;

    useEffect(() => {
        return () => {
            props.clearGeoCode();
        }
    }, []);


    useEffect(() => {
        if (waitForResponse && Object.keys(geoLocationAddress).length > 0) {
            setWaiting(false);
            onChange({...geoLocationAddress, locationId: geoLocationAddress.foreignServiceId});
        }
    }, [geoLocationAddress]);

    useEffect(() => {
        setLoading(false);
    }, [possibleAddresses]);

    function getAutocompletePossibilities(address) {
        setLoading(true);
        props.requestAutocompleteAddress({address: address});
    }

    function handleChange(address) {
        if (timeout) clearTimeout(timeout);

        if (address && address.length > 3)
            setTimeout(setTimeout(() => {
                getAutocompletePossibilities(address);
            }, keyStrokeTimeout));
    }

    function handleAutoFill(foreignServiceId) {
        setWaiting(true);
        props.requestLocationDetails({locationid: foreignServiceId});
        props.clearGeoCode();  
    }
    
    const getCustomPossibleAddresses = () => {
            return possibleAddresses.map((a, i) => (
                <Option key={i} value={a.foreignServiceId}>
                    <div dangerouslySetInnerHTML={{__html: a.displayName}}/>
                </Option>
            ))
    }

    return (
        <CustomSelect
            disableFilter
            allowClear={false}
            value={addressToStr(value)}
            placeholder={<Translate id="common.select.address"/>}
            handleChange={handleAutoFill}
            handleSearch={handleChange}
            notFound={loading}
        >
            {getCustomPossibleAddresses()}
        </CustomSelect>
    );
}

// AddressSelect.propTypes = {
//     value: PropTypes.string.isRequired,
//     onChange: PropTypes.func.isRequired,
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({requestLocationDetails, requestAutocompleteAddress, clearGeoCode}, dispatch);
}

function mapStateToProp(state) {
    return {
        possibleAddresses: state.address.entities,
        geoLocationAddress: state.address.current
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps
)(AddressSelect);
