import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { Select } from "antd";
import CustomSelect from "../Select";
import MultiSelect from "../MultiSelect";
import { exportFormatArray } from "../../../../models/ExportFormat";

const { Option } = Select;

const ExportSelect = (props) => {
  const { onChange, value, disabled, mutliple } = props;

  if (mutliple)
    return (
      <MultiSelect
        placeholder={<Translate id="common.select.exportformat" />}
        selectedArray={value}
        array={exportFormatArray}
        handleChange={onChange}
      />
    );
  return (
    <CustomSelect
      value={value}
      placeholder={<Translate id="common.select.exportformat" />}
      handleChange={onChange}
      allowClear={false}
      disabled={disabled}
    >
      {exportFormatArray.map((tt) => (
        <Option key={tt.id} value={tt.id}>
          {tt.name}
        </Option>
      ))}
    </CustomSelect>
  );
};

ExportSelect.defaultProps = {
  disabled: false,
  mutliple: false,
};

// ExportSelect.propTypes = {
//   onChange: PropTypes.func,
//   value: PropTypes.string,
//   disabled: PropTypes.bool,
//   multiple: PropTypes.bool,
// };

export default ExportSelect;
