import translations from './globalTranslations.json';

let currentLanguage = 'en-gb'; // Default language

export function setTranslatorLanguage(language) {
    currentLanguage = language;
}

export function getTranslationWithParam(key, params) {
    let translation = getTranslation(key);
    for (let paramKey in params) {
        translation = translation.replace("${ " + paramKey + " }", params[paramKey]);
    }

    return translation;
}
export function getTranslation(key) {
    const keys = key.split('.');

    let translatedValue = translations;

    for (const nestedKey of keys) {
        if (!translatedValue.hasOwnProperty(nestedKey)) {
            return key; // Return the original key if translation not found
        }

        translatedValue = translatedValue[nestedKey];

        if (Array.isArray(translatedValue)) {
            switch (currentLanguage) {
                case 'en-gb':
                    translatedValue = translatedValue[0];
                    break;
                case 'de-at':
                    translatedValue = translatedValue[1];
                    break;
                default:
                    translatedValue = translatedValue[0];
            }

        }
    }

    return translatedValue;
}

