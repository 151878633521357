
const menu = {
  login: {
    key: "login",
    path: "/login",
  },
  resetpassword: {
    key: "resetpassword",
    path: "/PasswordReset",
  },
  resetMomentPassword: {
    key: "migrationPassword",
    path: "/migrationpassword",
  },
  hwnodashboard: {
    key: "hwnodashboard",
    path: "/hwno",
  },
  dashboard: {
    key: "dashboard",
    path: "/",
  },
  calendar: {
    key: "calendar",
    path: "/calendar",
  },
  costcenters: {
    key: "costcenters",
    path: "/costcenters",
  },
  trips: {
    key: "trips",
    path: "/trips",
  },
  groupTrip: {
    key: "groupTrip",
    path: "/groupTrip",
  },
  dayView: {
    key: "day",
    path: "/day",
  },
  livelocation: {
    key: "livelocation",
    path: "/livelocation",
  },
  vehicles: {
    key: "vehicles",
    path: "/vehicles",
  },
  users: {
    key: "users",
    path: "/users",
  },
  groups: {
    key: "groups",
    path: "/groups",
  },
  driveboxes: {
    key: "driveboxes",
    path: "/driveboxes",
  },
  timeReporting: {
    key: "timereporting",
    path: "/time",
  },
  reporting: {
    key: "reporting",
    path: "/reports",
  },
  order: {
    key: "order",
    path: "/order",
  },
  settings: {
    key: "settings",
    path: "/settings",
  },
  help: {
    key: "help",
    path: "/help",
  },
  mandator: {
    key: "mandator",
    path: "/mandators",
  },
  mandatorDrivebox: {
    key: "mandatorDrivebox",
    path: "/mandator/driveboxes",
  },
  mandatorUser: {
    key: "mandatorUser",
    path: "/mandator/users",
  },
  mandatorSuperUser: {
    key: "mandatorSuperUser",
    path: "/mandator/superuser",
  },
  rowData: {
    key: "rawData",
    path: "/mandator/rawdata",
  },
  geofences: {
    key: "geofences",
    path: "/geofences",
  },
  geofencesReport: {
    key: "geofencesReport",
    path: "/geofences/report",
    },
  geofenceAnalysis: {
    key: "geofenceAnalysis",
    path: "/geofences/analysis",
  },
  chargingSimulator: {
    key: "chargingSimulator",
    path: "/asdijsad-asdj12d-daksne-djdi13-213j3",
  },
  reservation: {
    key: "reservation",
    path: "/reservation",
  },
  qualityCharging: {
    key: "qualityCharging",
    path: "/qualityCharging"
  },
  faultyTrips: {
    key: "faultyTrips",
    path: "/faultyTrips"
  },
  driverIdentification: {
    key: "driverIdentification",
    path: "/driverIdentification"
  }
};

Object.freeze(menu);

export { menu };
