import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { bindActionCreators } from "redux";
import { Select } from "antd";

import CustomSelect from "../Select";
import MultiSelect from "../MultiSelect";
import { requestRoles } from "../../../../actions/actionUser";

const { Option } = Select;

const RoleSelect = props => {
    const { onChange, roles, value, disabled, multiple,allowClear } = props;

    useEffect(() => {
        props.requestRoles();
    }, []);

    if (multiple)
        return <MultiSelect placeholder={<Translate id="common.select.roles"/>} 
                            selectedArray={value} 
                            array={roles}
                            handleChange={onChange}
                            disabled={disabled}/>;
    return (
        <CustomSelect value={value} 
                      placeholder={<Translate id="common.select.role"/>}
                      handleChange={onChange}
                      disabled={disabled}
        allowClear={allowClear}>
            {roles.map(user => (
                <Option key={user.id} value={user.id}>
                    {user.name}
                </Option>
            ))}
        </CustomSelect>
    );

};

RoleSelect.defaultProps = {
    disabled: false,
    multiple: false,
    allowClear:true
};

// RoleSelect.propTypes = {
//     onChange: PropTypes.func.isRequired,
//     value: PropTypes.string.isRequired,
//     disabled: PropTypes.bool,
//     multiple: PropTypes.bool,
//     allowClear: PropTypes.bool
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ requestRoles }, dispatch);
}

function mapStateToProp(state) {
    return {
        roles: state.users.roles,
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(RoleSelect);
