import React, { useEffect } from "react";
import CustomModal from "../../common/modals/CustomModal";
import Grid from "@material-ui/core/Grid";
import PeriodDatePicker from "../../common/selects/datePickers/PeriodDatePicker";
import Button from "@material-ui/core/Button";
import { Translate } from "react-localize-redux";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { updateFilterAPICall } from "../../../actions/actionFilter";
import { periods } from "../../../models/Period";
import { fromToByPeriod } from "../../../managers/timeManager";
import { subMonths } from "date-fns";
import {
  requestReservationReport,
  requestHwnoeVehicleDataReport,
  requestCostcenterReport,
  requestFuelMonitorReport
} from "../../../actions/actionExport";

import { REPORT_TYPE } from "../ReportPage";

const useStyles = makeStyles((theme) => ({
  exportButton: {
    marginTop: theme.spacing(1),
  },
}));

const PeriodicExportModal = (props) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.users.me);
  let isButtonDisabled = false;
  const classes = useStyles();
  const { onClose } = props;
  const filter = useSelector((state) => state.filter.api);

  const { reportType } = props;


  const translateKey = reportType === REPORT_TYPE.reservation ? "exportReservationModal" : "exportmodal"

  const defaultRange = fromToByPeriod(
    periods.month.id,
    subMonths(new Date(), 1)
  );

  useEffect(() => {
    dispatch(
      updateFilterAPICall({
        period: periods.month.id,
        from: defaultRange.from,
        to: defaultRange.to,
      })
    );
  }, []);

  function handleDateChange(period, from, to) {
    dispatch(
      updateFilterAPICall({
        period,
        from: new Date(from).toISOString(),
        to: new Date(to).toISOString(),
      })
    );
  }

  const content = (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <Translate id="trip.exportmodal.period" />
      </Grid>

      <Grid item xs={9}>
        <PeriodDatePicker
          period={filter.period || periods.month.id}
          onDateChange={handleDateChange}
          startDate={
            filter.from
              ? new Date(filter.from).toISOString()
              : defaultRange.from
          }
          endDate={
            filter.to ? new Date(filter.to).toISOString() : defaultRange.to
          }
          middleRange={reportType === REPORT_TYPE.costcenter ? false : true}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={handleExport}
          disabled={isButtonDisabled}
          className={classes.exportButton}
          variant="contained"
          color="primary"
          fullWidth
        >
          <Translate id="trip.exportmodal.export" />
        </Button>
      </Grid>
    </Grid>
  );

  function exportReservationReport() {
    switch (reportType) {
      case REPORT_TYPE.reservation:
        dispatch(
          requestReservationReport({
            ...filter,
            from: filter.from ? filter.from : defaultRange.from.toISOString(),
            to: filter.to ? filter.to : defaultRange.to.toISOString(),
          })
        );
        break;
      case REPORT_TYPE.hwnoeVehicle:
        dispatch(
          requestHwnoeVehicleDataReport({
            mandatorId: me.mandator.id,
            from: filter.from ? filter.from : defaultRange.from.toISOString(),
            to: filter.to ? filter.to : defaultRange.to.toISOString(),
          })
        );
        break;
      case REPORT_TYPE.costcenter: {
        dispatch(requestCostcenterReport({
          mandatorId: me.mandator.id,
          userId: me.id,
          period: filter.period,
          from: filter.from ? filter.from : defaultRange.from.toISOString(),
          to: filter.to ? filter.to : defaultRange.to.toISOString(),
        }));
        break;
      }
      case REPORT_TYPE.fuelMonitor: {
        dispatch(requestFuelMonitorReport({
          from: filter.from ? filter.from : defaultRange.from.toISOString(),
          to: filter.to ? filter.to : defaultRange.to.toISOString(),
        }))
      }
    }
  }

  function handleExport() {
    exportReservationReport();
    onClose();
  }

  return (
    <CustomModal
      onClose={onClose}
      title={<Translate id={`trip.${translateKey}.title`}/>}
      contents={[content]}
    />
  );
};

export default PeriodicExportModal;
