import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {fromToByPeriod} from "../../../../managers/timeManager";
import {periods} from "../../../../models/Period";
import {addYears, subYears} from "date-fns";
import {useHotkeys} from "react-hotkeys-hook";
import CustomDatePicker from "./CustomDatePicker";

const styles = theme => ({});

const YearPicker = props => {
    const { value, classes, minDate, onDateChange, disableFuture = true } = props;

    useHotkeys("ctrl+alt+left", () => {
        setDates(subYears(value, 1));
    }, [value]);
    useHotkeys("ctrl+alt+right", () => {
        setDates(addYears(value, 1));
    }, [value]);

    function setDates(startDate) {
        const yearRange = fromToByPeriod(periods.year.id, startDate);
        props.onDateChange(yearRange.from, yearRange.to);
    }

    return (
        <CustomDatePicker dateViews={["year"]}
                            value={value}
                            minDate={minDate}
                            disableFuture={disableFuture}
                            onChange={setDates}
                            inputFormat="yyyy"
        />
    );
};

export default withStyles(styles)(YearPicker);
