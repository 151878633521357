import { combineReducers } from "redux";
import { localizeReducer } from "react-localize-redux";

import { tree, group } from "./reducerGroups";
import { authorisedReducer, authentication } from "./reducerAuthentication";
import rawdata from "./rawDataReducer";
import { filter } from "./reduerFilter";

import { tripsDetail } from "./reducerTrips";
import { liveLocation } from "./reducerLiveLocation";
import { historyDetail } from "./reducerHistories";
import { addressDetail } from "./reducerGeoLocation";
import { errorDetail } from "./reducerError";
import { status, language } from "./reducerCommon";
import { reports } from "./reducerReports";
import { scheduledReports } from "./reducerScheduledReports";

import { costcentersDetail } from "./masterData/reducerCostcenters";
import { usersDetail } from "./masterData/reducerUsers";
import { vehiclesDetail } from "./masterData/reducerVehicles";
import { driveboxesDetail } from "./masterData/reducerDriveboxes";
import { mandatorsDetail } from "./masterData/reducerMandators";
import { timeRecordingDetail } from "./reducerTimeRecording";
import { geofenceDetail } from "./reducerGeofences";
import { exportTripsDetail } from "./reducerExportTrips";
import { reservationDetail } from "./masterData/reducerReservation";
import { chargeStationsReducer } from "./qualityCharging/reducerChargeStations";
import { connectorsReducer } from "./qualityCharging/reducerConnectors";
import { emailReducer } from "./reducerEmail";
import { unrealisticTripsReducer } from "./faultyTrips/reducerUnrealisticTrips";
import { misMatchTripsReducer } from "./faultyTrips/reducerMisMatchTrips";

export default combineReducers({
  localize: localizeReducer,
  rawdata: rawdata,
  trips: tripsDetail,
  lastVehiclesLocation: liveLocation,
  filter: filter,
  authentication: authentication,
  authorised: authorisedReducer,
  tree: tree,
  group: group,
  history: historyDetail,
  address: addressDetail,
  error: errorDetail,
  status: status,
  language: language,
  reports: reports,
  scheduledReports: scheduledReports,
  users: usersDetail,
  costcenters: costcentersDetail,
  vehicles: vehiclesDetail,
  driveboxes: driveboxesDetail,
  mandators: mandatorsDetail,
  timeRecording: timeRecordingDetail,
  geofences: geofenceDetail,
  exportTrips: exportTripsDetail,
  reservation: reservationDetail,
  chargeStations: chargeStationsReducer,
  connectors: connectorsReducer,
  email: emailReducer,
  unrealisticTrips: unrealisticTripsReducer,
  misMatchTrips: misMatchTripsReducer,
});
