import React from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import GenericTable from "../../common/table/GenericTable";
import TableTypes from "../../../models/TableTypes";
import Comparator from "../../../models/Comparator";
import {
  AddressPrintLevel,
  addressToStr,
} from "../../../managers/locationManager";
import {
  secondsToString,
  serverTimeToSeconds,
} from "../../../managers/timeManager";
import EditIcon from "@material-ui/icons/EditTwoTone";
import { Translate } from "react-localize-redux";
import {
  allowedRoles,
  isAuthorized,
  isViewer,
} from "../../../managers/authManager";
import { packages } from "../../../models/Package";
import { roles } from "../../../models/Role";

import { tripType } from "../../../models/TripType";
import FontAwesomeIcon from "../../common/views/FontAwesomeIcon";
import {
  customTranslation,
  getTranslation,
} from "../../../translations/customTranslation";

const AnalysisTable = (props) => {
  const {
    trips,
    analysisEvents,
    total,
    page,
    rowsPerPage,
    onSelect,
    onSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    onTrack,
    onHistory,
    onToBusiness,
    me,
    loading,
    isInGeofenceReport,
    onlyShowBusinessTrips,
    showCheckboxes,
    showVehicle,
  } = props;

  const viewer = isViewer(me);
  const openTrip = useSelector((state) => state.vehicles.openTrip);

    const customTripComparator = new Comparator(
    ["agent"],
    (agent) => agent == "1"
  );
  const changedComparator = new Comparator(
    ["hasBeenChanged"],
    (hasBeenChanged) => hasBeenChanged
  );
  const calibratedAtComparator = new Comparator(
    ["calibratedAt"],
    (calibratedAt) => Boolean(calibratedAt)
  );
  const isYourPrivateTripComparator = new Comparator(
    ["type", "driver.id"],
    (type, driverID) =>
      type !== tripType.Private ||
      (type === tripType.Private && me.id == driverID)
  );
  const isToBusinessComparator = new Comparator(
    ["type", "driver.id"],
    (type, driverID) =>
      !(
        type !== tripType.Private ||
        (type === tripType.Private && me.id == driverID)
      )
    );

    function analysisEventsTripTypeFilter(event) {
        if (onlyShowBusinessTrips) {
            
            let tt = trips.find(trip => trip.id === event.tripId)
            if ( tt && tt.type !== tripType.Business) {
                return false;
            }

        }
        return true;
    }

    const eventsWithMappedAddresses = analysisEvents.filter(analysisEventsTripTypeFilter).map((event) => {

        let updatedEvent = { ...event }; // A copy of the original event

        updatedEvent.mappedVehicle = event.vehicleName + ' (' + event.vehicleLicensePlate + ')';
        updatedEvent.mappedDate = new Date(event.eventIntersectionTimestamp * 1000);
        updatedEvent.mappedEvent = event.event;
        updatedEvent.mappedTime = new Date(event.eventIntersectionTimestamp * 1000);
        updatedEvent.mappedDescription = event.description;
        updatedEvent.mappedCostCenter = event.costCenter;
        updatedEvent.isSelected = false;

        return updatedEvent;
    });

  const checkTripForPrivateRide = (trip) =>
    trip.type !== tripType.Private ||
    (trip.type === tripType.Private && me.id == trip.driver.id)
      ? onTrack(trip)
            : undefined;

    function tripTypeFilter(trip) {
        if (onlyShowBusinessTrips) {
            if (trip.type !== tripType.Business) {
                return false;
            }
        }
        return true;
    }

  const tripsWithMappedAddress = trips.filter(tripTypeFilter).map((trip) => {
    trip.mappedStartAddress = addressToStr(
      trip.startAddress,
      AddressPrintLevel.street
    );
    trip.mappedDestinationAddress = addressToStr(
      trip.destinationAddress,
      AddressPrintLevel.street
    );
    trip.mappedDescription = trip.description
      ? trip.description.length > 20
        ? trip.description.substring(0, 20) + "..."
        : trip.description
          : "";
      var mileageInKm = trip.mileageInMeters / 1000;

      trip.mappedMileage = mileageInKm.toLocaleString(navigator.languages, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " km";
      if (mileageInKm < 1) trip.mappedMileage = "< " + (1).toLocaleString(navigator.languages, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " km";;
    trip.mappedTripType =
      trip.type == tripType.Business ? (
        getTranslation(customTranslation.business, me)
      ) : trip.type == tripType.Private ? (
        <Translate id="geofenceAnalysis.table.private" />
      ) : trip.type == tripType.DriveToWork ? (
        getTranslation(customTranslation.driveToWork, me)
      ) : (
        ""
      );
    trip.mappedDuration = secondsToString(serverTimeToSeconds(trip.duration));
    trip.mappedIdleTime = secondsToString(serverTimeToSeconds(trip.idleTime));
    trip.mappedDate = trip.startedAt;
    trip.hasDriver = Boolean(trip.driver);
    trip.mappedStartingBatteryChargeLevelInPercent = trip.startingBatteryChargeLevelInPercent ? trip.startingBatteryChargeLevelInPercent + "%" : "Keine Daten";
    trip.mappedFinishingBatteryChargeLevelInPercent = trip.finishingBatteryChargeLevelInPercent ? trip.finishingBatteryChargeLevelInPercent + "%" : "Keine Daten";

    trip.mappedDriver = trip.driver ? (
      trip.driver.name
    ) : (
      <Translate id="geofenceAnalysis.table.noDriver" />
    );
    trip.mappedVehicle = trip.vehicle?.name || (
      <Translate id="geofenceAnalysis.table.noVehicle" />
    );
    return trip;
  });


  if (openTrip.positions.length > 0 && page === 0) {
    tripsWithMappedAddress.unshift({
      mappedDriver: openTrip.driver ? (
        openTrip.driver.name
      ) : (
        <Translate id="geofenceAnalysis.table.noDriver" />
      ),
      mappedIdleTime: "-",
      mappedDuration: "-",
      mappedTripType: "-",
      mappedStartAddress: addressToStr(
        openTrip.startAddress,
        AddressPrintLevel.street
      ),
      mappedDestinationAddress: "Fahrt wurde noch nicht beendet",
      startedAt: openTrip.startedAt,
      mappedDate: openTrip.startedAt,
      finishedAt: null,
    });
  }

  const columnInformations = [
    {
      title: <Translate id="geofenceAnalysis.table.vehicle" />,
      key: "mappedVehicle",
    },
  ];
  columnInformations.push({
    title: <Translate id="geofenceAnalysis.table.date" />,
    key: "mappedDate",
    type: TableTypes.date,
  });
  columnInformations.push({
    title: <Translate id="geofenceAnalysis.table.event" />,
    key: "mappedEvent",
    type: TableTypes.string,
  });
  columnInformations.push({
    title: <Translate id="geofenceAnalysis.table.time" />,
    key: "mappedTime",
    type: TableTypes.time,
  });
  columnInformations.push({
    title: <Translate id="geofenceAnalysis.table.description" />,
    key: "mappedDescription",
    type: TableTypes.string,
    noBr: true,
  });
  
  if (isAuthorized(me, allowedRoles(roles.Driver), [packages.CostCenter]))
    columnInformations.push({
      title: <Translate id="geofenceAnalysis.table.costCenter" />,
      key: "costcenterKey",
      noBr: true,
    });

  const actions = [];

  if (onTrack) {
    actions.push({
      title: <Translate id="geofenceAnalysis.table.map" />,
      event: onTrack,
      comparator: isYourPrivateTripComparator,
    });
  }

  if (onHistory) {
    actions.push({
      title: <Translate id="geofenceAnalysis.table.history" />,
      event: onHistory,
      comparator: isYourPrivateTripComparator,
    });
  }

  if (
    onToBusiness &&
    me.isHWNOE &&
    isAuthorized(me, allowedRoles(roles.GroupLeader))
  )
    actions.push({
      title: <Translate id="geofenceAnalysis.table.toBusiness" />,
      event: onToBusiness,
      comparator: isToBusinessComparator,
    });

  const viewerActions = [
    {
      title: <Translate id="geofenceAnalysis.table.history" />,
      event: onHistory,
      comparator: isYourPrivateTripComparator,
    },
  ];

  const typeIcons = [
    {
      icon: <FontAwesomeIcon color={"secondary"}>far fa-pen</FontAwesomeIcon>,
      comparator: calibratedAtComparator,
      title: <Translate id="geofenceAnalysis.table.calibratedTrip" />,
    },
    {
      icon: <FontAwesomeIcon color={"secondary"}>far fa-tools</FontAwesomeIcon>,
      comparator: customTripComparator,
      title: <Translate id="geofenceAnalysis.table.manuallyTrip" />,
    },
    {
      icon: <EditIcon />,
      comparator: changedComparator,
      title: <Translate id="geofenceAnalysis.table.editTrip" />,
    },
  ];

  return (
    <GenericTable
      data={eventsWithMappedAddresses}
      onRowClick={checkTripForPrivateRide}
      columnInformations={columnInformations}
      actions={viewer ? viewerActions : actions}
      rowCount={total}
      pageNavigation
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      onSelectAllClick={onSelectAll}
      onSelect={onSelect}
      //numSelected={analysisEvents.filter((analysisEvent) => analysisEvent.isSelected).length}
      showActions={isInGeofenceReport ? false : true}
      showCheckboxes={
        showCheckboxes === undefined
          ? isInGeofenceReport
            ? false
            : !viewer
          : showCheckboxes
      }
      typeIcons={isInGeofenceReport ? false : typeIcons}
      loading={loading}
    />
  );
};
AnalysisTable.defaultProps = {
  page: 0,
  showVehicle: false,
};

// AnalysisTable.propTypes = {
//   page: PropTypes.number,
//   rowsPerPage: PropTypes.number.isRequired,
//   handleChangePage: PropTypes.func.isRequired,
//   handleChangeRowsPerPage: PropTypes.func.isRequired,
//   onSelect: PropTypes.func.isRequired,
//   onSelectAll: PropTypes.func.isRequired,
//   onTrack: PropTypes.func,
//   onHistory: PropTypes.func,
//   onToBusiness: PropTypes.func,
//   showCheckboxes: PropTypes.bool,
//   showVehicle: PropTypes.bool,
//   onlyShowBusinessTrips: PropTypes.bool,
// };

function mapStateToProp(state) {
    return {
    trips: state.trips.entities,
    analysisEvents: state.geofences.geofenceAnalysisEvents.entities ? state.geofences.geofenceAnalysisEvents.entities : [],
    costcenters: state.costcenters.entities,
    //loading: state.geofences.geofenceAnalysisEvents.loading,
    total: state.geofences.geofenceAnalysisEvents.total,
    me: state.users.me,
  };
}

export default connect(mapStateToProp)(AnalysisTable);
