import { Grid, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import Map from '../../../common/map/Map';
import OriginDestinationTimeline from '../../../trips/OriginDestinationTimeline';
import useTrackModalStyle from "../../../../../src/components/trips/tripsModals/useTrackModalStyle";
import CustomModal from '../../../common/modals/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { clearGeoCode } from '../../../../actions/actionGeoLocation';
import { requestMileageOfTrip, requestTrackOfTrip, requestTripById } from '../../../../actions/actionTrips';
import { requestGeofencesEventsOfCurrentTrip, requestGeofencesOfCurrentTrip, requestGeofencesOfCurrentVehicle, searchLocallyGeofences } from '../../../../actions/actionGeofences';
import { Typography } from 'antd';
import { Marker, Polygon, Popup, Tooltip } from 'react-leaflet'; 
import Entering from '../../../../icons/vehicle_driving.svg';
import Leaving from '../../../../icons/vehicle_standing_still.svg';
import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { formatTimeStamp } from '../../utils/dateTimeUtils';
import ToolbarSearch from '../../../common/toolbar/ToolbarSearch';

function getIntersectionIcon(leaving) {
    return divIcon({
        html: renderToStaticMarkup(<img style={{zIndex: "500", marginBottom: "-12px", marginLeft:"6px", width: "20px", height: "20px"}}
                                        src={leaving? Leaving : Entering}/>),
    });
}

const GeofencesTripIntersectionModal = props => {
    const {onClose, id, vehicleId} = props;

    const trip = useSelector(state => state.trips.current);
    const me = useSelector(state => state.users.me);
    const geofencesOfCurrentVehicle = useSelector(state => state.geofences.geofencesOfCurrentVehicle);
    const geofencesOfCurrentTrip = useSelector(state => state.geofences.geofencesOfCurrentTrip);
    const eventsOfCurrentTrip = useSelector(state => state.geofences.eventsOfCurrentTrip);
    const dispatch = useDispatch();

    const [geofencesOfTripOrVehicle, setGeofencesOfTripOrVehicle] = useState();
    // used to fly to bounds
    const [leafletMap, setLeafletMap] = useState();
    const [showTrack, setShowTrack] = useState(me.canShowTracks);
    const classes = useTrackModalStyle();
    
    // View Content stuff
    const startEndTrack = [
        {latitude: trip.startLatitude, longitude: trip.startLongitude},
        {latitude: trip.destinationLatitude, longitude: trip.destinationLongitude},
    ];
    const track = trip.positions || [];
    const mappedTrip = trip;

    useEffect(() => {
        dispatch(requestTripById(id));
        dispatch(requestMileageOfTrip(id));
        dispatch(requestGeofencesOfCurrentVehicle({vehicleId}))
        dispatch(requestGeofencesOfCurrentTrip({tripId: id}))
        dispatch(requestGeofencesEventsOfCurrentTrip({tripId: id}))
        if (me.canShowTracks) dispatch(requestTrackOfTrip(id));

        return () => dispatch(clearGeoCode());
    }, []);

    useEffect(() => { 
        if (Object.keys(geofencesOfCurrentVehicle).length !== 0) {
            let allGeofences = [...geofencesOfCurrentVehicle?.geofences, ...geofencesOfCurrentTrip];
            let set = new Set();

            let unionArray = allGeofences.filter(item => {
                if (!set.has(item.id)) {
                    set.add(item.id);
                    return true;
                }
                return false;
            }, set);


            setGeofencesOfTripOrVehicle(unionArray); 
        }
    }, [geofencesOfCurrentVehicle, geofencesOfCurrentTrip]);


    // can be used for fitting bounds when searching 
    // useEffect(() => {
    //     let resBounds = geofencesOfTripOrVehicle?.filter(geo => !geo.isHidden).map(geo => geo.coordinates.map(pos => ({ lat: pos[0], lng: pos[1] }))); 
    //     if(resBounds && resBounds.length > 0){
    //         const newBounds = L.latLngBounds(resBounds).pad(0.05);
    //         var a = leafletMap?.fitBounds(newBounds);
    //     }
    // }, [geofencesOfTripOrVehicle])

    function renderMarker(eventIntersection, durationSinceLastEnter, totalDuration, geofenceName) {
        const event = eventIntersection.event; 
        return <Marker icon={getIntersectionIcon(event == "Entering" ? false : true)} position={[eventIntersection.intersection.lat, eventIntersection.intersection.long]} key={eventIntersection.id}>
            <Popup >
                <b><Translate id={"geofenceReport." + event} /> <Translate id="geofenceReport.event" /> {geofenceName}</b>
                <br /><Translate id={"geofenceReport." + event} /> <Translate id="geofenceReport.time" />: {formatTimeStamp(new Date(eventIntersection.intersection.timestamp))}
                {durationSinceLastEnter !== 0 ? <br /> : null}
                {durationSinceLastEnter != 0 ? <Translate id="geofenceReport.durationSinceLastEntrance" />  : null}
                {durationSinceLastEnter != 0 ? new Date(durationSinceLastEnter * 1000).toISOString().substr(11, 8) : null}
                <br />{totalDuration ? <Translate id="geofenceReport.totalDuration" />  : null}
                {totalDuration ? " " + geofenceName + ": " +  new Date(totalDuration * 1000).toISOString().substr(11, 8) : null}
            </Popup>
        </Marker>
    }

    function renderTripEventsMarker(tripEvent, geofenceName, totalDuration) {
        return (
            <>
                {tripEvent?.enteringEventIntersection?.intersection?.lat ? renderMarker(tripEvent.enteringEventIntersection, 0, totalDuration, geofenceName) : null}
                {tripEvent?.leavingEventIntersection?.intersection?.lat ? renderMarker(tripEvent.leavingEventIntersection, tripEvent.durationSinceLastEnter, totalDuration, geofenceName) : null}
            </>
        );
    }
    
    
    // seems that for those who do not have access on track will show only the start and end point as a straight line (startendtrack)
    // otherwise if they have access the whole track will be shown
    mappedTrip.positions = showTrack ? track : startEndTrack; 

    const map = <Map trips={[mappedTrip]} setLeafletMap={setLeafletMap} automaticHeight={false} showTrackStartStopMarker >
        {geofencesOfTripOrVehicle?.map(geofence => {
            return <Polygon
                positions={geofence.coordinates}
                //pathOptions={pathOptions}
                key={'poly_' + geofence.id}
                idGeoFence={geofence.id}
            > 
                <Tooltip sticky>{geofence.title}</Tooltip>
            </Polygon>
        })}
        {eventsOfCurrentTrip.map(geofenceEvent => Object.keys(geofenceEvent.tripEvents)
            .map(key =>
                renderTripEventsMarker(geofenceEvent.tripEvents[key],
                    geofenceEvent.geofenceName,
                    geofenceEvent.durationOfTripInGeofence))
        )}

    </Map>;
    

    const viewContent = translate => (
        <Grid container className={classes.innerContainer}>
            <Grid item xs={12}>
                <OriginDestinationTimeline trip={trip} showDetailInfo />
                <Typography variant="h3" component="h5">
                    <Translate id="geofenceReport.assignedGeofences" />
                </Typography>
                    <div className={classes.searchStyle}>
                        <ToolbarSearch onSearch={searchTerm => {
                            dispatch(searchLocallyGeofences({
                                searchTerm,
                                module: "geofencesOfCurrentVehicle"
                            }))
                            dispatch(searchLocallyGeofences({
                                searchTerm,
                                module: "geofencesOfCurrentTrip"
                            }))
                        }} />
                    </div>
                <Paper>
                    <List>
                        {geofencesOfTripOrVehicle?.filter(geofence => !geofence.isHidden).map(geofence =>
                            <ListItem button key={geofence.id} style={{ border: "1px solid gray", borderRadius: "5px", marginBottom: "5px" }} onClick={() => leafletMap?.flyToBounds(geofence.coordinates)}>
                                {geofence.archivedAt !== null &&geofence.archivedAt !== undefined
                                    ? <ListItemText>{geofence.title}<Translate id="geofenceReport.archivedSuffix" /></ListItemText>
                                    : <ListItemText>{geofence.title}</ListItemText>
                                }
                            </ListItem>)}        
                        
                    </List>
                </Paper>
            </Grid>
            <Grid item xs={12} className={classes.bottomContainer}>
                
            </Grid>
        </Grid>
    );

    const contents = [
        <Translate>
            {({ translate }) =>
                <Grid container>
                    <Grid item xs={3} className={classes.leftColumn}  style={{overflow:"auto", height:"calc(100vh - 220px)"}}>
                        {viewContent(translate)}
                    </Grid>
                    <Grid item xs={9} style={{ height: "100%" }}>
                        {map}
                    </Grid>
                </Grid>}
        </Translate>,
    ];


    return <CustomModal largeModal
        onClose={onClose}
        showErrorToolbar={false}
        title={<Translate id="trip.trackmodal.title" />}
        contents={contents}
        className={classes.modal} />;
}


export default GeofencesTripIntersectionModal;