import CustomModal, { modalActions } from '../common/modals/CustomModal';
import { Translate } from 'react-localize-redux';
import Button from "@material-ui/core/Button";
import { customColors } from '../geofences/generic/StylingOptions';
import { makeStyles } from '@material-ui/core';

import { ReservationResponseInfo } from '../../types/reservation/reservationResponse.types';

const useStyles = makeStyles({
    container:{
        display: "flex"
    },
    defaultSpacing: {
        marginRight: 5,
        fontSize: 14,
        textAlign: "center"
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "center"
    },
    editButton:{
        color: "white",
        backgroundColor: customColors.ilogsBlue,
        marginRight: 10

    },
    deleteButton:{
        color: "white",
        backgroundColor: customColors.deleteDarkRed,
        marginLeft: 10
    },
});


type EditOrDeleteModalProps = {
    onUpdate: Function,
    onDelete: Function,
    onClose: Function,
    reservation?: ReservationResponseInfo,
}

const EditOrDeleteModal: React.FC<EditOrDeleteModalProps> = ({onUpdate, onDelete, onClose, reservation} ) => {

    const classes = useStyles();
    const askForEditOrDeleteContent  = [
        <>
            <div>
                <p className={classes.defaultSpacing}><Translate id="reservation.editOrDelete"/></p>
            </div>
            <div className={classes.buttonWrapper}>
                <Button className={classes.editButton} onClick={() =>{ onUpdate(reservation); onClose();}}><Translate id="reservation.edit"/></Button>
                <Button className={classes.deleteButton} onClick={() => {
                    onDelete(reservation); onClose();
                }}><Translate id="reservation.delete"/></Button>
            </div>
        </>
    ]

return (
    <CustomModal
        onClose={onClose}
        title={<Translate id="reservation.editOrDeleteModalTitle" />}
        contents={[askForEditOrDeleteContent]}
        action={modalActions.none}
        />
)
}
export default EditOrDeleteModal;