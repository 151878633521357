import React, {useEffect, useState} from "react";
import Page from "../common/container/Page";
import {menu} from "../../managers/pathManager";
import {withLocalize} from "react-localize-redux";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Toolbar from "../common/toolbar/Toolbar";
//Timeline
import VehicleDayTimeline from "./VehicleDayTimeline";
import VehicleWeekTimeline from "./VehicleWeekTimeline";

import {setTextArray, updateFilterAPICall} from "../../actions/actionFilter";
//Icons
import CarIcon from "@material-ui/icons/DirectionsCar";
import PersonIcon from "@material-ui/icons/Person";

import {Button, ButtonGroup} from "@material-ui/core";
import ViewTypes from "./ViewTypes";
import TrackModal from "../trips/tripsModals/TrackModal";
// import TripSplitModal from "../trips/tripsModals/TripSplitModal";
import TripEditModal from "../trips/tripsModals/TripEditModal";
import {useModalStatus} from "../../hooks/useModalStatus";
import DayPicker from "../common/selects/datePickers/DayPicker";
import WeekPicker from "../common/selects/datePickers/WeekPicker";
import {fromToByPeriod} from "../../managers/timeManager";
import {periods} from "../../models/Period";
import FilterModal from "../vehicles/vehicleModals/VehicleFilterModal";
import FilterToolbar from "../common/toolbar/FilterToolbar";
import {vehicleProfileArray} from "../../models/VehicleProfile";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {calendarKey} from "../../managers/localStorageManager";
 

const CalendarPage = props => {
    const [startDay, setStartDay] = useState(fromToByPeriod(periods.day.id).from);
    const [endDay, setEndDay] = useState(fromToByPeriod(periods.day.id).to);

    const [trip, setTrip] = useState("");

    const {modal: filterModal, open: openFilterModal, close: closeFilterModal} = useModalStatus();
    const {modal: trackModal, open: openTrackModal, close: closeTrackModal} = useModalStatus();
    const {modal: editModal, open: openEditModal, close: closeEditModal} = useModalStatus();
    const {value: settings, set: setSettings} = useLocalStorage(calendarKey, {
        period: ViewTypes.Day,
    });
    
    const {period} = settings;
    
    const {filter} = props;
    const {api, initial, updatedAt} = filter;

    useEffect(() => {
        const {groups, vehicleProfiles} = initial;
        const filterParams = [];
        const filterVehicleProfiles = typeof vehicleProfiles === "undefined" ||
            vehicleProfiles.map(vpId => vehicleProfileArray.find(vp => vp.id === vpId).name);

        if (typeof groups !== "undefined" && groups.length > 0)
            filterParams.push("Gruppen: " + groups.map(g => g.label).join(", "));

        if (typeof vehicleProfiles !== "undefined" && vehicleProfiles.length > 0)
            filterParams.push("VehicleProfile: " + filterVehicleProfiles.join(", "));

        props.setTextArray(filterParams);
    }, [updatedAt]);

    function handleSearch(searchTerm) {
        props.updateFilterAPICall({searchTerm}, false);
    }

    function handleDateChange(from, to) {
        setStartDay(from);
        setEndDay(to);
    }

    const picker = period === ViewTypes.Day ? <DayPicker value={startDay} onDateChange={handleDateChange}/> :
        <WeekPicker onDateChange={handleDateChange} value={startDay}/>;

    const toolbar = (
        <Toolbar onSearch={handleSearch} onFilter={openFilterModal}
                 controls={[picker, <ButtonGroup size="small" variant="outlined" style={{margin: "0 3px"}}>
                     <Button color={period === ViewTypes.Day ? "primary" : "default"}
                             onClick={
                                 () => setSettings({...settings, period: ViewTypes.Day})
                             }>
                         Tag
                     </Button>
                     <Button color={period === ViewTypes.Week ? "primary" : "default"}
                             onClick={() => setSettings({...settings, period: ViewTypes.Week})}>
                         Woche
                     </Button>
                 </ButtonGroup>]}>
        </Toolbar>
    );
    return (
        <Page toolbar={toolbar} activePage={menu.calendar.key}>
            <FilterToolbar/>

            {period === ViewTypes.Day && (
                <VehicleDayTimeline
                    from={startDay.toISOString()}
                    to={endDay.toISOString()}
                    setTrip={setTrip}
                    onTrip={openTrackModal}
                />
            )}
            {period === ViewTypes.Week && (
                <VehicleWeekTimeline
                    from={startDay.toISOString()}
                    to={endDay.toISOString()}
                    setTrip={setTrip}
                    onTrip={openTrackModal}
                />
            )}

            {trackModal && <TrackModal id={trip.id} onClose={closeTrackModal} onEdit={openEditModal}/>}
            {editModal && <TripEditModal tripId={trip.id} onClose={closeEditModal}/>}
            {filterModal && <FilterModal onClose={closeFilterModal}/>}
        </Page>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({updateFilterAPICall, setTextArray}, dispatch);
}

function mapStateToProp(state) {
    return {
        filter: state.filter,
        me: state.users.me,
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(withLocalize(CalendarPage));

