import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { menu } from "../../managers/pathManager";
import Page from "../common/container/Page";
import StatusTable from "./StatusTable";
import MeteringPointTable from "./MeteringPointTable";
import QualityChargingToolbar from "../trips/toolbars/QualityChargingToolbar";

import { MESSAGE_TYPES } from "../../types/qualityCharging/messageType";

const QualityChargingPage = () => {
  const [messageType, setMessageType] = useState(MESSAGE_TYPES.METERING_POINT);
  const statuses = useSelector((state) => state.connectors.current.statuses);
  const meteringPoints = useSelector(
    (state) => state.connectors.current.meteringPoints
  );


  return (
    <Page
      activePage={menu.qualityCharging.key}
      toolbar={<QualityChargingToolbar messageType={messageType} setMessageType={setMessageType} />}
    >
      {messageType === MESSAGE_TYPES.STATUS ? (
        <StatusTable statuses={statuses}  />
      ) : (
        <MeteringPointTable meteringPoints={meteringPoints} />
      )}
    </Page>
  );
};

export default QualityChargingPage;
