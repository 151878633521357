import { Box, Tab, Tabs, AppBar } from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    width: "100%", 
    bgcolor: 'background.paper',
    marginTop: "100px"
  },
  tabAppbar: {
    width: "100%",
    backgroundColor: theme.palette.error.dark,
  },
  panelContainer: {
    padding: "35px"
  }
}));


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FaultyTripsTabs({ value, handleTabChange, handleTabChangeIndex}) {
  const classes = useStyles();

  return (
    <Box className={classes.tabContainer}>
      <AppBar position="static" className={classes.tabAppbar}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="Faulty Trips Tabs"
        >
          <Tab label="unrealistic trips" {...a11yProps(0)} />
          <Tab label="Multiple Plug-offs" {...a11yProps(1)} />
          <Tab label="destination mismatches " {...a11yProps(2)} />
        </Tabs>
      </AppBar>
    </Box>
  );
}