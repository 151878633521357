import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { requestUpdateGroup } from "../../../actions/actionGroups";
import { requestUpdateVehicle } from "../../../actions/actionVehicle";
import { requestUpdateUser } from "../../../actions/actionUser";

import InputField from "../../common/inputs/InputField";

import { types } from "../../../models/GroupTypes";
import CustomModal from "../../common/modals/CustomModal";
import { Grid } from "@material-ui/core";
import { Translate } from "react-localize-redux";

const RenameModal = (props) => {
  const [newGroupName, setNewGroupName] = useState("");
  const [newVehicleName, setNewVehicleName] = useState("");
  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");

  const { onClose, group, currentRow, vehicle, user } = props;

  const splitName = currentRow.name.split(" ");

  useEffect(() => {
    if (currentRow.type === types.user) {
      setNewFirstName(splitName[0]);
      setNewLastName(splitName[1]);
    } else {
      setNewVehicleName(splitName[0]);
    }

    setNewGroupName(group.name);
  }, [group, currentRow]);

  function rename() {
    switch (currentRow.type) {
      case types.node:
        props.requestUpdateGroup({ ...group, name: newGroupName });
        break;
      case types.user:
        props.requestUpdateUser({
          ...user,
          firstName: newFirstName,
          lastName: newLastName,
          roleId: user.role.id,
        });
        break;
      case types.vehicle:
        props.requestUpdateVehicle({
          ...vehicle,
          name: newVehicleName,
        });
        break;
    }
    onClose();
  }

  let content = <div />;
  let title = {};
  switch (currentRow.type) {
    case types.node:
      title = <Translate id="group.renameGroup.title" />;
      content = (
        <InputField
          label={<Translate id="group.renameGroup.nameOfGroup" />}
          value={newGroupName}
          onChange={(e) => setNewGroupName(e.target.value)}
        />
      );
      break;
    case types.user:
      title = <Translate id="group.renameUser.title" />;
      content = (
        <Grid container direction="row" spacing={2}>
          <Grid item xs={6}>
            <InputField
              label={<Translate id="group.renameUser.firstname" />}
              value={newFirstName}
              onChange={(e) => setNewFirstName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              label={<Translate id="group.renameUser.lastname" />}
              value={newLastName}
              onChange={(e) => setNewLastName(e.target.value)}
            />
          </Grid>
        </Grid>
      );
      break;
    case types.vehicle:
      title = <Translate id="group.renameVehicle.title" />;
      content = (
        <InputField
          label={<Translate id="group.renameVehicle.nameOfVehicle" />}
          value={newVehicleName}
          onChange={(e) => setNewVehicleName(e.target.value)}
        />
      );
      break;
  }

  return (
    <CustomModal
      title={title}
      action="update"
      contents={[content]}
      onSave={rename}
      onClose={onClose}
    />
  );
};

// RenameModal.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   id: PropTypes.string.isRequired,
//   type: PropTypes.oneOf(["node", "vehicle", "user"]).isRequired,
// };

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestUpdateGroup,
      requestUpdateVehicle,
      requestUpdateUser,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return state
}

export default connect(mapStateToProp, mapDispatchToProps)(RenameModal);
