import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormModal from "../../../common/modals/FormModal";
import FormBuilder from "../../../../managers/formBuilder";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { requestAddVehicleService } from "../../../../actions/actionVehicleService";
import { Translate } from "react-localize-redux";
import { Input } from "antd";
import DayPicker from "../../../common/selects/datePickers/DayPicker";
import { useInputChangeHandler } from "../../../../hooks/useInputChangeHandler";

const useStyles = makeStyles({
  listItem: {
    marginTop: "-21px",
    marginLeft: "-15px",
  },
});

const DEFAULT_INPUT_VALUES = {
  date: null,
  km: null,
  description: null,
};

const ServiceAddModal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.vehicles.current);
  const { handleInputChange, fields } =
    useInputChangeHandler(DEFAULT_INPUT_VALUES);

  const { date, km, description } = fields;

  const { onClose } = props;

  let inputs = [
    new FormBuilder.FormInput(
      "Datum",
      (
        <DayPicker
          style={{ width: "100%" }}
          value={date}
          onDateChange={(value) => handleInputChange("date", value)}
        />
      )
    )
      .withValue(date)
      .withKey("date")
      .withValidationMessage("Bitte setzte ein Datum")
      .isRequired()
      .build(),
    new FormBuilder.FormInput(
      "Kilometerstand",
      (
        <Input
          type="number"
          value={km}
          onChange={(e) => handleInputChange("km", e.target.value)}
        />
      )
    )
      .withKey("km")
      .withValidationMessage("Bitte setzte deinen Kilometerstand")
      .isRequired()
      .build(),
    new FormBuilder.FormInput(
      <Translate id="user.editmodal.description" />,
      (
        <Input.TextArea
          value={description}
          onChange={(e) => handleInputChange("description", e.target.value)}
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      )
    )
      .withKey("description")
      .build(),
  ];

  const handleSave = () => {
      dispatch(
        requestAddVehicleService({
          vehicleId: vehicle.id,
          serviceDate: new Date(date).toISOString(),
          serviceAt: km * 1000,
          description: description,
        })
      );
      onClose();
  };

  return (
    <FormModal
      action="add"
      onClose={onClose}
      onSave={handleSave}
      title="Neues Service hinzufügen"
      inputs={inputs}
    />
  );
};

// ServiceAddModal.propTypes = {
//   onClose: PropTypes.func.isRequired,
// };

export default ServiceAddModal;
