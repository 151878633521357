import { Translate } from "react-localize-redux"

export const DATE_PICKER_TYPE = {
    rawDataFilterDatePicker: "rawDataFilterDatePicker",
}

export const CustomDatePickerProps = {
    minDateMessage: <Translate id="common.datePicker.minDateMessage"/>,
    maxDateMessage: <Translate id="common.datePicker.maxDateMessage"/>,
    invalidDateMessage: <Translate id="common.datePicker.invalidDateMessage"/>,
}

export function getTodayDateRange() {
    const currentDate = new Date();
    const from = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0).toISOString();
    const to = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59).toISOString();
    
    return { from, to };
}