import { EditControl } from 'react-leaflet-draw';
import axios from 'axios';
import { isSelfIntersecting } from '../../utils/geometryUtils';
import L from 'leaflet';
import { withLocalize } from 'react-localize-redux';
import { useMapEvent } from 'react-leaflet';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { requestUpdateGeofenceVehicles } from '../../../../actions/actionGeofences';


const baseUrl = window.location.origin + "/api";

function MyEditComponent(props) {
    let { currentUser, geoFences, setDialog, setMapState, addGeoFenceInState } = props;

    const dispatch = useDispatch();

    function _onCreated(e) {
        let createdLayer = e.layer;

        if (e.layerType === 'circle') {
            // circle
            onCircleCreated(createdLayer);
        } else {
            // polygon or rectangle
            onPolygonCreated(createdLayer);
        }
    }

    function _onEdited(e) {
        let editedLayers = e.layers._layers;
        let count = Object.keys(editedLayers).length;

        if (count <= 0)
            return;

        let firstGeoFenceTitle = geoFences.get(editedLayers[Object.keys(editedLayers)[0]].options.idGeoFence).geoFence.Title;

        setDialog({
            isOpen: true,
            type: 'confirm',
            title: (count === 1 ? props.translate('dialogs.confirmChanges', { title: firstGeoFenceTitle }) : props.translate('dialogs.confirmChangesMulti', { count: count })),
            btnConfirm: props.translate('dialogs.buttons.save'),
            confirm: () => {
                for (let key of Object.keys(editedLayers)) {
                    onPolygonEdited(editedLayers[key]);
                }
            },
            cancel: () => {
                window.location.reload(); // alternative: find a way to reset geometry directly in mapLayer
            }
        });
    }

    function onPolygonCreated(createdLayer) {
        setDialog({
            geofenceId: true,
            isOpen: true,
            type: 'prompt',
            title: props.translate('dialogs.createDialogTitle'),
            label: props.translate('dialogs.createDialogLabel'),
            defaultText: '',
            isAntiTheftEnabled: false,
            notificationEmail: '',
            notificationMobileNumber: '',
            btnConfirm: props.translate('dialogs.buttons.create'),
            confirm: (newTitle, n, isAntiTheft, notificationEmail, notificationMobileNumber) => {
                let newObj = {
                    title: newTitle,
                    points: createdLayer._latlngs[0],
                    isAntiTheftEnabled: isAntiTheft,
                    notificationEmail: notificationEmail,
                    notificationMobileNumber: notificationMobileNumber,
                    username: currentUser
                }

                newObj.points.push(newObj.points[0]);

                if (isSelfIntersecting(newObj.points)) {
                    alert(props.translate('errors.selfIntersection'));
                    createdLayer._map.removeLayer(createdLayer);
                    return;
                }

                let newStr = JSON.stringify(newObj);
                newStr = newStr.replace(/"lng":/g, '"long":');

                // create polygon with API
                axios({
                    method: 'post',
                    url: `${baseUrl}/geoFences/`,
                    data: newStr,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: currentUser
                    }
                })
                    .then((res) => {
                        dispatch(requestUpdateGeofenceVehicles(res.data.ID));
                        addGeoFenceInState(res.data.ID, newTitle, createdLayer._latlngs, false, false, isAntiTheft, notificationEmail, notificationMobileNumber);
                    })
                    .catch(err => {
                        alert(props.translate('errors.couldNotCreateGeofence'));
                        createdLayer._map.removeLayer(createdLayer);
                    })
            },
            cancel: () => {
                createdLayer._map.removeLayer(createdLayer);
            }
        });
    }

    function onCircleCreated(createdLayer) {
        setDialog({
            geofenceId: true,
            isOpen: true,
            type: 'prompt',
            title: props.translate('dialogs.createDialogTitle'),
            label: props.translate('dialogs.createDialogLabel'),
            defaultText: '',
            isAntiTheftEnabled: false,
            notificationEmail: '',
            notificationMobileNumber: '',
            btnConfirm: props.translate('dialogs.buttons.confirm'),
            confirm: (newTitle, n, isAntiTheft, notificationEmail, notificationMobileNumber) => {
                let newObj = {
                    lat: createdLayer._latlng.lat,
                    long: createdLayer._latlng.lng,
                    title: newTitle,
                    radius: Math.floor(createdLayer._mRadius),
                    isAntiTheftEnabled: isAntiTheft,
                    notificationEmail: notificationEmail,
                    notificationMobileNumber: notificationMobileNumber,
                    username: currentUser
                }

                let newStr = JSON.stringify(newObj);

                // create circle with API
                axios({
                    method: 'post',
                    url: `${baseUrl}/geoFences/circle/`,
                    data: newStr,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: currentUser
                    }
                })
                    .then(res => {
                        let newLatLngs = [[]];

                        // convert coordinates from DB to Leaflet format
                        for (let coordPair of res.data.geoPoints) {
                            newLatLngs[0].push([coordPair.lat, coordPair.long]);
                        }
                        dispatch(requestUpdateGeofenceVehicles(res.data.id));
                        addGeoFenceInState(res.data.id, newTitle, newLatLngs, false, true, isAntiTheft, notificationEmail, notificationMobileNumber);
                    })
                    .catch(err => {
                        alert(props.translate('errors.couldNotCreateGeofence'));
                        createdLayer._map.removeLayer(createdLayer);
                    })

            },
            cancel: () => {
                createdLayer._map.removeLayer(createdLayer);
            }
        });
    }

    function onPolygonEdited(editedLayer) {
        let geoFenceTitle = geoFences.get(editedLayer.options.idGeoFence).geoFence.Title;

        let editedObj = {
            title: geoFenceTitle,
            points: editedLayer._latlngs[0],
            username: currentUser
        }
        editedObj.points.push(editedObj.points[0]);

        let newStr = JSON.stringify(editedObj);
        newStr = newStr.replace(/"lng":/g, '"long":');

        // edit polygon with API
        axios({
            method: 'patch',
            url: `${baseUrl}/geoFences/${editedLayer.options.idGeoFence}`,
            data: newStr,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: currentUser
            }
        })
            .catch(err => {
                alert(props.translate('errors.couldNotSaveChanges', { title: geoFenceTitle }));
                //window.location.reload(); // alternative: find a way to reset geometry directly in mapLayer
            })
    }


    useMapEvent("moveend", (e) => {
        setMapState({
            zoom: e.target.getZoom(),
            position: e.target.getCenter()
        });
    });

    L.drawLocal = {
        draw: {
            toolbar: {
                actions: {
                    title: props.translate('drawLocal.draw.toolbar.actions.title'),
                    text: props.translate('drawLocal.draw.toolbar.actions.text')
                },
                finish: {
                    title: props.translate('drawLocal.draw.toolbar.finish.title'),
                    text: props.translate('drawLocal.draw.toolbar.finish.text')
                },
                undo: {
                    title: props.translate('drawLocal.draw.toolbar.undo.title'),
                    text: props.translate('drawLocal.draw.toolbar.undo.text')
                },
                buttons: {
                    polyline: props.translate('drawLocal.draw.toolbar.buttons.polyline'),
                    polygon: props.translate('drawLocal.draw.toolbar.buttons.polygon'),
                    rectangle: props.translate('drawLocal.draw.toolbar.buttons.rectangle'),
                    circle: props.translate('drawLocal.draw.toolbar.buttons.circle'),
                    marker: props.translate('drawLocal.draw.toolbar.buttons.marker'),
                    circlemarker: props.translate('drawLocal.draw.toolbar.buttons.circlemarker'),
                }
            },
            handlers: {
                circle: {
                    tooltip: {
                        start: props.translate('drawLocal.draw.handlers.circle.tooltip.start')
                    },
                    radius: props.translate('drawLocal.draw.handlers.circle.radius')
                },
                circlemarker: {
                    tooltip: {
                        start: props.translate('drawLocal.draw.handlers.circlemarker.tooltip.start')
                    }
                },
                marker: {
                    tooltip: {
                        start: props.translate('drawLocal.draw.handlers.marker.tooltip.start')
                    }
                },
                polygon: {
                    tooltip: {
                        start: props.translate('drawLocal.draw.handlers.polygon.tooltip.start'),
                        cont: props.translate('drawLocal.draw.handlers.polygon.tooltip.cont'),
                        end: props.translate('drawLocal.draw.handlers.polygon.tooltip.end')
                    }
                },
                polyline: {
                    error: props.translate('drawLocal.draw.handlers.error'),
                    tooltip: {
                        start: props.translate('drawLocal.draw.handlers.polyline.tooltip.start'),
                        cont: props.translate('drawLocal.draw.handlers.polyline.tooltip.cont'),
                        end: props.translate('drawLocal.draw.handlers.polyline.tooltip.end')
                    }
                },
                rectangle: {
                    tooltip: {
                        start: props.translate('drawLocal.draw.handlers.rectangle.tooltip.start')
                    }
                },
                simpleshape: {
                    tooltip: {
                        end: props.translate('drawLocal.draw.handlers.simpleshape.tooltip.end')
                    }
                }
            }
        },
        edit: {
            toolbar: {
                actions: {
                    save: {
                        title: props.translate('drawLocal.edit.toolbar.actions.save.title'),
                        text: props.translate('drawLocal.edit.toolbar.actions.save.text')
                    },
                    cancel: {
                        title: props.translate('drawLocal.edit.toolbar.actions.cancel.title'),
                        text: props.translate('drawLocal.edit.toolbar.actions.cancel.text')
                    },
                    clearAll: {
                        title: props.translate('drawLocal.edit.toolbar.actions.clearAll.title'),
                        text: props.translate('drawLocal.edit.toolbar.actions.clearAll.text')
                    }
                },
                buttons: {
                    edit: props.translate('drawLocal.edit.toolbar.buttons.edit'),
                    editDisabled: props.translate('drawLocal.edit.toolbar.buttons.editDisabled'),
                    remove: props.translate('drawLocal.edit.toolbar.buttons.remove'),
                    removeDisabled: props.translate('drawLocal.edit.toolbar.buttons.removeDisabled'),
                }
            },
            handlers: {
                edit: {
                    tooltip: {
                        text: props.translate('drawLocal.edit.handlers.edit.tooltip.text'),
                        subtext: props.translate('drawLocal.edit.handlers.edit.tooltip.subtext')
                    }
                },
                remove: {
                    tooltip: {
                        text: props.translate('drawLocal.edit.handlers.remove.tooltip.text')
                    }
                }
            }
        }
    };

    return (
        <EditControl
            position='topleft'
            draw={{
                marker: false,
                circlemarker: false,
                polyline: false,
                polygon: {
                    allowIntersection: false,
                },
            }}
            edit={{
                remove: false,
            }}
            onCreated={e => _onCreated(e)}
            onEdited={e => _onEdited(e)}
        />
    );
}

export default withLocalize(MyEditComponent);
