import React, { useEffect, useState } from "react";
import useCollapse from "react-collapsed";
import { getWeekDayName } from "../../../utils/dateTimeUtils";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ListAltIcon from '@material-ui/icons/ListAlt';
import axios from "axios";
import { ClickAwayListener, Tooltip } from "@material-ui/core";
import { customColors } from "../../../generic/StylingOptions";
import HistoryTooltip from "./HistoryTooltip";
import Popper from '@material-ui/core/Popper';
import ListItemMenu from './ListItemMenu';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Translate, withLocalize } from "react-localize-redux";
import { compareByReference, objAreEqual } from "../../../utils/comparisonUtils";
import OverflowTip from "../../../generic/OverflowTip";
import { useDispatch } from "react-redux";
import { requestGeofenceToggleArchive, requestUpdateGeofenceVehicles } from "../../../../../actions/actionGeofences";
import ArchiveModal from "../../../../common/modals/ArchiveModal";
import { useModalStatus } from "../../../../../hooks/useModalStatus";

const baseUrl = window.location.origin + "/api";

function GeoFenceListItem(props) {
    let { id, map, geoFences, setGeoFences, isActive, editHistory, currentUser,
        geoFenceMetadata, visibilityObj, setVisibilityObj,
        setGeoFenceMetadata, setDialog, removeFromSelection, geoFenceLocks, setGeoFenceLocks } = props;

    const [isExpanded, setExpanded] = useState(isActive);
    const [showTooltip, setShowTooltip] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const { getToggleProps, getCollapseProps } = useCollapse({
        isExpanded
    });

    const {
        modal: archiveModal,
        open: openArchiveModal,
        close: closeArchiveModal,
    } = useModalStatus();

    useEffect(() => {
        setExpanded(isActive);
        let visibility = JSON.parse(localStorage.getItem("visibility"));
        let newGeoFences = geoFences;

        let visible = false;

        if (visibility) {
            visible = visibility[`id_${id}`] || false;
        }

        try {
            newGeoFences.get(id).geoFence.Hidden = visible;
        } catch {

        }

        setGeoFences(new Map(newGeoFences));
    }, [isActive, setExpanded]);

    function toggleLocking(weekday) {
        axios({
            method: 'get',
            url: `${baseUrl}/geoFences/${id}/${weekday}/2`,
            headers: {
                Authorization: currentUser
            }
        }).then((res) => {
            let newGeoFenceLocks = geoFenceLocks;
            let foundIndex = -2;
            if (newGeoFenceLocks[`id_${id}`])
                foundIndex = newGeoFenceLocks[`id_${id}`].indexOf(weekday);

            if (foundIndex === -2) {
                newGeoFenceLocks[`id_${id}`] = [];
                newGeoFenceLocks[`id_${id}`].push(weekday);
            } else if (foundIndex === -1) {
                newGeoFenceLocks[`id_${id}`].push(weekday);
            } else {
                newGeoFenceLocks[`id_${id}`].splice(foundIndex, 1);
            }

            setGeoFenceLocks({ ...newGeoFenceLocks });
        }).catch((err) => {
            alert(props.translate('errors.couldNotToggleLocking'));
            console.log(err);
        })
    }

    function onDelete() {
        setDialog({
            isOpen: true,
            type: 'confirm',
            danger: true,
            title: props.translate('dialogs.confirmDelete', { title: geoFences.get(id).geoFence.Title }),
            btnConfirm: props.translate('dialogs.buttons.delete'),
            confirm: () => {
                axios({
                    method: 'delete',
                    url: `${baseUrl}/geoFences/${id}`,
                    headers: {
                        Authorization: currentUser
                    }
                }).then(res => {
                    removeFromSelection();
                    let newGeoFences = geoFences;
                    newGeoFences.delete(id);
                    setGeoFences(new Map(newGeoFences));
                }).catch(err => {
                    let geoFenceTitle = geoFences.get(id).Title;
                    alert(props.translate('errors.couldNotDeleteGeofence', { title: geoFenceTitle }));
                    console.log(err);
                })
            },
            cancel: () => {

            }
        });
    }


    function setModal() {
        return (<ArchiveModal
            title="Archive Geofence"
            onClose={closeArchiveModal}
            noComment={true}
            onArchive={(comment) => {
                try {
                    dispatch(
                        requestGeofenceToggleArchive({
                            geofenceId: id
                        })
                    )
                    removeFromSelection();
                    let newGeoFences = geoFences;
                    newGeoFences.delete(id);
                    setGeoFences(new Map(newGeoFences));
                } catch (e) {
                    let geoFenceTitle = geoFences.get(id).Title;
                    alert(props.translate('errors.couldNotToggleArchiveGeofence', { title: geoFenceTitle }));
                    console.log(e);
                };
            }}
        />);
    }

    function onArchive() {
        openArchiveModal();
        return (<ArchiveModal
            title="Archive Geofence"
            onClose={closeArchiveModal}
            description={props.translate('dialogs.deleteNotification', { title: geoFences.get(id).geoFence.Title })}
            noComment={ true }
            onArchive={(comment) => {
        try {
            dispatch(
                requestGeofenceToggleArchive({
                    geofenceId: id
                })
            )
            removeFromSelection();
            let newGeoFences = geoFences;
            newGeoFences.delete(id);
            setGeoFences(new Map(newGeoFences));
        } catch (e) {
            let geoFenceTitle = geoFences.get(id).Title;
            alert(props.translate('errors.couldNotToggleArchiveGeofence', { title: geoFenceTitle }));
            console.log(e);
        };
            }}
        />);
    }

    function showHistory(e) {
        if (editHistory.length > 0) {
            setAnchorEl(e.currentTarget);
            setShowTooltip(!showTooltip);
        }

    }

    function onHighlight(temp = 0) {
        let newGeoFences = geoFences;

        // unhide and highlight geofence
        if (!newGeoFences.get(id).geoFence.Highlighted) {
            newGeoFences.get(id).geoFence.Hidden = false;
            newGeoFences.get(id).geoFence.Highlighted = true;
        }

        // set map view to center on highlighted geofence
        map.flyToBounds(newGeoFences.get(id).getLatLngs());

        // only highlight one at a time
        [...newGeoFences.keys()].forEach(currentId => {
            if (currentId !== id) {
                newGeoFences.get(currentId).geoFence.Highlighted = false;
            }
        })

        setGeoFences(new Map(newGeoFences));
    }

    function onToggleVisibility() {
        let newGeoFences = geoFences;

        if (newGeoFences.get(id).geoFence.Hidden) {
            newGeoFences.get(id).geoFence.Hidden = false;
        } else {
            newGeoFences.get(id).geoFence.Hidden = true;
            newGeoFences.get(id).geoFence.Highlighted = false;
        }

        let obj = { ...visibilityObj };

        newGeoFences.forEach(e => {
            obj[`id_${e.geoFence.ID}`] = e.geoFence.Hidden || false;
        });

        setVisibilityObj(obj);
        localStorage.setItem("visibility", JSON.stringify(obj));

        setGeoFences(new Map(newGeoFences));
    }

    function onEditTitle() {
        setDialog({
            geofenceId: id,
            isOpen: true,
            type: 'prompt',
            title: props.translate("dialogs.editTitle"),
            label: props.translate("dialogs.renameLabel"),
            defaultText: geoFences.get(id).geoFence.Title,
            isAntiTheftEnabled: geoFences.get(id).geoFence.IsAntiTheftEnabled,
            btnConfirm: props.translate('dialogs.buttons.save'),
            notificationEmailStr: geoFences.get(id).geoFence.NotificationEmail,
            notificationMobileNumberStr: geoFences.get(id).geoFence.NotificationMobileNumber,
            confirm: (newTitle, n, isAntiTheft, notificationEmail, notificationMobileNumber) => {
                let editedObj = {
                    title: newTitle,
                    isAntiTheftEnabled: isAntiTheft,
                    notificationEmail: notificationEmail,
                    notificationMobileNumber: notificationMobileNumber,
                    username: currentUser
                }
                let newStr = JSON.stringify(editedObj);

                axios({
                    method: 'patch',
                    url: `${baseUrl}/geoFences/${id}`,
                    data: newStr,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        //Authorization: currentUser
                    }
                })
                    .then(res => {
                        let newGeoFences = geoFences;
                        newGeoFences.get(id).geoFence.Title = newTitle;
                        newGeoFences.get(id).geoFence.IsAntiTheftEnabled = isAntiTheft;
                        newGeoFences.get(id).geoFence.NotificationEmail = notificationEmail;
                        newGeoFences.get(id).geoFence.NotificationMobileNumber = notificationMobileNumber;
                        setGeoFences(new Map(newGeoFences));
                    })
                    .catch(err => {
                        console.log(`Could not edit title for geofence ${id}`, err)
                    })
                dispatch(requestUpdateGeofenceVehicles(id));

            },
            cancel: () => {

            }
        });
    }

    function onToggleCollapsible() {
        setExpanded((x) => !x)
    }

    return (
        <div className={geoFences.get(id).Hidden ? "collapsible-wrapper hidden" : "collapsible-wrapper"}>
            <div className="collapsible-header">
                <div className="collapsible-header-button">
                    <IconButton
                        id={`btn_collapse_${id}`}
                        aria-label="collapse"
                        style={{ color: customColors.dark, padding: '2px' }}
                        {...getToggleProps({
                            onClick: () => onToggleCollapsible()
                        })}
                    >
                        {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </div>
                <div className="collapsible-header-title">
                    <OverflowTip>
                        {geoFences.get(id).geoFence.Title}
                    </OverflowTip>
                </div>
                <div className="collapsible-header-actions">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <ClickAwayListener onClickAway={() => { setShowTooltip(false) }}>
                                        <div>
                                            <Tooltip enterDelay={500} title={<Translate id="fenceList.history"></Translate>}>
                                                <IconButton
                                                    id={`btn_history_${id}`}
                                                    aria-label="history"
                                                    onClick={showHistory}
                                                    style={{ color: (editHistory?.length > 0 ? customColors.dark : customColors.disabled), padding: '2px' }}
                                                    disabled={!(editHistory?.length > 0)}
                                                >
                                                    <ListAltIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Popper transition className="popper-ui" style={{ overflow: "hidden", maxWidth: "250px" }} placement="left" open={showTooltip} anchorEl={anchorEl}>
                                                <HistoryTooltip historyData={editHistory}></HistoryTooltip>
                                            </Popper>
                                        </div>
                                    </ClickAwayListener>
                                </td>
                                <td>
                                    <Tooltip enterDelay={500} title={<Translate id={geoFences.get(id).geoFence.Hidden ? 'fenceList.show' : 'fenceList.hide'}></Translate>}>
                                        <IconButton
                                            onClick={onToggleVisibility}
                                            style={{ color: (geoFences.get(id).geoFence.Hidden ? customColors.danger : customColors.dark), padding: '2px' }}
                                        >
                                            {geoFences.get(id).geoFence.Hidden ?
                                                <VisibilityOffIcon /> :
                                                <VisibilityIcon />
                                            }
                                        </IconButton>
                                    </Tooltip>
                                </td>
                                <td>
                                    <ListItemMenu
                                        isDisabled={false}
                                        thisGeoFence={geoFences.get(id)}
                                        onEditTitle={onEditTitle}
                                        onHighlight={onHighlight}
                                        onArchive={onArchive}
                                        onDelete={onDelete}
                                        onToggleVisibility={onToggleVisibility}
                                        geoFenceMetadata={geoFenceMetadata}
                                        setGeoFenceMetadata={setGeoFenceMetadata}
                                        setDialog={setModal}
                                        currentUser={currentUser}
                                    ></ListItemMenu>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="collapsible-content" {...getCollapseProps()}>
                {[1, 2, 3, 4, 5, 6, 0].map((weekday) => {
                    return (
                        <button
                            id={`btn_toggleLocked_${id}_${weekday}`}
                            key={`btn_toggleLocked_${id}_${weekday}`}
                            className={geoFenceLocks[`id_${id}`]?.includes(weekday) ? "lockingBtn lockingBtnLocked" : "lockingBtn"}
                            onClick={() => toggleLocking(weekday)}>
                            {<Translate id={`day.${getWeekDayName(weekday)}`}></Translate>}
                        </button>
                    );
                })}
            </div>
            {archiveModal && (
                <ArchiveModal
                    title=""
                    noComment={true}
                    description={props.translate('dialogs.deleteNotification', { title: geoFences.get(id).geoFence.Title })}
                    elementId={id}
                    onClose={closeArchiveModal}
                    onArchive={id => {
                        try {
                            dispatch(
                                requestGeofenceToggleArchive({
                                    geofenceId: id
                                })
                            )
                            removeFromSelection();
                            let newGeoFences = geoFences;
                            newGeoFences.delete(id);
                            setGeoFences(new Map(newGeoFences));
                        } catch (e) {
                            let geoFenceTitle = geoFences.get(id).Title;
                            alert(props.translate('errors.couldNotToggleArchiveGeofence', { title: geoFenceTitle }));
                            console.log(e);
                        }; }
                        //props.requestCostcenterToggleArchive({ id: geoFences.get(id) })
                    }
                />
            )}
        </div>
    );
}

export default React.memo(withLocalize(GeoFenceListItem), isEqual);

function isEqual(prevProps, nextProps) {
    if (objAreEqual(prevProps.id, nextProps.id) &&
        compareByReference(prevProps.id, nextProps.id) &&
        compareByReference(prevProps.map, nextProps.map) &&
        objAreEqual(prevProps.geoFences, nextProps.geoFences) &&
        compareByReference(prevProps.geoFences, nextProps.geoFences) &&
        objAreEqual(prevProps.geoFenceMetadata, nextProps.geoFenceMetadata) &&
        compareByReference(prevProps.geoFenceMetadata, nextProps.geoFenceMetadata) &&
        objAreEqual(prevProps.visibilityObj, nextProps.visibilityObj) &&
        compareByReference(prevProps.visibilityObj, nextProps.visibilityObj) &&
        objAreEqual(prevProps.geoFenceLocks, nextProps.geoFenceLocks) &&
        compareByReference(prevProps.geoFenceLocks, nextProps.geoFenceLocks)) {
        return true;
    }
    return false;
}
