import React, {useEffect} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Timeline} from "antd";
import {requestCalibrations} from "../../../actions/actionVehicle";
import {format} from "date-fns";
import {Translate} from "react-localize-redux";

const VehicleCalibrationHistoryContent = props => {
    const {calibrations} = props;

    useEffect(() => {
        props.requestCalibrations(props.id);
    }, []);


    if (calibrations.length === 0) {
        return <h3><Translate id="vehicle.calibrationModal.noCalibration"/></h3>;
    }

    return (
        <Timeline>
            {calibrations.map(entry => {
                return (
                    <Timeline.Item>
                        <p>
                            <Translate id="vehicle.calibrationModal.changeMessage" data={{
                                mileageInMeter: entry.actualMileageInMeters / 1000,
                                newMileageInMeter: entry.calibratedMileageInMeters / 1000,
                                date: format(new Date(entry.calibratedAt), "dd.MM.yy H:mm"), 
                            }}/> 
                            <br/>
                            <Translate id="vehicle.calibrationModal.byMessage" data={{
                                date: format(new Date(entry.createdAt), "dd.MM.yy H:mm"),
                                user: entry.user ? entry.user.name : ""
                            }}/>
                        </p>
                    </Timeline.Item>
                );
            })}
        </Timeline>
    );

};

// VehicleCalibrationHistoryContent.propTypes = {
//     id: PropTypes.string.isRequired,
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({requestCalibrations}, dispatch);
}

function mapStateToProp(state) {
    return {calibrations: state.vehicles.calibrations.entities};
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(VehicleCalibrationHistoryContent);
