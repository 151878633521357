import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Card, CardContent, Paper, Toolbar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DashboardOpenTrips from "./DashboardOpenTrips";
import DashboardTripsWithoutDriver from "./DashboardTripsWithoutDriver";
import { Translate } from "react-localize-redux";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "5px 5px",
  },
  card: {
    minWidth: 275,
    margin: "3px 5px",
    overflowY: "scroll",
  },
}));

const DashboardTabs = (props) => {
  const { date } = props;
  const me = useSelector((state) => state.users.me);
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTabs =
    me.role.name === "Admin" ? (
      <Tab
        label={<Translate id="dashboard.tabs.unlockedTrips" />}
        {...a11yProps(0)}
      />
    ) : (
      <></>
    );

  const renderTabPanels =
    me.role.name === "Admin" ? (
      <TabPanel value={value} index={0}>
        <DashboardOpenTrips date={date} />
      </TabPanel>
    ) : (
      <></>
    );

  return (
    <Paper square className={classes.root}>
      <Tabs
        className={classes.tab}
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        {renderTabs}
        <Tab
          label={<Translate id="dashboard.tabs.tripsWithoutDriver" />}
          {...a11yProps(1)}
        />
      </Tabs>

      {renderTabPanels}
      <TabPanel value={value} index={1}>
        <DashboardTripsWithoutDriver date={date} />
      </TabPanel>
    </Paper>
  );
};

export default connect()(DashboardTabs);
