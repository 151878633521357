import { MenuItem, Select, TextField, IconButton, Tooltip, Toolbar, Box } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import React, {useState} from 'react'
import { customColors } from '../../generic/StylingOptions';
import ClearIcon from '@material-ui/icons/Clear';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import { Translate, withLocalize } from 'react-localize-redux';

function MetadataSearchBar(props) {
    let { dockSize, getGeoFenceDataWithSearch,
        searchMode, setSearchMode, } = props;

    const [selectValue, setSelectValue] = useState(1);
    const [searchValue, setSearchValue] = useState("");

    function handleSelect(e) {
        setSelectValue(e.target.value);
    }

    function onSearch() {
        if (searchValue) {
            setSearchMode(2);
            getGeoFenceDataWithSearch(selectValue, searchValue);
        }
    }

    function onClear() {
        setSearchValue('');
    }

    return (
        <div className="searchBarWrapperDiv" style={{ width: `${dockSize}px`, height: (searchMode ? '64px' : '0'), transition: 'height 0.3s' }}>
            <Toolbar>
                <Box display='flex' flexGrow={1}>
                    <div className="searchSelect">
                        <Select style={{ color: 'inherit' }} value={selectValue} className="searchSelect" onChange={handleSelect}>
                            <MenuItem value={1}><PersonIcon style={{ marginRight: '15px' }}></PersonIcon><Translate id="category.worker"></Translate></MenuItem>
                            <MenuItem value={99}><InfoIcon style={{ marginRight: '15px' }}></InfoIcon><Translate id="category.others"></Translate></MenuItem>
                            <MenuItem value={100}><TextFormatIcon style={{ marginRight: '15px' }}></TextFormatIcon><Translate id="category.name"></Translate></MenuItem>
                        </Select>
                    </div>
                    <TextField 
                        value={searchValue} 
                        placeholder={props.translate("searchBar.placeholder")} 
                        variant="outlined" 
                        size="small"
                        onChange={(e) => {setSearchValue(e.target.value)}}
                        fullWidth={true}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {     // Enter key
                                e.preventDefault();
                                onSearch();
                            }
                        }}
                    ></TextField>
                </Box>
                <Tooltip enterDelay={500} title={<Translate id="searchBar.clear"></Translate>}>
                    <IconButton
                        size="small" className="searchButton" 
                        style={{ margin: '2px 4px 6px -38px' }}
                        onClick={onClear}
                        disabled={!searchValue}
                    >
                        <ClearIcon className="searchIcon" style={{ color: (searchValue ? customColors.dark : customColors.bright), fontSize: 24 }}></ClearIcon>
                    </IconButton>
                </Tooltip>
                <Tooltip enterDelay={500} title={<Translate id="searchBar.search"></Translate>}>
                    <IconButton
                        size="small" className="searchButton" 
                        style={{ backgroundColor: (searchValue ? customColors.ilogsBlue : customColors.disabledDark),
                                color: 'inherit',
                                borderRadius: '0 5px 5px 0',
                                padding: '7.5px',
                                marginBottom: '6px',
                                marginLeft: '0px' }}
                        onClick={onSearch}
                        disabled={!searchValue}
                    >
                        <SearchIcon className="searchIcon" style={{ color: customColors.bright, fontSize: 30 }}></SearchIcon>
                    </IconButton>
                </Tooltip>
            </Toolbar>
        </div>
    )
}

export default withLocalize(MetadataSearchBar);
