import {
    getEntityById
  } from "../../managers/reducerManager";

import { requestConnectorById, receiveConnectorById } from "../../actions/qualityCharging/actionConnectors";
  
  import { AnyAction } from "redux";
  
  export type ConnectorState = {
      readonly entities: any[];
      readonly current: any;
      readonly loading: boolean;
    };
  
  const CONNECTOR_INITIAL_STATE: ConnectorState = {
    entities: [],
    current: { meteringPoints: [], statuses: [] },
    loading: false,
  };
  
  export const connectorsReducer = (state = CONNECTOR_INITIAL_STATE, action: AnyAction): ConnectorState => {

      if(receiveConnectorById.match(action)) {
        return getEntityById(state, action.data);
      }

      if(requestConnectorById.match(action)) {
        return {...state, loading: true}
      }
  
      return state;
  };
  