import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fromToByPeriod} from "../../../managers/timeManager";
import {periods} from "../../../models/Period";
import {updateFilterAPICall} from "../../../actions/actionFilter";
import {requestTimeLogExportPdf} from "../../../actions/actionExport";
import Grid from "@material-ui/core/Grid";
import PeriodDatePicker from "../../common/selects/datePickers/PeriodDatePicker";
import Button from "@material-ui/core/Button";
import {Translate} from "react-localize-redux";
import EditModal from "../../common/modals/CustomModal";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import UserSelect from "../../common/selects/specificSelects/UserSelect";
import GeofenceSelect from "../../common/selects/specificSelects/GeofenceSelect";

const useStyles = makeStyles((theme) => ({
    exportButton: {
        marginTop: theme.spacing(1),
    },
}));

const TimeLogExportModal = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector(state => state.filter.api);

    const {onClose} = props;

    const defaultRange = fromToByPeriod(periods.month.id, new Date());

    useEffect(() => {
        handleDateChange(periods.month.id, defaultRange.from, defaultRange.to)
    }, [])

    function handleExport() {
        let driverIds = filter.userIds;
        dispatch(requestTimeLogExportPdf({...filter, driverIds}));

        onClose();
    }

    function handleDateChange(period, from, to) {
        dispatch(updateFilterAPICall({period, from: new Date(from).toISOString(), to: new Date(to).toISOString()}))
    }

    function handleUserChange(userIds) {
        dispatch(updateFilterAPICall({userIds}))
    }

    const content = <Grid container spacing={1}>
        <Grid item xs={3}>
            Zeitraum
        </Grid>

        <Grid item xs={9}>
            <PeriodDatePicker period={filter.period || periods.month.id}
                              onDateChange={handleDateChange}
                              startDate={filter.from ? new Date(filter.from).toISOString() : defaultRange.from}
                              endDate={filter.to ? new Date(filter.to).toISOString() : defaultRange.to}/>
        </Grid>


        <Grid item xs={3}>
            Benutzer
        </Grid>

        <Grid item xs={9}>
            <UserSelect onChange={handleUserChange} value={filter.userIds}/>
        </Grid>

        <Grid item xs={12}>
            <Button onClick={handleExport}
                    disabled={!filter.userIds || filter.userIds.length === 0}
                    className={classes.exportButton}
                    variant="contained"
                    color="primary"
                    fullWidth>
                <Translate id="trip.exportmodal.export"/>
            </Button>
        </Grid>
    </Grid>

    return <EditModal onClose={onClose}
                      title={<Translate id="trip.exportmodal.title"/>}
                      contents={[content]}/>;
};

// TimeLogExportModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     graphicReport: PropTypes.bool,
// };

export default TimeLogExportModal;