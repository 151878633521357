import React, { Profiler, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";

import { menu } from "../../managers/pathManager";
import { Breadcrumbs, Paper, Button } from "@material-ui/core";
import Page from "../common/container/Page";
import Toolbar from "../common/toolbar/ToolbarAdmin";
import { Link } from "react-router-dom";
import ConfirmModal from "../common/modals/ConfirmModal";
import AddNodeModal from "./groupModals/AddNodeModal";
import GroupModal from "./groupModals/GroupModal";

import UserModal from "../users/userModals/UserModal";
import VehicleModal from "../vehicles/vehicleModals/VehicleModal";
import {
  receiveGroupById,
  receiveGroups,
  requestDeleteGroup,
  requestGroupById,
  requestGroups,
  requestTree,
} from "../../actions/actionGroups";
import { requestUserById, requestUpdateUser } from "../../actions/actionUser";
import {
  requestVehicleById,
  requestUpdateVehicle,
} from "../../actions/actionVehicle";

import { clearError } from "../../actions/actionCommon";
import DropDownArrowIcon from "@material-ui/icons/ArrowDropDown";
import GroupTable from "./GroupTable";
import { useModalStatus, useContextMenu } from "../../hooks/useModalStatus";
import {
  allowedRoles,
  isAuthorized,
  isViewer,
} from "../../managers/authManager";
import { roles } from "../../models/Role";
import { packages } from "../../models/Package";

import RenameModal from "./groupModals/RenameModal";
import { types } from "../../models/GroupTypes";
import TableContextMenu from "./contextMenues/TableContextMenu";
import MoveToContextMenu from "./contextMenues/MoveToContextMenu";
import AddContextMenu from "./contextMenues/AddContextMenu";
import AddUserModal from "./groupModals/AddUserModal";
import AddVehicleModal from "./groupModals/AddVehicleModal";
import { Translate } from "react-localize-redux";
import FontAwesomeIcon from "../common/views/FontAwesomeIcon";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Tooltip } from "antd";

const useStyles = makeStyles((theme) => ({
  breadcrumbItem: {
    color: "hsl(206, 6%, 25%)",
    fontSize: "15px",
    gap: "normal",
    lineHeight: "24px",
    margin: "2px 0px",
    padding: "4px 8px",
    borderRadius: "8px",
    fontWeight: "450",
    textTransform: "none",
  },
  breadcrumb: {
    padding: "10px 10px",
    // margin: " 0px 0px 8px 0px",
    marginTop: "65px",
    //margin: "75px 5px"
    marginBottom: "5px",
  },
  currentGroup: {
    display: "flex",
    // margin: "5px 5px",
  },
  root: {
    flexGrow: 1,
    // margin: "-110px 5px -12.5px 5px", // 60
    margin: "-110px 5px -2.5px 5px",
    borderRadius: "5px",
    //maxWidth: 500,
  },
  tabs: {
    margin: "auto",
    width: "50%",
    textAlign: "center",
  },
}));

const GroupPage = (props) => {
  const dispatch = useDispatch();

  const [currentRow, setCurrentRow] = useState({});
  const [isInMainNode, setIsInMainNode] = useState(true);
  const [ancestorsNameId, setAncestorsNameId] = useState([]);
  const [shouldVehicleModalOpen, setShouldVehicleModalOpen] = useState(false);

  const {
    anchorEl: breadcrumbMenu,
    open: openBreadCrumbMenu,
    close: closeBreadCrumbMenu,
  } = useContextMenu();
  const {
    anchorEl: itemMenu,
    open: openItemMenu,
    close: closeItemMenu,
  } = useContextMenu();
  const {
    anchorEl: addMenu,
    open: openAddMenu,
    close: closeAddMenu,
  } = useContextMenu();
  const {
    anchorEl: moveToMenu,
    open: openMoveToMenu,
    close: closeMoveToMenu,
  } = useContextMenu();

  const {
    modal: addModal,
    open: openAddModal,
    close: closeAddModal,
  } = useModalStatus();
  const {
    modal: addUserModal,
    open: openAddUserModal,
    close: closeAddUserModal,
  } = useModalStatus();
  const {
    modal: addVehicleModal,
    open: openAddVehicleModal,
    close: closeAddVehicleModal,
  } = useModalStatus();

  const {
    modal: groupModal,
    open: openGroupModal,
    close: closeGroupModal,
  } = useModalStatus();
  const {
    modal: userModal,
    open: openUserModal,
    close: closeUserModal,
  } = useModalStatus();
  const {
    modal: vehicleModal,
    open: openVehicleModal,
    close: closeVehicleModal,
  } = useModalStatus();
  const {
    modal: removeModal,
    open: openRemoveModal,
    close: closeRemoveModal,
  } = useModalStatus();
  const {
    modal: renameModal,
    open: openRenameModal,
    close: closeRenameModal,
  } = useModalStatus();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();

  const { group, tree, loading, me, vehicle, user } = props;
  const viewer = isViewer(me);

  useEffect(() => {
    vehicle?.id && shouldVehicleModalOpen && openVehicleModal();

    return () => {
      setShouldVehicleModalOpen(false);
    };
  }, [vehicle]);

  useEffect(() => {
    props.requestGroups();
    props.requestTree();
  }, []);

  useEffect(() => {
    switch (currentRow.type) {
      case types.node:
        //do nothing //do not load id of group -> it will be a redirect to another page ;)
        break;
      case types.user:
        dispatch(requestUserById(currentRow.id));
        break;
      case types.vehicle:
        dispatch(requestVehicleById(currentRow.id));
        break;
    }
  }, [currentRow]);

  function handleRemoveNode() {
    closeRemoveModal();
    switch (currentRow.type) {
      case types.node:
        props.requestDeleteGroup(currentRow);
        break;
      case types.vehicle:
        props.requestUpdateVehicle({
          ...vehicle,
          groupIds: vehicle.groups
            .filter((g) => g.id !== group.id)
            .map((g) => g.id),
          driveboxId: vehicle.drivebox ? vehicle.drivebox.id : "",
          ownerId: vehicle.owner ? vehicle.owner.id : "",
        });
        break;
      case types.user:
        props.requestUpdateUser({
          ...user,
          roleId: user.role.id,
          groupIds: user.groups
            .filter((g) => g.id !== group.id)
            .map((g) => g.id),
        });
        break;
    }
  }

  function handleNavigateToMainNode() {
    if (!isInMainNode) {
      setAncestorsNameId([]);
      props.clearError();
      // remove data of a children when going back to main node
      dispatch(receiveGroups({ children: [], loading: true }));
      props.requestGroups();
      dispatch(receiveGroupById({}));
      setIsInMainNode(true);
    }
  }

  function handleNavigateToNode(nodeId = "") {
    let currentAncestors = ancestorsNameId.slice();
    console.log("currentAncesstor", currentAncestors);
    let indexOfCurrentNode = ancestorsNameId.findIndex((el) => el.id == nodeId);
    if (indexOfCurrentNode != -1) {
      currentAncestors.splice(indexOfCurrentNode + 1);
      setAncestorsNameId(currentAncestors);
    }
    setIsInMainNode(false);
    dispatch(receiveGroupById({}));
    dispatch(receiveGroups({ children: [], loading: true }));
    props.clearError();
    props.requestGroups(nodeId);
    props.requestGroupById(nodeId);
  }

  const onHandleRename = () => {
    if (currentRow.type === types.user) {
      dispatch(requestUserById(currentRow.id));
    }

    openRenameModal();
  };

  function handleFastActionByType(d) {
    const { name, type, id } = d;

    closeItemMenu();

    if (type === types.node) {
      let currentAncestors = ancestorsNameId.slice();
      currentAncestors.push({
        name,
        id,
      });

      setAncestorsNameId(currentAncestors);
      handleNavigateToNode(id);
    }

    if (type === types.user && !viewer) {
      dispatch(requestUserById(id));
      console.log("userId", id);
      openUserModal();
    }

    if (type === types.vehicle && !viewer) {
      setShouldVehicleModalOpen(true);
      console.log("vehicleid", id);
      dispatch(requestVehicleById(id));
    }
  }

  function handleContextMenuOpen(event, currentRow) {
    setCurrentRow(currentRow);
    openItemMenu(event.currentTarget);
  }

  const actionButtons = [];

  if (isAuthorized(me, allowedRoles(roles.GroupLeader))) {
    actionButtons.push({
      icon: <FontAwesomeIcon>far fa-plus</FontAwesomeIcon>,
      translateKey: "group.page.add",
      onClick: (e) => openAddMenu(e.currentTarget),
    });
    actionButtons.push({
      icon: <FontAwesomeIcon>far fa-pen</FontAwesomeIcon>,
      hide: !group.id,
      translateKey: "group.page.edit",
      onClick: openGroupModal,
    });
  }

  const toolbar = <Toolbar actionButtons={actionButtons} />;
  const noEntryFound = tree.length === 0 && false; //disabled

  const costCenterInformation = isAuthorized(me, allowedRoles(roles.Admin), [
    packages.CostCenter,
  ]) ? (
    <Tab
      aria-label="donate"
      component={Link}
      to={"/costcenters"}
      label="Kostenstellenverwaltung"
    />
  ) : (
    <Tooltip title="Das Modul Kostenstellen ist nur in DRiVEBOX BASIC+ und DRiVEBOX PRO verfügbar!">
      <div className={classes.tabs}>
        <Tab
          aria-label="donate"
          component={Link}
          to={"/costcenters"}
          label="Kostenstellenverwaltung"
          disabled
        />
      </div>
    </Tooltip>
  );
  return (
    <Page
      toolbar={toolbar}
      activePage={menu.costcenters.key}
      loading={loading}
      noEntryFound={noEntryFound}
      onAdd={openAddModal}
      disableTesterToolbar
    >
      <Paper square className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange} //<Tooltip title={groups} placement="right">   </Tooltip> disabled
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon tabs example"
        >
          <Tab
            aria-label="foldertree"
            component={Link}
            to={"/groups"}
            label="Gruppenverwaltung"
          />
          {costCenterInformation}
        </Tabs>
      </Paper>
      <Paper>
        <Breadcrumbs aria-label="Breadcrumb" className={classes.breadcrumb}>
          <Button
            onClick={(e) => handleNavigateToMainNode()}
            variant="text"
            className={classes.breadcrumbItem}
          >
            Gruppenverwaltung
          </Button>
          {ancestorsNameId &&
            ancestorsNameId.slice(0, -1).map((ancestor) => (
              <Button
                onClick={(e) => handleNavigateToNode(ancestor.id)}
                variant="text"
                key={ancestor.id}
                className={classes.breadcrumbItem}
              >
                {ancestor.name}
              </Button>
            ))}
          {group.name && !viewer && (
            <div className={classes.currentGroup}>
              <Button
                variant="text"
                className={classes.breadcrumbItem}
                endIcon={<DropDownArrowIcon />}
                onClick={(e) => {
                  console.log("group", group);
                  setCurrentRow({ ...group, type: types.node });
                  openBreadCrumbMenu(e.currentTarget);
                }}
              >
                {group.name}
              </Button>
            </div>
          )}
        </Breadcrumbs>
      </Paper>

      <Paper>
        <GroupTable
          onClick={handleFastActionByType}
          onContextMenuOpen={handleContextMenuOpen}
        />
      </Paper>

      {addModal && <AddNodeModal onClose={closeAddModal} />}
      {addUserModal && <AddUserModal onClose={closeAddUserModal} />}
      {addVehicleModal && <AddVehicleModal onClose={closeAddVehicleModal} />}
      {userModal && (
        <UserModal tree={tree} user={user} onClose={closeUserModal} />
      )}
      {vehicleModal && (
        <VehicleModal
          tree={tree}
          vehicle={vehicle}
          onClose={closeVehicleModal}
        />
      )}
      {groupModal && <GroupModal onClose={closeGroupModal} />}
      {renameModal && (
        <RenameModal
          currentRow={currentRow}
          user={user}
          vehicle={vehicle}
          group={currentRow.type === types.node ? currentRow : group}
          onClose={closeRenameModal}
        />
      )}

      {removeModal && (
        <ConfirmModal
          open={removeModal}
          title={
            <Translate
              id="group.removeModal.title"
              data={{
                name: currentRow.name,
              }}
            />
          }
          text={
            <Translate
              id="group.removeModal.information"
              data={{
                name: currentRow.name,
              }}
            />
          }
          onConfirm={handleRemoveNode}
          onClose={closeRemoveModal}
        />
      )}

      <TableContextMenu
        anchorEl={itemMenu}
        onOpen={() => handleFastActionByType(currentRow)}
        onRename={onHandleRename}
        onMove={(e) => openMoveToMenu(itemMenu)}
        onRemove={openRemoveModal}
        onClose={closeItemMenu}
        disableOpen={viewer && currentRow.type !== types.node}
      />

      <TableContextMenu
        anchorEl={breadcrumbMenu}
        onRename={onHandleRename}
        onMove={(e) => openMoveToMenu(breadcrumbMenu)}
        onRemove={openRemoveModal}
        onClose={closeBreadCrumbMenu}
      />

      <MoveToContextMenu
        anchorEl={moveToMenu}
        id={currentRow.id}
        type={currentRow.type}
        onClose={closeMoveToMenu}
      />

      <AddContextMenu
        anchorEl={addMenu}
        onClose={closeAddMenu}
        onAddGroup={() => {
          closeAddMenu();
          openAddModal();
        }}
        onAddCar={() => {
          closeAddMenu();
          openAddVehicleModal();
        }}
        onAddUser={() => {
          closeAddMenu();
          openAddUserModal();
        }}
        isInMainNode={isInMainNode}
      />
    </Page>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestGroups,
      requestGroupById,
      requestDeleteGroup,
      requestTree,
      clearError,
      requestUpdateVehicle,
      requestUpdateUser,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    user: state.users.current,
    groups: state.groups,
    group: state.group,
    tree: state.tree,
    loading: state.group.loading,
    me: state.users.me,
    vehicle: state.vehicles.current,
    user: state.users.current,
  };
}

// GroupPage.propTypes = {};

export default connect(mapStateToProp, mapDispatchToProps)(GroupPage);
