import React, {useEffect, useState} from "react";
import {Redirect} from "react-router-dom";
import qs from "query-string";
import {withLocalize} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom'

import {menu} from "../../managers/pathManager";
import {requestResetPassword} from "../../actions/actionUser";
import {setError} from "../../actions/actionCommon";
import {requestLogout, receiveLogout} from "../../actions/actionAuthentication";
import {Button, CssBaseline, FormControl, Input, InputLabel, Paper, Typography} from "@material-ui/core";
import ErrorToolbar from "../common/toolbar/ErrorToolbar";
import DriveboxLogo from "../../images/DriveboxLogo.svg";
import useLoginStyles from "../login/useLoginStyles";


function getCookie(name) {
    const dc = document.cookie;
    const prefix = name + "=";
    let begin = dc.indexOf("; " + prefix);
    if (begin === -1) {
        begin = dc.indexOf(prefix);
        if (begin !== 0) return null;
    } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end === -1) {
            end = dc.length;
        }
    }
    // because unescape has been deprecated, replaced with decodeURI
    //return unescape(dc.substring(begin + prefix.length, end));
    return decodeURI(dc.substring(begin + prefix.length, end));
}

const ResetPage = props => {
    const token = qs.parse(props.location.search, {ignoreQueryPrefix: true}).Token;
    const userId = qs.parse(props.location.search, {ignoreQueryPrefix: true}).UserId;

    const [password, setPassword] = useState("");
    const [repeatePassword, setRepeatePassword] = useState("");
    const [tokenExpired, setTokenExpired] = useState(false);

    const classes = useLoginStyles(props);
    const {me, error} = props;

    useEffect(() => {
        if (getCookie("Identity")) props.requestLogout();
        else props.receiveLogout();

     }, []);


    useEffect(() => {
        if (error.title && error.status === 404 && !error.message) {
            updateError("Link ist leider abgelaufen. Bitte kontaktieren Sie ihren Administrator");
            setTokenExpired(true);
        }
    }, [error]);


    function updateError(msg) {
        props.setError({
            message: msg,
        });
    }


    function resetPassword() {
        if (password !== repeatePassword) {
            updateError("Passwörter stimmen nicht überein");
            return;
        }

        if (password.length < 6) {
            updateError("Passwort braucht mindestens 6 Stellen");
            return;
        }

        props.requestResetPassword({userId: userId, passwordResetToken: token, newPassword: password});
    }

    function resetPasswortOnEnter(event) {
        if (event.keyCode === 13) {
            resetPassword();  //ENTER
        }
    }

    if (!token || me.loggedIn) {
        return <Redirect to={menu.login.path}/>;
    }

    return (
        <main className={classes.main}>
            <CssBaseline/>
            <div className={classes.paperContainer}>
                <img styles={{maxWidth: "90%", height: "30px"}}
                     className={classes.logo}
                     src={DriveboxLogo}
                     alt="Drivebox"/>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Bitte setzen sie ihr neues Passwort
                    </Typography>

                    <Typography component="h2" variant="body1">
                        (6 zeichen)
                    </Typography>

                    <form className={classes.form}>
                        <FormControl margin="normal"
                                     required
                                     fullWidth>
                            <InputLabel htmlFor="password">Passwort</InputLabel>
                            <Input
                                value={password}
                                name="password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                disabled={tokenExpired}
                                onChange={e => setPassword(e.target.value)}
                                onKeyDown={resetPasswortOnEnter}
                            />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password2">Wiederholtes Passwort</InputLabel>
                            <Input
                                value={repeatePassword}
                                name="password2"
                                type="password"
                                id="password2"
                                autoComplete="current-password"
                                disabled={tokenExpired}
                                onChange={e => setRepeatePassword(e.target.value)}
                                onKeyDown={resetPasswortOnEnter}

                            />
                        </FormControl>
                    </form>
                    <Button fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={tokenExpired}
                            onClick={resetPassword}>
                        Passwort ändern
                    </Button>

                    <ErrorToolbar className={classes.error}/>
                </Paper>
            </div>
        </main>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({requestResetPassword, setError, requestLogout, receiveLogout}, dispatch);
}

function mapStateToProp(state) {
    return {error: state.error, me: state.users.me};
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(withLocalize(withRouter(ResetPage)));
