import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Switch } from "@material-ui/core";
import ReportCard from "../common/views/ReportCard";
import { Translate } from "react-localize-redux";
import Icon from "@material-ui/core/Icon";
import { secondsToString } from "../../managers/timeManager";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import { useSelector } from "react-redux";
import FontAwesomeIcon from "../common/views/FontAwesomeIcon";

const useStyles = makeStyles({
  toolbar: {
    margin: "0 3px 4px 3px",
  },
  card: {
    marginTop: "2px",
    marginBottom: "2px",
  },
});

const DayViewSummaryReports = (props) => {
  const classes = useStyles(props);

  const trip = useSelector((state) => state.reports.trip);
  const idleTime = useSelector((state) => state.reports.idleTime);
  const vehicle = useSelector((state) => state.vehicles.current);

  const {
    mileageInMeters,
    businessTripCount,
    privateTripCount,
    driveToWorkTripCount,
    timeInSeconds,
    driverCount,
    vehicleCount,
  } = trip;
  const tripCount = businessTripCount + privateTripCount + driveToWorkTripCount;

  return (
    <Grid container className={classes.toolbar} spacing={0}>
      <Grid item xs={12} sm={4} md={2} className={classes.card}>
        <ReportCard
          condensed
          title={<Translate id="trip.cards.vehicle" />}
          loading={typeof vehicle.id == "undefined"}
          value={vehicle.name}
          icon={
            <FontAwesomeIcon fontSize={30} color="secondary">
              far fa-car
            </FontAwesomeIcon>
          }
        />
      </Grid>

      <Grid item xs={12} sm={4} md={2} className={classes.card}>
        <ReportCard
          condensed
          title={<Translate id="trip.cards.trips" />}
          loading={typeof businessTripCount == "undefined"}
          value={tripCount}
          icon={
            <FontAwesomeIcon fontSize={30} color="secondary">
              far fa-book
            </FontAwesomeIcon>
          }
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2} className={classes.card}>
        <ReportCard
          condensed
          title={<Translate id="trip.cards.mileage" />}
          loading={typeof mileageInMeters == "undefined"}
          value={Math.round(mileageInMeters / 1000)}
          icon={
            <FontAwesomeIcon fontSize={30} minWidth={34} color="secondary">
              fal fa-road
            </FontAwesomeIcon>
          }
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2} className={classes.card}>
        <ReportCard
          condensed
          title={<Translate id="trip.cards.drivingTime" />}
          loading={typeof mileageInMeters == "undefined"}
          value={secondsToString(timeInSeconds) || 0}
          icon={
            <FontAwesomeIcon fontSize={30} color="secondary">
              far fa-stopwatch
            </FontAwesomeIcon>
          }
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2} className={classes.card}>
        <ReportCard
          condensed
          title={<Translate id="trip.cards.idleTime" />}
          loading={typeof idleTime == "undefined"}
          value={secondsToString(idleTime * 60) || 0}
          icon={
            <FontAwesomeIcon fontSize={30} color="secondary">
              far fa-hourglass
            </FontAwesomeIcon>
          }
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2} className={classes.card}>
        <ReportCard
          condensed
          title={<Translate id="trip.cards.driver" />}
          loading={typeof driverCount == "undefined"}
          value={driverCount || 0}
          icon={
            <FontAwesomeIcon fontSize={30} color="secondary">
              far fa-user
            </FontAwesomeIcon>
          }
        />
      </Grid>
    </Grid>
  );
};

// DayViewSummaryReports.propTypes = {};

export default DayViewSummaryReports;
