import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { requestVehicleInfoById } from "../../actions/actionDriverIdentification";

const DriverIdentificationPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestVehicleInfoById("4372894723"));
  }, []);

  return (
    <>
      <h1>Driver Identification page</h1>
      <main><h2>Content goes here</h2></main>
    </>
  );
};

export default DriverIdentificationPage;
