import React from "react";
import GenericTable from "../common/table/GenericTable";
import PropTypes from "prop-types";
import TableTypes from "../../models/TableTypes";
import Comparator from "../../models/Comparator";
import {useSelector} from "react-redux";
import {getReportInformationById} from "../../managers/reportManager";
import {Translate} from "react-localize-redux";
import {mapSendToAllServerEnumToPersonGroup, possiblePersonGroup} from "../../models/PossiblePersonGroup";

const ReportAgenda = props => {
    const {page, rowsPerPage, onPageChange, onRowsPerPageChange, onEdit, onDelete} = props;

    const reports = useSelector(state => state.scheduledReports.entities);
    const totalReports = useSelector(state => state.scheduledReports.total);

    // color: {value: "geekblue", comparator: new Comparator(["g"], (g) => g === "ep")},
    const columnInformations = [
        {title: "Name", key: "name"},
        {title: "Datum", key: "nextOccurrenceAt", type: TableTypes.dateTime},
        {title: "Berichtsart", key: "typeName"},
        {title: "Senden an", key: "sendTo", type: TableTypes.group},
        {title: "Zusätzliche Personen", key: "additionalUsers", type: TableTypes.group},
        {title: "Ausgenommene Personen", key: "doNotSendToUsers", type: TableTypes.group}
    ];

    const mappedReports = reports.map(r => {
        r.typeName = <Translate id={getReportInformationById(r.type).translationKey}/>;
        let personGroup = mapSendToAllServerEnumToPersonGroup(r.sendToAll)
        if (personGroup) {
            r.additionalUsers = r.sentToUsers
            switch (personGroup) {
                case possiblePersonGroup.driver:
                    r.sendTo = [{name: "An alle Fahrer"}]
                    break;
                case possiblePersonGroup.groupLeader:
                    r.sendTo = [{name: "An alle Gruppenleiter"}]
                    break;
                case possiblePersonGroup.admins:
                    r.sendTo = [{name: "An alle Administratoren"}]
                    break;
            }
        } else {
            if (r.sendToGroups && r.sendToGroups.length > 0) {
                r.sendTo = r.sendToGroups;
                r.additionalUsers = r.sentToUsers
            } else if (r.sentToUsers && r.sentToUsers.length > 0) {
                r.sendTo = r.sentToUsers;
            }
        }


        return r;
    })

    const actions = [
        {title: "Bearbeiten", event: onEdit},
        {title: "Löschen", event: onDelete},
    ];

    return <GenericTable columnInformations={columnInformations} data={mappedReports}
                         rowCount={totalReports}
                         page={page}
                         onRowClick={onEdit}
                         showActions
                         actions={actions}
                         rowsPerPage={rowsPerPage}
                         handleChangePage={onPageChange}
                         handleChangeRowsPerPage={onRowsPerPageChange}
                         pageNavigation/>

};

// ReportAgenda.propTypes = {
//     onEdit: PropTypes.func.isRequired,
//     onDelete: PropTypes.func.isRequired,
//     page: PropTypes.number,
//     rowsPerPage: PropTypes.number.isRequired,
//     onPageChange: PropTypes.func.isRequired,
//     onRowsPerPageChange: PropTypes.func.isRequired,
// };

export default ReportAgenda;