import React from "react";
import {Translate} from "react-localize-redux";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import SearchField from "./ToolbarSearch";
import className from "classnames"

import {Button, ButtonGroup, Card, IconButton, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import FilterButton from "../buttons/FilterButton";

import RemoveIcon from "@material-ui/icons/RemoveCircle";
import FontAwesomeIcon from "../views/FontAwesomeIcon";

const useStyles = makeStyles(theme => ({
        toolbar: {
            paddingRight: theme.spacing(1),
            marginTop: "105px", //50
            marginBottom: "4px",
            padding: "10px 20px",
            backgroundColor: "white",
            display: "flex",
            alignItems: "left",
            justifyContent: "flex-start",
            "flex-wrap": "wrap",
        },
        divider: {
            borderRight: "1px solid rgba(0, 0, 0, 0.54) ",
        },
        togglesContainer: {
            marginRight: "1rem"
        },
        toggleIcon: {
            margin: "2px 4px",
        },
        icon: {
            fontSize: "small",
        },
        buttonIcon: {},
        leftIcon: {
            marginRight: "2px",
        },
        space: {
            flexGrow: "1",
        },
        activeIcon: {
            color: theme.palette.primary.main,
        },
        title: {
            fontSize: 20,
            marginRight: "1rem",
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        clearIcon: {
            marginLeft: "1px",
        },
        controls: {
            width: "auto",
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
            "border-color": "black",
            marginTop: "4px",
            marginLeft: "2px",
            color: "rgba(0, 0, 0, 0.54)",
        },
        filterButton: {
            marginTop: "3px"
        },
        button: {
            width: "auto",
            [theme.breakpoints.down('xs')]:
                {
                    width: '100%',
                }
            ,
        },
        toolbarend: {
            display: "flex"
        }
    }))
;

const CustomToolbar = props => {
    const {actionButtons, bulkActionButtons, toggles, onSearch, onFilter, onSetting, numberSelected, title, onDeselect, controls} = props;
    const classes = useStyles();

    const toolbarContent = (
        <Translate>
            {({translate}) =>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                    <div className={classes.togglesContainer}>
                        <ButtonGroup size="small" variant="text">
                            {toggles.map((toggle, i) => (
                                <Tooltip title={translate(toggle.translateKey)}>
                                    <Button
                                        color={toggle.active ? "primary" : "default"}
                                        key={"toggleBtn#" + i + "#" + toggle.key}
                                        onClick={toggle.onClick}>
                                        {toggle.icon}
                                    </Button>
                                </Tooltip>
                            ))}
                        </ButtonGroup>
                    </div>
                    <div>
                        <Typography className={className({[classes.title]: title})}>{title}</Typography>
                    </div>
                    <div>
                        {actionButtons.map((button, i) => {
                            if (!button.hide)
                                return (
                                    <Button style={{marginRight: "3px"}}
                                            variant="outlined"
                                            color="secondary"
                                            key={"actionBtn#" + i + "#" + button.key}
                                            className={classes.button}
                                            onClick={button.onClick}
                                            startIcon={button.icon}>
                                        {translate(button.translateKey)}
                                    </Button>
                                );
                        })}
                    </div>
                    <div> {onSearch && <SearchField onSearch={onSearch}/>}</div>
                    <div className={classes.space}/>
                    <div className={classes.toolbarend}>
                        {controls && controls.length > 0 && controls.map((c, idx) => <div
                            key={`controls-${idx}`} className={classes.controls}>{c}</div>)}
                        {onFilter && <div className={classes.filterButton}><FilterButton onClick={onFilter}/></div>}
                        {onSetting && (
                            <div>
                                <Tooltip title="Setting">
                                    <IconButton onClick={onSetting}>
                                        <FontAwesomeIcon marginTop={-2} fontSize={17}>far fa-cog</FontAwesomeIcon>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                </Grid>

            }
        </Translate>
    );

    // TODO translations
    const bulkEditContent = (
        <Translate>
            {({translate}) =>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Grid item>
                        {bulkActionButtons.map((button, idx) => {
                            if (!button.min || numberSelected >= button.min)
                                return (
                                    <Button color="secondary"
                                            key={`bulk-edit-${button.key}`}
                                            style={{marginRight: "3px"}}
                                            variant="outlined"
                                            className={classes.button}
                                            onClick={button.onClick}
                                            startIcon={button.icon}>
                                        {translate(button.translateKey)}
                                    </Button>
                                );
                        })}
                    </Grid>
                    <Grid className={classes.space}/>
                    <Grid item>{numberSelected} <Translate id="common.toolbar.selected"/> </Grid>
                    {onDeselect && <Grid item>
                        <Tooltip title={"Auswahl aufheben"}>
                            <IconButton size="small"
                                        aria-label="Deselect"
                                        className={classes.clearIcon}
                                        onClick={onDeselect}>
                                <RemoveIcon fontSize="small"/>
                            </IconButton>
                        </Tooltip>
                    </Grid>}
                </Grid>
            }
        </Translate>
    );


    return (
        <Card className={classes.toolbar}>
            {numberSelected == 0 && toolbarContent}
            {numberSelected != 0 && bulkEditContent}
        </Card>
    );
};


CustomToolbar.defaultProps = {toggles: [], actionButtons: [], bulkActionButtons: [], numberSelected: 0};

// CustomToolbar.propTypes = {
//     title: PropTypes.string,
//     onSearch: PropTypes.func,
//     onFilter: PropTypes.func,
//     onSetting: PropTypes.func,
//     onDeselect: PropTypes.func,
//     actionButtons: PropTypes.array,
//     bulkActionButtons: PropTypes.array,
//     toggles: PropTypes.array,
//     numberSelected: PropTypes.number,
//     controls: PropTypes.array,
// };

export default CustomToolbar;
