import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form } from "antd";

import WizardModal, {
  WIZARD_TYPES,
} from "../../common/modals/wizard-modal/WizardModal";

import DatePicker from "../../common/selects/datePickers/CustomDateTimePicker";

import VehicleSelect from "../../common/selects/specificSelects/VehicleSelect";
import UserSelect from "../../common/selects/specificSelects/UserSelect";
import TripTypeSelect from "../../common/selects/specificSelects/TripTypeSelect";
import AddressSelect from "../../common/selects/specificSelects/AddressSelect";

import { requestAddTrip } from "../../../actions/actionTrips";
import { clearGeoCode } from "../../../actions/actionGeoLocation";
import FormBuilder from "../../../managers/formBuilder";
import { Input } from "antd";
import Label from "../../common/views/Label";
import { allowedRoles, isAuthorized } from "../../../managers/authManager";
import { roles } from "../../../models/Role";
import { packages } from "../../../models/Package";
import { useWizardSteps } from "../../../hooks/useModalStatus";
import { Translate } from "react-localize-redux";
import CostCenterSelect from "../../common/selects/specificSelects/CostCenterSelect";
import { useValidateForm } from "../../../hooks/useValidateForm";
import { useCustomValidation } from "../../../hooks/useDatePickersValidation";
import { validateInputs } from "../../common/inputs/inputs.utils";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";

const DEFAULT_INPUT_VALUES = {
  startedAt: null,
  finishedAt: null,
  startAddress: null,
  destinationAddress: null,
  vehicleId: null,
  driverId: null,
  costcenterId: null,
  type: null,
  mileageInKm: null,
  description: null,
};

const TripAddModal = (props) => {
  const [trip, setTrip] = useState({ startedAt: null, finishedAt: null });
  const { step, nextStep, prevStep } = useWizardSteps();
  const { me, onClose } = props;
  const [form] = Form.useForm();
  const { submittable } = useValidateForm(form);
  const { handleInputChange, fields } = useInputChangeHandler(DEFAULT_INPUT_VALUES);
  const { customRule, setIsValidated, isValidated } = useCustomValidation();

  useEffect(() => {
    fields.finishedAt && new Date(fields.startedAt) > new Date(fields.finishedAt)
      ? setIsValidated(false)
      : setIsValidated(true);
  }, [fields.startedAt, fields.finishedAt]);

  useEffect(() => {
    if (step === 3) {
      props.requestAddTrip({
        ...trip,
        mileageInMeters: trip.mileageInKm * 1000,
        destinationLatitude: trip.destinationAddress.latitude,
        destinationLongitude: trip.destinationAddress.longitude,
        startLatitude: trip.startAddress.latitude,
        startLongitude: trip.startAddress.longitude,
        driverId: trip.driverId ? trip.driverId : me.id,
      });
      onClose();
    }
  }, [step]);

  function handleNext(values) {
    props.clearGeoCode();
    setTrip({ ...trip, ...values });
    nextStep();
  }

  function canBack() {
    return true;
  }

  const page1 = {
    title: <Translate id="trip.addmodal.page1.title" />,
    inputs: [
      new FormBuilder.FormInput(
        <Translate id="trip.addmodal.page1.startTime" />,
        (
          <DatePicker
            onChange={(value) => handleInputChange("startedAt", value)}
            value={fields.startedAt}
            disableFuture
          />
        )
      )
        .withValue(fields.startedAt)
        .withKey("startedAt")
        .withType("object")
        .withValidationMessage(
          <Translate id="trip.addmodal.page1.startTimeValidation" />
        )
        .isRequired()
        .build(),
      new FormBuilder.FormInput(
        <Translate id="trip.addmodal.page1.startAddress" />,
        (
          <AddressSelect
            onChange={(value) => handleInputChange("startAddress", value)}
          />
        )
      )
        .withValue(fields.startAddress)
        .withKey("startAddress")
        .withType("object")
        .withValidationMessage(
          <Translate id="trip.addmodal.page1.startAddressValidation" />
        )
        .isRequired()
        .build(),
      new FormBuilder.FormInput(
        <Translate id="trip.addmodal.page2.finishTime" />,
        (
          <DatePicker
            onChange={(value) => handleInputChange("finishedAt", value)}
            value={fields.finishedAt}
            disableFuture
          />
        )
      )
        .withValue(fields.finishedAt)
        .withKey("finishedAt")
        .withCustomRule(customRule)
        .withValidationMessage(
          <Translate id="trip.addmodal.page2.finishTimeValidation" />
        )
        .isRequired()
        .build(),
      new FormBuilder.FormInput(
        <Translate id="trip.addmodal.page2.destinationAddress" />,
        (
          <AddressSelect
            onChange={(value) => handleInputChange("destinationAddress", value)}
          />
        )
      )
        .withValue(fields.destinationAddress)
        .withKey("destinationAddress")
        .withType("object")
        .withValidationMessage(
          <Translate id="trip.addmodal.page2.destinationAddressValidation" />
        )
        .isRequired()
        .build(),
    ],
  };

  /* Page 2 is removes - and merged into page 1*/
  const page3 = {
    title: <Translate id="trip.addmodal.page3.title" />,
    inputs: [
      new FormBuilder.FormInput(
        <Translate id="trip.addmodal.page3.vehicle" />,
        (
          <VehicleSelect
            value={fields.vehicleId}
            onChange={(value) => handleInputChange("vehicleId", value)}
          />
        )
      )
        .withValue(fields.vehicleId)
        .withKey("vehicleId")
        .withValidationMessage(
          <Translate id="trip.addmodal.page3.vehicleValidation" />
        )
        .isRequired()
        .build(),
      new FormBuilder.FormInput(
        <Translate id="trip.addmodal.page3.driver" />,
        (
          <UserSelect
            driverId={fields.driverId}
            onChange={(value) => handleInputChange("driverId", value)}
          />
        )
      )
        .withValue(fields.driverId)
        .withKey("driverId")
        .withValidationMessage(
          <Translate id="trip.addmodal.page3.driverValidation" />
        )
        .isRequired()
        .build(),
    ],
  };

  if (isAuthorized(me, allowedRoles(roles.Admin), [packages.CostCenter])) {
    page3.inputs.push(
      new FormBuilder.FormInput(
        <Translate id="trip.addmodal.page3.costcenter" />,
        (
          <CostCenterSelect
            value={fields.costcenterId}
            showArchived={false}
            onChange={(value) => handleInputChange("costcenterId", value)}
          />
        )
      )
        .withValue(fields.costcenterId)
        .withKey("costcenterId")
        .build()
    );
  }

  const page3WithoutDriver = {
    title: <Translate id="trip.addmodal.page3.titleWithoutDriver" />,
    inputs: [
      new FormBuilder.FormInput(
        <Translate id="trip.addmodal.page3.vehicle" />,
        <VehicleSelect value={fields.vehicleId} onChange={(value) => handleInputChange("vehicleId", value)} />
      )
        .withValue(fields.vehicleId)
        .withKey("vehicleId")
        .withValidationMessage(
          <Translate id="trip.addmodal.page3.vehicleValidation" />
        )
        .isRequired()
        .build(),
      new FormBuilder.FormInput(
        <Translate id="trip.addmodal.page3.driver" />,
        <Label />
      )
        .withValue(me.firstName + " " + me.lastName)
        .viewOnly()
        .build(),
    ],
  };
  const page4 = {
    title: <Translate id="trip.addmodal.page4.title" />,
    inputs: [
      new FormBuilder.FormInput(
        <Translate id="trip.addmodal.page4.type" />,
        <TripTypeSelect value={fields.type}  onChange={(value) => handleInputChange("type", value)} />
      )
        .withValue(fields.type)
        .withKey("type")
        .withValidationMessage(
          <Translate id="trip.addmodal.page4.typeValidation" />
        )
        .isRequired()
        .build(),
      new FormBuilder.FormInput(
        <Translate id="trip.addmodal.page4.mileage" />,
        <Input suffix="km" value={fields.mileageInKm} onChange={(e) => handleInputChange("mileageInKm", e.target.value)} />
      )
        .withValue(fields.mileageInKm)
        .withKey("mileageInKm")
        .withValidationMessage(
          <Translate id="trip.addmodal.page4.mileageValidation" />
        )
        .isRequired()
        .build(),
      new FormBuilder.FormInput(
        <Translate id="trip.addmodal.page4.description" />,
        <Input.TextArea autosize={{ minRows: 3, maxRows: 5 }} value={fields.description} onChange={(e) => handleInputChange("description", e.target.value)} />
      )
        .withValue(fields.description)
        .withKey("description")
        .build(),
    ],
  };

  const pages = [page1, page3, page4];
  const pagesForDriver = [page1, page3WithoutDriver, page4];

  return (
    <WizardModal
      onClose={onClose}
      active
      canNext={
        !fields.startedAt ||
        !fields.finishedAt ||
        new Date(fields.startedAt) < new Date(fields.finishedAt)
      }
      canBack={canBack}
      submittable={submittable}
      onNext={handleNext}
      form={form}
      validateInputs={ !isValidated && validateInputs }
      customRuleMessage={<Translate id="trip.page.inputValidation.datePickersValidation" />}
      onBack={prevStep}
      title={<Translate id="trip.addmodal.title" />}
      pages={
        isAuthorized(me, allowedRoles(roles.GroupLeader))
          ? pages
          : pagesForDriver
      }
      step={step}
      wizardType={WIZARD_TYPES.trip.name}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ requestAddTrip, clearGeoCode }, dispatch);
}

function mapStateToProp(state) {
  return { me: state.users.me };
}

// TripAddModal.propTypes = {
//   onClose: PropTypes.func.isRequired,
// };

export default connect(mapStateToProp, mapDispatchToProps)(TripAddModal);
