import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { requestVehiclesLastPosition } from "../../actions/actionVehicle";
import moment from "moment";
import { Translate } from "react-localize-redux";
import TableTypes from "../../models/TableTypes";
import GenericTable from "../common/table/GenericTable";
import { usePagination } from "../../hooks/usePagination";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoadingIndicator from "../common/LoadingIndicator";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    paddingTop: "80px",
    paddingBottom: "15px",
    paddingLeft: "10px",
  },
}));

function NoDriveBoxDataTable() {
  const { page, rowsPerPage, changePage, changeRowPerPage } = usePagination();
  const classes = useStyles();

  const vehicles = useSelector(
    (state) => state.vehicles.entitiesWithLastPosition
  );
  const loading = useSelector(
      (state) => state.vehicles.loading
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestVehiclesLastPosition());
  }, [page, rowsPerPage]);

  const filteredValues = vehicles.filter((v) => {
    if (!v.lastPosition) return true; // No last position found

    let hours = moment().diff(moment(v.lastPosition.timestamp), "hours");
    return hours >= 24 * 7; // 7 days
  });

  const total = filteredValues.length;

  const pageData = filteredValues.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const columnInformations = [
    {
      title: <Translate id="vehicle.table.name" />,
      key: "name",
    },
    {
      title: <Translate id="vehicle.table.licensePlate" />,
      key: "licensePlate",
    },
    {
      title: <Translate id="vehicle.table.drivebox" />,
      key: "drivebox.name",
    },
    {
      title: <Translate id="vehicle.table.lastPosition" />,
      key: "lastPosition.timestamp",
      type: TableTypes.date,
    },
    {
      title: "",
      key: "lastPosition.timestamp",
      type: TableTypes.time,
    },
  ];

  if (loading)
    return (
      <>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Lade Driveboxen, die seit 7 Tagen nicht senden
        </Typography>
        <LoadingIndicator />
      </>
    );

  return (
    <Paper>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        Driveboxen, die seit 7 Tagen nicht senden
      </Typography>
      <GenericTable
        data={pageData}
        columnInformations={columnInformations}
        rowCount={total}
        pageNavigation
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={changePage}
        handleChangeRowsPerPage={changeRowPerPage}
      />
    </Paper>
  );
}

export default NoDriveBoxDataTable;
