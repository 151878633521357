import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {fromToByPeriod} from "../../managers/timeManager";
import {periods} from "../../models/Period";
import Toolbar from "../common/toolbar/Toolbar";
import {Grid} from "@material-ui/core";
import VehicleSelect from "../common/selects/specificSelects/VehicleSelect";
import {useDispatch, useSelector} from "react-redux";
import {updateFilterAPICall} from "../../actions/actionFilter";
import DayPicker from "../common/selects/datePickers/DayPicker";
import {Translate} from "react-localize-redux";
import moment from "moment";

const DayViewToolbar = props => {
    const dispatch = useDispatch();
    const vehicle = useSelector(state => state.vehicles.current);

    const currentDay = fromToByPeriod(periods.day.id, new Date());

    const [dateRange, setDateRange] = useState({
        period: periods.day.id,
        from: currentDay.from,
        to: currentDay.to,
    });
    const {from, to} = dateRange;

    useEffect(() => {
        dispatch(updateFilterAPICall({
            from: from.toISOString(),
            to: to.toISOString(),
            period: periods.day.id,
        }));
    }, [from, to]);


    function handleDateChange(from, to) {
        setDateRange({period: periods.day.id, from, to});
    }

    const controls = [<VehicleSelect value={props.vehicle} onChange={props.setVehicle} allowClear={false}/>,
        <DayPicker value={from} onDateChange={handleDateChange}/>];

    return (
        <Toolbar title={<Translate id="dayView.page.title"
                                   data={{vehicle: vehicle.name, date: moment(from).format('DD MMMM YYYY')}}/>}
                 controls={controls}/>
    );
};

// DayViewToolbar.propTypes = {
//     vehicle: PropTypes.string.isRequired,
//     setVehicle: PropTypes.func.isRequired
// };

export default DayViewToolbar;
