import React from "react";
import PropTypes from "prop-types";
import {Select} from "antd";
import {Translate} from "react-localize-redux";
import CustomSelect from "../Select";
import {orderByArray} from "../../../../models/OrderBy";

const {Option} = Select;

const OrderBySelect = ({onChange, value, disabled}) => {
    return (
        <CustomSelect allowClear={false} value={value} placeholder="Sortierung" handleChange={onChange}
                      disabled={disabled}>
            {orderByArray.map(vp => (
                <Option key={vp.id} value={vp.id}>
                    {vp.name}
                </Option>
            ))}
        </CustomSelect>
    );
};


// OrderBySelect.propTypes = {
//     onChange: PropTypes.func.isRequired,
//     value: PropTypes.string.isRequired,
//     disabled: PropTypes.bool,
// };

export default OrderBySelect;
