import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import AddModal from "../../common/modals/AddModal";
import {makeStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {requestVehicles} from "../../../actions/actionVehicle";
import {requestUpdateGroup} from "../../../actions/actionGroups";
import {Grid, Button,} from "@material-ui/core"
import CustomTransferList from "./CustomTransferList";
import {useTransferList} from "../../../hooks/useTransferList";
import {Translate} from "react-localize-redux";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

const AddVehicleModal = props => {
    const classes = useStyles();
    const {
        checked,
        left,
        setLeft,
        right,
        numberOfChecked,
        onCheckedRight,
        onCheckedLeft,
        onToggle,
        onToggleAll,
        leftChecked,
        rightChecked,
    } = useTransferList();


    const {group, onClose, vehicles, total, selected} = props;

    useEffect(() => {
        props.requestVehicles({page: 1, perPage: 999999, IsArchived: false});
    }, []);

    useEffect(() => {
        setLeft(vehicles.filter(u => !group.vehicles.some(g => g.id === u.id)));
    }, [vehicles, group]);

    function addVehicle() {
        const userIds = group.users.map(u => u.id);
        const vehicleIdsToAdd = right.map(u => u.id);
        const vehicleIds = group.vehicles.map(u => u.id).concat(vehicleIdsToAdd);
        props.requestUpdateGroup({name: group.name, id: group.id, vehicleIds, userIds});
        onClose();
    }

    return (
        <AddModal title={<Translate id="group.addVehicleModal.title"/>} onAdd={addVehicle} onClose={onClose} open largeModal>
            <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                <Grid item>
                    <CustomTransferList title={<Translate id="group.addVehicleModal.vehicle"/>} items={left} checked={checked}
                                        onToggle={onToggle} onToggleAll={onToggleAll}
                                        numberOfChecked={numberOfChecked}/>
                </Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={onCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right">
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={onCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left">
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <CustomTransferList title={<Translate id="group.addVehicleModal.addToGroup"/>} items={right} checked={checked}
                                        onToggle={onToggle} onToggleAll={onToggleAll}
                                        numberOfChecked={numberOfChecked}/>
                </Grid>
            </Grid>
        </AddModal>
    );
};

// AddVehicleModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestVehicles,
            requestUpdateGroup,
        },
        dispatch,
    );
}

function mapStateToProp(state) {
    return {
        vehicles: state.vehicles.entities,
        total: state.vehicles.total,
        selected: state.vehicles.selected,
        group: state.group,

    };
}

export default connect(mapStateToProp, mapDispatchToProps)(AddVehicleModal);
