import {fork} from "redux-saga/effects";

import sagaUser from "./sagaUser";
import sagaVehicle from "./sagaVehicle";
import sagaDrivebox from "./sagaDrivebox";
import sagaTrips from "./sagaTrips";
import sagaAuthentication from "./sagaAuthentication";
import sagaMandator from "./sagaMandators";
import sagaGroup from "./sagaGroup";
import sagaLiveLocation from "./sagaLiveLocation";
import sageExport from "./sagaExport";
import sagaHistory from "./sagaHistory";
import sagaGeoLocation from "./sagaGeoLocation";
import sagaCommon from "./sagaCommon";
import sagaReports from "./sagaReports";
import sagaOrder from "./sagaOrder";
import sagaScheduledReport from "./sagaScheduledReport"
import sagaVehicleService from "./sagaVehicleService";
import sageInspection from "./sagaInspection";
import sagaTimeRecording from "./sagaTimeRecording";
import sagaCostcenter from "./sagaCostcenter";
import sagaGeofences from "./sagaGeofences";
import sagaReservation from "./sagaReservation";
import sagaChargeStations from "./qualityCharging/sagaChargeStations";
import sagaConnectors from "./qualityCharging/sagaConnectors";
import sagaEmail from "./sagaEmail";
import sagaUnrealisticTrips from "./faultyTrips/sagaUnrealisticTrips";
import sagaMisMatchTrips from "./faultyTrips/sagaMisMatchTrips";

export default function* rootSaga() {
    yield [
        fork(sagaAuthentication),
        fork(sagaUser),
        fork(sagaCostcenter),
        fork(sagaVehicle),
        fork(sagaDrivebox),
        fork(sagaTrips),
        fork(sagaMandator),
        fork(sagaGroup),
        fork(sagaLiveLocation),
        fork(sageExport),
        fork(sagaHistory),
        fork(sagaGeoLocation),
        fork(sagaReports),
        fork(sagaCommon),
        fork(sagaOrder),
        fork(sagaScheduledReport),
        fork(sagaVehicleService),
        fork(sageInspection),
        fork(sagaTimeRecording),
        fork(sagaGeofences),
        fork(sagaReservation),
        fork(sagaChargeStations),
        fork(sagaConnectors),
        fork(sagaEmail),
        fork(sagaUnrealisticTrips),
        fork(sagaMisMatchTrips),
    ];
}
