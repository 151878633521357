import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-localize-redux";

import Toolbar from "../../common/toolbar/Toolbar";
import ChargeStationSelect from "../../common/selects/specificSelects/ChargeStationSelect";
import ConnectorSelect from "../../common/selects/specificSelects/ConnectorSelect";

import { MESSAGE_TYPES } from "../../../types/qualityCharging/messageType";

import { requestStartConnectionToEvo, requestStopConnectionToEvo } from "../../../actions/qualityCharging/actionChargeStations";

import { requestConnectorById } from "../../../actions/qualityCharging/actionConnectors";

//Icons
import FontAwesomeIcon from "../../common/views/FontAwesomeIcon";

const QualityChargingToolbar = ({ setMessageType, messageType }) => {
  const dispatch = useDispatch();
  const [connectorNumber, setConnectorNumber] = useState(null);
  const [chargeStationId, setChargeStationId] = useState();
  const hasEvoConnectionStarted = useSelector(state => state.chargeStations.hasEvoConnectionStarted);

useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      if(chargeStationId && connectorNumber !== null) {
        dispatch(requestConnectorById({chargeStationId, connectorNumber}));
      } 
    }, 10000);

    // Cleanup function
    return () => {
      clearInterval(fetchDataInterval);
    };
  }, [dispatch, chargeStationId, connectorNumber]);

  const actionButtons =  !hasEvoConnectionStarted ? [
    {
      icon:  (
        <FontAwesomeIcon minWidth={26} fontSize={17}>
          fa fa-play
        </FontAwesomeIcon>
      ),
      translateKey: "qualityCharging.startMqttService",
      onClick: () => dispatch(requestStartConnectionToEvo()),
    }
  ] : [
    {
      icon: (
        <FontAwesomeIcon minWidth={26} fontSize={17}>
          fa fa-stop-circle
        </FontAwesomeIcon>
      ),
      translateKey: "qualityCharging.stopMqttService",
      onClick: () => dispatch(requestStopConnectionToEvo()),
    },
  ];

  const toggles = [
    {
      icon: (
        <FontAwesomeIcon
          fontSize={19}
          color={messageType !== MESSAGE_TYPES.METERING_POINT ? "secondary" : undefined}
        >
          fa fa-calculator
        </FontAwesomeIcon>
      ),
      translateKey: "qualityCharging.messageType.meteringPoint",
      onClick: () => setMessageType(MESSAGE_TYPES.METERING_POINT),
      active: messageType === MESSAGE_TYPES.METERING_POINT ,
    },
    {
      icon: (
        <FontAwesomeIcon
          fontSize={19}
          color={messageType !== MESSAGE_TYPES.STATUS ? "secondary" : undefined}
        >
          fa fa-industry
        </FontAwesomeIcon>
      ),
      translateKey: "qualityCharging.messageType.status",
      onClick: () => setMessageType(MESSAGE_TYPES.STATUS),
      active: messageType === MESSAGE_TYPES.STATUS,
    },
  ];


  const controls = [
    <ChargeStationSelect chargeStationId={chargeStationId} setConnectorNumber={setConnectorNumber} setChargeStationId={setChargeStationId} />,
    <ConnectorSelect connectorNumber={connectorNumber} setConnectorNumber={setConnectorNumber} />
  ];

  return (
    <Toolbar
      title={<Translate id="qualityCharging.title" />}
      toggles={toggles}
      actionButtons={actionButtons}
      controls={controls}
    />
  );
};


export default QualityChargingToolbar;
