import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import PropTypes from "prop-types";

import FormBuilder from "../../../managers/formBuilder";
import FormModal from "../../common/modals/FormModal";

import { requestUpdateCostcenters } from "../../../actions/actionCostcenter";
import { Input } from "antd";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";
import { MODAL_TYPES } from "../../common/modals/modal.utils";

const DEFAULT_INPUT_VALUES = {
  foreignId: "",
  description: "",
};

const CostCenterEditModal = (props) => {
  const { onClose, requestUpdateCostcenters, costcenters } = props;
  const { fields, handleInputChange } = useInputChangeHandler(DEFAULT_INPUT_VALUES);
  const { foreignId, description } = fields;

  const handleOnSave = () => {
    let objToUpdate = {
      costcenterIds: costcenters.filter((v) => v.isSelected).map((v) => v.id),
    };

    foreignId && (objToUpdate.foreignId = foreignId);

    description && (objToUpdate.description = description);

    requestUpdateCostcenters(objToUpdate);
    onClose();
  };

  const inputs = [
    new FormBuilder.FormInput(
      <Translate id="user.editmodal.foreignKey" />,
      <Input value={foreignId} onChange={(e) => handleInputChange("foreignId", e.target.value)} />
    )
      .withKey("foreignId")
      .build(),
    new FormBuilder.FormInput(
      <Translate id="user.editmodal.foreignKey" />,
      <Input value={description} onChange={(e) => handleInputChange("description", e.target.value)} />
    )
      .withKey("description")
      .build(),
  ];

  return (
    <FormModal
      action="update"
      onSave={handleOnSave}
      onClose={onClose}
      formType={MODAL_TYPES.withoutValidation}
      title="Kostenstelle bearbeiten"
      inputs={inputs}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestUpdateCostcenters,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    tree: state.tree,
    costcenters: state.costcenters.entities,
    loading: state.costcenters.loading,
  };
}

// CostCenterEditModal.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   id: PropTypes.string.isRequired,
// };

export default connect(mapStateToProp, mapDispatchToProps)(CostCenterEditModal);
