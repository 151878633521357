import React from "react";
import PropTypes from "prop-types";
import {Select} from "antd";
import {Translate} from "react-localize-redux";
import CustomSelect from "../Select";
import {groupingArray} from "../../../../models/Grouping";

const {Option} = Select;

const GroupingSelect = ({onChange, value, disabled, groupArray}) => {
    return (
        <CustomSelect allowClear={false}
                      value={value}
                      placeholder="Gruppierung"
                      handleChange={onChange}
                      disabled={disabled}>
            {groupArray.map(vp => (
                <Option key={vp.id} value={vp.id}>
                    {vp.name}
                </Option>
            ))}
        </CustomSelect>
    );
};


// GroupingSelect.propTypes = {
//     onChange: PropTypes.func,
//     value: PropTypes.string,
//     disabled: PropTypes.bool,
// };

export default GroupingSelect;
