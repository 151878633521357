import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import CustomSelect from "../common/selects/Select";
import { Translate } from "react-localize-redux";


const { Option } = Select;
const options = [
    { id: 0, name: <Translate id="drivebox.page.all"/> },
    { id: 1, name: <Translate id="drivebox.page.assigned"/> },
    { id: 2, name: <Translate id="drivebox.page.unassigned"/> },
];

const AssignedDriveBoxSelect = props => {
    const { onChange, value } = props;
    return (
        <CustomSelect value={value} handleChange={onChange} allowClear={false}>
            {options.map(tt => (
                <Option key={tt.id} value={tt.id}>
                    {tt.name}
                </Option>
            ))}
        </CustomSelect>
    );
};

// AssignedDriveBoxSelect.propTypes = {
//     onChange: PropTypes.func.isRequired,
//     value: PropTypes.oneOfType([
//         PropTypes.string.isRequired,
//         PropTypes.number.isRequired,
//     ])
// };

export default AssignedDriveBoxSelect;
