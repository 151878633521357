import React, {Component, useEffect} from "react";
import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {Alert} from "antd";
import {connect} from "react-redux";
import {addDays, format, differenceInCalendarDays, startOfDay} from "date-fns";

import {clearError} from "../../../actions/actionCommon";
import {Link} from "react-router-dom";
import {allowedRoles, isAuthorized} from "../../../managers/authManager";
import {roles} from "../../../models/Role";
import {Button} from "@material-ui/core";

const styles = theme => ({
    toolbar: {
        width: "100%",
        margin: "0px 0px 7px 0px",
    },
});

const TesterToolbar = props => {
    const {classes, me, className} = props;
    const {testerSince, testerExpiresInDays} = me;
    
    useEffect(() => {
        return () => {
            props.clearError();
        }
    }, []);

const startDate = startOfDay(new Date(testerSince));

const testEndDate = addDays(startDate, testerExpiresInDays);
const dayLeft = differenceInCalendarDays(testEndDate, startOfDay(new Date()));

    const dayLeftStr = " (" + (dayLeft <= 0 ? "ist abgelaufen)" : dayLeft == 1 ? dayLeft + "Tag verbleibt)" : dayLeft + " Tage verbleibend)");

    if (testerSince)
        return <div className={className}>
            <Alert className={classes.toolbar}
                   message={"Teststellung bis " + format(testEndDate, "dd.MM.yyyy") + dayLeftStr}
                   closable={isAuthorized(me, allowedRoles(roles.Admin))}
                   closeText={isAuthorized(me, allowedRoles(roles.Admin)) ?
                      "" : ""}
                   onClose={e => e.preventDefault()}
                   description="DRiVEBOX - einfach Ihr digitaler Fuhrparkmanager"
                   type="warning"/>
        </div>;
    return <div/>;
};

function mapStateToProp(state) {
    return {me: state.users.me};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({clearError}, dispatch);
}

TesterToolbar.defaultProps = {
    closable: false,
};

// TesterToolbar.propTypes = {
//     closable: PropTypes.bool,
//     hideTitle: PropTypes.bool,
// };

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(withStyles(styles, {withTheme: true})(TesterToolbar));
