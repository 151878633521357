import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import Page from "../common/container/Page";
import LanguageSelect from "../common/selects/specificSelects/LanguageSelect";

import { menu } from "../../managers/pathManager";
import { useModalStatus, useSnackbar } from "../../hooks/useModalStatus";
import { setLanguage } from "../../actions/actionCommon";
import {
  requestChangePassword,
  requestCurrentlyLoggedinUser,
  requestUpdateUserMandator,
} from "../../actions/actionUser";
import { requestUpdateMandatorNotification } from "../../actions/actionMandators";

import Snackbar from "../common/views/Snackbar";
import { Translate } from "react-localize-redux";
import ChangePasswordModal from "../common/modals/ChangePasswordModal";
import ErrorToolbar from "../common/toolbar/ErrorToolbar";
import TagEmailSelect from "../common/selects/specificSelects/TagEmailSelect";
import { Checkbox } from "antd";
import InputField from "../common/inputs/InputField";
import AddressSelect from "../common/selects/specificSelects/AddressSelect";
import ContactPersonTable from "./ContactPersonTable";
import ContactPersonModal from "./ContactPersonModal";

const styles = (theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  card: {
    maxWidth: 900,
    margin: "auto",
    marginTop: 20,
  },
  avatar: {
    backgroundColor: red[500],
  },
});

const SettingPage = (props) => {
  const dispatch = useDispatch();

  const me = useSelector((state) => state.users.me);
  const language = useSelector((state) => state.language);
  const error = useSelector((state) => state.error);

  const {
    open: changeLanguageSnackbar,
    show: showSuccessChangeLanguage,
    hide: hideChangeLanguageSnackbar,
  } = useSnackbar();
  const {
    modal: changePasswordModal,
    open: openChangePasswordModal,
    close: closeChangePasswordModal,
  } = useModalStatus();
  const {
    modal: contactPersonModal,
    open: openConcatPersonModal,
    close: closeContactPersonModal,
  } = useModalStatus();

  const { classes } = props;
  const {
    sendDriveboxUnpluggedNotification,
    sendDriveboxUnpluggedNotificationTo,
  } = me;

  const [plugOffActivate, setPlugOfActivate] = useState(
    sendDriveboxUnpluggedNotification
  );
  const [plugOffMails, setPlugOffMails] = useState(
    sendDriveboxUnpluggedNotificationTo
  );

  useEffect(() => {
    dispatch(requestCurrentlyLoggedinUser());
  }, []);

  const [name, setName] = useState();
  const [uidNumber, setUidNumber] = useState();
  const [registrationNumber, setRegistrationNumber] = useState();
  const [billingAddress, setBillingAddress] = useState();
  const [showShippingAddress, setShowShippingAddress] = useState();
  const [shippingAddress, setShippingAddress] = useState();

  const [contactPersons, setContactPersons] = useState();

  const fullName = me.firstName + " " + me.lastName;
  const shortForm = me.firstName
    ? me.firstName.charAt(0) + me.lastName.charAt(0)
    : "-";
  const rolesStr = me.role ? me.role.name : [];

  function handleSavePlugInPlugOffEvent() {
    dispatch(
      requestUpdateMandatorNotification({
        sendDriveboxUnpluggedNotification: plugOffActivate,
        emails: plugOffMails,
      })
    );
  }

  function changePassword(currentPassword, newPassword) {
    dispatch(requestChangePassword({ currentPassword, newPassword }));
  }

  function handleLanguageChange(language) {
    dispatch(setLanguage(language));

    showSuccessChangeLanguage();
    setTimeout(() => {
      window.location.reload(true);
    }, 500);
  }

  /*
             <Card className={classes.card}>
                <CardHeader
                    title="Stammdaten"
                    subheader="Geben Sie hier ihre Stammdaten an"
                />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Typography>Firmenbezeichnung</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <InputField value={name} onChange={e => setName(e.target.value)} variant="outlined"/>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography>UID-Nummer</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <InputField value={uidNumber} onChange={e => setUidNumber(e.target.value)}
                                        variant="outlined"/>
                        </Grid>


                        <Grid item xs={4}>
                            <Typography>Firmenbuchnummer</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <InputField value={registrationNumber} onChange={e => setRegistrationNumber(e.target.value)}
                                        variant="outlined"/>
                        </Grid>


                        <Grid item xs={4}>
                            <Typography>Rechnungsadresse</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <AddressSelect value={billingAddress} onChange={setBillingAddress}/>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography>Weitere Adresse</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Checkbox checked={showShippingAddress}
                                      onChange={e => setShowShippingAddress(e.target.checked)}>
                                Abweichende Lieferadresse
                            </Checkbox>
                        </Grid>

                        {showShippingAddress && <>
                            <Grid item xs={4}>
                                <Typography>Lieferadresse</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <AddressSelect value={shippingAddress} onChange={setShippingAddress}/>
                            </Grid>
                        </>}

                        <Grid item xs={8}>
                            <Typography>
                                Wolle Sie die Änderungen speichern?
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Button fullWidth variant="contained" color="primary">
                                Speichern
                            </Button>
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>

            <Card className={classes.card}>
                <CardHeader
                    title="Kontaktpersonen"
                    subheader="Geben Sie an, wen wir bei Informationen kontaktieren dürfen"
                />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ContactPersonTable onRemove={() => {
                            }}/>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>
                                Weitere Kontaktperson hinzufügen
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Button onClick={openConcatPersonModal} fullWidth variant="contained" color="secondary">
                                Hinzufügen
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
     */

  return (
    <Page
      activePage={menu.settings.key}
      toolbar={<div className={classes.toolbar} />}
      disableErrorToolbar
    >
      {error.updatedAt && <ErrorToolbar className={classes.card} />}
      <Card className={classes.card}>
        <CardHeader title={me.mandator.name} />
        <CardHeader
          avatar={
            <Avatar aria-label={fullName} className={classes.avatar}>
              {shortForm}
            </Avatar>
          }
          title={fullName}
          subheader={rolesStr}
        />

        <CardContent>
          <LanguageSelect onChange={handleLanguageChange} value={language} />
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardHeader
          title={<Translate id="setting.security" />}
          subheader={<Translate id="setting.securityInfo" />}
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Typography>
                <Translate id="setting.changePassword" />
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={openChangePasswordModal}
                fullWidth
                variant="contained"
                color="secondary"
              >
                <Translate id="setting.changePasswordNow" />
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardHeader
          title={<Translate id={"setting.notifications"} />}
          subheader={<Translate id={"setting.notificationsInfo"} />}
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography>
                <Translate id={"setting.activateNotification"} />{" "}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Checkbox
                checked={plugOffActivate}
                onChange={(e) => setPlugOfActivate(e.target.checked)}
              >
                <Translate id={"setting.plugOffInfo"} />
              </Checkbox>
            </Grid>

            <Grid item xs={4}>
              <Typography>
                <Translate id={"setting.emails"} />
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TagEmailSelect
                disabled={!plugOffActivate}
                onChange={setPlugOffMails}
                value={!plugOffActivate ? [] : plugOffMails}
              />
            </Grid>

            <Grid item xs={8}>
              <Typography>Wolle Sie die Änderungen speichern?</Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={handleSavePlugInPlugOffEvent}
                disabled={
                  plugOffActivate === sendDriveboxUnpluggedNotification &&
                  plugOffMails === sendDriveboxUnpluggedNotificationTo
                }
                fullWidth
                variant="contained"
                color="primary"
              >
                Speichern
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {contactPersonModal && (
        <ContactPersonModal onClose={closeContactPersonModal} />
      )}
      {changePasswordModal && (
        <ChangePasswordModal
          onClose={closeChangePasswordModal}
          onConfirm={changePassword}
          oldPasswordNeeded
        />
      )}
      <Snackbar
        translateKey="setting.changeLanguage"
        onClose={hideChangeLanguageSnackbar}
        open={changeLanguageSnackbar}
      />
    </Page>
  );
};

export default withStyles(styles, { withTheme: true })(SettingPage);
