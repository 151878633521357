import { Translate } from "react-localize-redux";
import ReservationAddModalStepThreeDriver from "./ReservationAddModalStepThreeDriver";
import FormInput from "../../../common/inputs/FormInput";
import CostCenterSelect from "../../../common/selects/specificSelects/CostCenterSelect";
import UserSelect from "../../../common/selects/specificSelects/UserSelect";

import { getRequiredRule } from "../ReservationAddModal.utils";
import { RESERVATION_INPUT_TYPES } from "../ReservationAddModal.utils";

const ReservationAddModalStepThreeAdmin = ({
  purpose,
  comment,
  handleInputChange,
  handleTextAreaChange,
  driverId,
  isAuthorised,
  costcenterId,
}) => {

  return (
    <>
      {isAuthorised && (
        <FormInput
          name={RESERVATION_INPUT_TYPES.costcenterId}
          label={
            <Translate id="reservation.addReservationModal.pageThree.costCenter" />
          }
        >
          <CostCenterSelect
            onChange={(value) => handleInputChange("costcenterId", value)}
            onClear={() => handleInputChange("costcenterId", null)}
            showArchived={false}
            reservationModal
            value={costcenterId}
          />
        </FormInput>
      )}
      <FormInput
        name={RESERVATION_INPUT_TYPES.driverId}
        label={<Translate id="group.editModal.users" />}
        rules={[getRequiredRule(<Translate id="reservation.addReservationModal.pageThree.driverValidation" />)]}
      >
        <UserSelect
          onChange={(value) => handleInputChange("driverId", value)}
          showArchived={false}
          driverId={driverId}
          allowClear={false}
          reservationModal={true}
        />
      </FormInput>
      <ReservationAddModalStepThreeDriver
        purpose={purpose}
        comment={comment}
        handleTextAreaChange={handleTextAreaChange}
      />
    </>
  );
};

export default ReservationAddModalStepThreeAdmin;
