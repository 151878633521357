import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
//Common
import UserSelect from "../../common/selects/specificSelects/UserSelect";
import VehicleSelect from "../../common/selects/specificSelects/VehicleSelect";
import TripTypeSelect from "../../common/selects/specificSelects/TripTypeSelect";
import CostCenterSelect from "../../common/selects/specificSelects/CostCenterSelect";
import AddressSelect from "../../common/selects/specificSelects/AddressSelect";
import DatePicker from "../../common/selects/datePickers/CustomDateTimePicker";
import FormModal from "../../common/modals/FormModal";

import { Input } from "antd";
import {
  loadTrip,
  requestUpdateTrip,
} from "../../../actions/actionTrips";
import { requestVehicleById } from "../../../actions/actionVehicle";
import { requestCostcenters } from "../../../actions/actionCostcenter";
import FormBuilder from "../../../managers/formBuilder";
import { allowedRoles, isAuthorized } from "../../../managers/authManager";
import { roles } from "../../../models/Role";
import { packages } from "../../../models/Package";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";

const getDefaultInputValue = (trip) => ({
  startedAt: trip ? trip.startedAt : null,
  finishedAt: trip ? trip.finishedAt : null,
  startAddress: trip ? trip.startAddress : null,
  destinationAddress: trip ? trip.destinationAddress : null,
  vehicleId: trip ? trip.vehicle?.id : null,
  driverId: trip ? trip.driver?.id : null,
  costcenterId: trip ? trip.costcenterId : null,
  type: trip ? trip.type : null,
  mileageInKm: trip ? Math.round(trip.mileageInMeters / 1000) : null,
  description: trip ? trip.description : null,
});

const TripEditModal = (props) => {
  const {
    onClose,
    loading,
    me,
    tripId,
    requestUpdateTrip,
  } = props;

  const trip = useSelector(state => state.trips.current);
  
  const { handleInputChange, fields } = useInputChangeHandler(
    getDefaultInputValue(trip)
  );

  const isCustomTrip = trip.agent === 1;
  const isMigratedTrip = trip.agent === 3;
  const driverName = trip.driver ? trip.driver.name : "";

  const {
    driverId,
    vehicleId,
    startedAt,
    finishedAt,
    mileageInKm,
    type,
    description,
    startAddress,
    destinationAddress,
    costcenterId,
  } = fields;

  useEffect(() => {
    if (isAuthorized(me, allowedRoles(roles.Admin), [packages.CostCenter]))
      props.requestCostcenters();
  }, []);

  function handleOnSave() {
    const mergeStateWithUpdatedValues = { ...trip, ...fields };

    //Do not override DriverId if role is below Groupleader
    if (!isAuthorized(me, allowedRoles(roles.GroupLeader))) {
      mergeStateWithUpdatedValues.driverId = driverId;
    }

    //remove start and end adr from moment trips
    if (isMigratedTrip) {
      mergeStateWithUpdatedValues.startAddress = null;
      mergeStateWithUpdatedValues.destinationAddress = null;
    } else {
      if (mergeStateWithUpdatedValues.destinationAddress.latitude) {
        mergeStateWithUpdatedValues.destinationLatitude =
          mergeStateWithUpdatedValues.destinationAddress.latitude;
        mergeStateWithUpdatedValues.destinationLongitude =
          mergeStateWithUpdatedValues.destinationAddress.longitude;
      }
      if (mergeStateWithUpdatedValues.startAddress.latitude) {
        mergeStateWithUpdatedValues.startLatitude =
          mergeStateWithUpdatedValues.startAddress.latitude;
        mergeStateWithUpdatedValues.startLongitude =
          mergeStateWithUpdatedValues.startAddress.longitude;
      }
    }

    if (mergeStateWithUpdatedValues.mileageInKm) {
      mergeStateWithUpdatedValues.mileageInMeters =
        mergeStateWithUpdatedValues.mileageInKm * 1000;
    }

    requestUpdateTrip({ ...mergeStateWithUpdatedValues, tripId: tripId });

    onClose();
  }

  const customTripInputs = [
    new FormBuilder.FormInput(
      <Translate id="trip.editmodal.vehicle" />,
      <VehicleSelect value={vehicleId} onChange={(value) => handleInputChange("vehicleId", value)} allowClear={false}  />
    )
      .withValue(vehicleId)
      .withKey("vehicleId")
      .withValidationMessage(
        <Translate id="trip.editmodal.vehicleValidation" />
      )
      .isRequired()
      .build(),
    new FormBuilder.FormInput(
      <Translate id="trip.editmodal.driver" />,
      <UserSelect driverId={driverId} onChange={(value) => handleInputChange("driverId", value)} allowClear={false} />
    )
      .withValue(
        isAuthorized(me, allowedRoles(roles.GroupLeader))
          ? driverId
          : driverName
      )
      .withKey("driverId")
      .withValidationMessage(<Translate id="trip.editmodal.driverValidation" />)
      .isRequired()
      .build(),
    new FormBuilder.FormInput(
      <Translate id="trip.editmodal.startAt" />,
      <DatePicker value={startedAt} onChange={(value) => handleInputChange("startedAt", value)} disableFuture />
    )
      .withValue(startedAt)
      .withKey("startedAt")
      .withValidationMessage(
        <Translate id="trip.editmodal.startAtValidation" />
      )
      .isRequired()
      .build(),
    new FormBuilder.FormInput(
      <Translate id="trip.editmodal.finishAt" />,
      <DatePicker value={finishedAt} onChange={(value) => handleInputChange("finishedAt", value)}  disableFuture />
    )
      .withValue(finishedAt)
      .withKey("finishedAt")
      .withValidationMessage(
        <Translate id="trip.editmodal.finishAtValidation" />
      )
      .isRequired()
      .build(),
    new FormBuilder.FormInput(
      <Translate id="trip.editmodal.startAddress" />,
      <AddressSelect value={startAddress} onChange={(value) => handleInputChange("startAddress", value)} />
    )
      .withValue(startAddress)
      .withKey("startAddress")
      .withValidationMessage(
        <Translate id="trip.editmodal.startAddresValidation" />
      )
      .isRequired()
      .build(),
    new FormBuilder.FormInput(
      <Translate id="trip.editmodal.destinationAddress" />,
      <AddressSelect value={destinationAddress} onChange={(value) => handleInputChange("destinationAddress", value)} />
    )
      .withValue(destinationAddress)
      .withKey("destinationAddress")
      .withValidationMessage(
        <Translate id="trip.editmodal.destinationAddressValidation" />
      )
      .isRequired()
      .build(),
    new FormBuilder.FormInput(
      <Translate id="trip.editmodal.mileage" />,
      <Input value={mileageInKm} onChange={(e) => handleInputChange("mileageInKm", e.target.value)} suffix="km" />
    )
      .withValue(mileageInKm)
      .withKey("mileageInKm")
      .withValidationMessage(
        <Translate id="trip.editmodal.mileageValidation" />
      )
      .isRequired()
      .build(),
    new FormBuilder.FormInput(
      <Translate id="trip.editmodal.tripType" />,
      <TripTypeSelect value={type}  onChange={(value) => handleInputChange("type", value)} allowClear={false} />
    )
      .withValue(type)
      .withKey("type")
      .withValidationMessage(
        <Translate id="trip.editmodal.tripTypeValidation" />
      )
      .isRequired()
      .build(),
  ];

  if (isAuthorized(me, allowedRoles(roles.Admin), [packages.CostCenter])) {
    customTripInputs.push(
      new FormBuilder.FormInput(
        <Translate id="trip.editmodal.costCenter" />,
        <CostCenterSelect value={costcenterId} onChange={(value) => handleInputChange("costcenterId", value)} allowClear={true} showArchived={false} />
      )
        .withValue(costcenterId)
        .withKey("costcenterId")
        .build()
    );
  }

  customTripInputs.push(
    new FormBuilder.FormInput(
      <Translate id="trip.editmodal.description" />,
      <Input.TextArea value={description} onChange={(e) => handleInputChange("description", e.target.value)} autosize={{ minRows: 3, maxRows: 5 }} />
    )
      .withValue(description)
      .withKey("description")
      .build()
  );

  const tripInputs = [
    new FormBuilder.FormInput(
      <Translate id="trip.editmodal.driver" />,
      <UserSelect driverId={isAuthorized(me, allowedRoles(roles.GroupLeader)) ? driverId : driverName} onChange={(value) => handleInputChange("driverId", value)} />
    )
      .withValue(
        isAuthorized(me, allowedRoles(roles.GroupLeader))
          ? driverId
          : driverName
      )
      .withKey("driverId")
      .withValidationMessage(<Translate id="trip.editmodal.driverValidation" />)
      .isRequired()
      .build(),
    new FormBuilder.FormInput(
      <Translate id="trip.editmodal.tripType" />,
      <TripTypeSelect value={type}  onChange={(value) => handleInputChange("type", value)} />
    )
      .withValue(type)
      .withKey("type")
      .withValidationMessage(
        <Translate id="trip.editmodal.tripTypeValidation" />
      )
      .isRequired()
      .build(),
    new FormBuilder.FormInput(
      <Translate id="trip.editmodal.description" />,
      <Input.TextArea autosize={{ minRows: 3, maxRows: 5 }} value={description} onChange={(e) => handleInputChange("description", e.target.value)} />
    )
      .withValue(description)
      .withKey("description")
      .build(),
  ];

  if (isAuthorized(me, allowedRoles(roles.Admin), [packages.CostCenter])) {
    tripInputs.push(
      new FormBuilder.FormInput(
        <Translate id="trip.editmodal.costCenter" />,
        <CostCenterSelect value={costcenterId} onChange={(value) => handleInputChange("costcenterId", value)} allowClear={true} showArchived={false} />
      )
        .withValue(costcenterId)
        .withKey("costcenterId")
        .build()
    );
  }

  return (
    <FormModal
      loading={loading}
      onSave={handleOnSave}
      onClose={onClose}
      title={<Translate id="trip.editmodal.title" />}
      inputs={isCustomTrip ? customTripInputs : tripInputs}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestUpdateTrip,
      requestVehicleById,
      requestCostcenters,
      loadTrip,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    loading: state.trips.loading,
    costcenters: state.trips.costcenters,
    me: state.users.me,
  };
}

// TripEditModal.propTypes = {
//   open: PropTypes.bool.isRequired,
//   onClose: PropTypes.func.isRequired
// };

export default connect(mapStateToProp, mapDispatchToProps)(TripEditModal);
