import React, {useState} from "react";
import {bindActionCreators} from "redux";

import {Redirect} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";

import {menu} from "../../managers/pathManager";
import {secondsToString, serverTimeToSeconds} from "../../managers/timeManager";
import {setFilter} from "../../actions/actionFilter";
import {Translate} from "react-localize-redux";
import GenericTable from "../common/table/GenericTable";
import {customTranslation, getTranslation} from "../../translations/customTranslation";

const useStyles = makeStyles(theme => ({
    container: {
        margin: "5px 5px"
    }
}));

const DashboardTripTable = ({vehicles, setFilter, test, me}) => {
    const [openTrip, redirectToTrip] = useState(false);
    const classes = useStyles();
    const columnInformations = [
        {title: <Translate id="dashboard.table.vehicle"/>, key: "vehicle.name"},
        {title: <Translate id="dashboard.table.trips"/>, key: "trips"},
        {title: <Translate id="dashboard.table.duration"/>, key: "duration"},
        {title: getTranslation(customTranslation.business, me), key: "businessMileageInMeters"},
        {title: <Translate id="dashboard.table.private"/>, key: "privateMileageInMeters"},
        {title: getTranslation(customTranslation.driveToWork, me), key: "driveToWorkMileageInMeters"},
    ];


    const mappedData = vehicles.map(v => {
        v.trips = Math.round(v.mileageInMeters / 1000) + "km (" + (v.businessTripCount + v.privateTripCount + v.driveToWorkTripCount) + " Fahrten)";
        v.duration = secondsToString(v.timeInSeconds);
        v.businessMileageInMeters = Math.round(v.businessMileageInMeters / 1000) + " km (" + v.businessTripCount + " Fahrten)";
        v.privateMileageInMeters = Math.round(v.privateMileageInMeters / 1000) + " km (" + v.privateTripCount + " Fahrten)";
        v.driveToWorkMileageInMeters = Math.round(v.driveToWorkMileageInMeters / 1000) + " km (" + v.driveToWorkTripCount + " Fahrten)";
        return v;
    });

    return (
        <div className={classes.container}>
            <GenericTable data={mappedData} columnInformations={columnInformations} hover onRowClick={(row) => {
                const vehicleId = row.vehicle.id;
                setFilter({tripbookVehicle: vehicleId}, {tripbookVehicle: vehicleId});
                redirectToTrip(true);
            }}/>

            {openTrip && <Redirect to={menu.trips.path}/>}
        </div>
    );
};


function mapStateToProp(state) {
    return {
        me: state.users.me,
        vehicles: state.reports.vehicles,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setFilter}, dispatch);
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(DashboardTripTable);
