import React from "react";
import PropTypes from "prop-types";
import {Translate} from "react-localize-redux";
import {Select} from "antd";
import CustomSelect from "../Select";
import MultiSelect from "../MultiSelect";
import {vehicleProfileArray} from "../../../../models/VehicleProfile";

const {Option} = Select;

const VehicleProfileSelect = props => {
    const {onChange, value, disabled, multiple, allowClear} = props;

    if (multiple) return <MultiSelect placeholder={<Translate id="common.select.vehicleprofiles"/>}
                                      selectedArray={value}
                                      array={vehicleProfileArray}
                                      handleChange={onChange}/>;
    return (
        <CustomSelect value={value}
                      placeholder={<Translate id="common.select.vehicleprofile"/>}
                      handleChange={onChange}
                      disabled={disabled}
                      allowClear={allowClear}>
            {vehicleProfileArray.map(vp => (
                <Option key={vp.id} value={vp.id}>
                    {vp.name}
                </Option>
            ))}
        </CustomSelect>
    );
};

VehicleProfileSelect.defaultProps = {
    disabled: false,
    multiple: false,
    allowClear: true,
};

// VehicleProfileSelect.propTypes = {
//     onChange: PropTypes.func.isRequired,
//     value: PropTypes.string.isRequired,
//     disabled: PropTypes.bool,
//     multiple: PropTypes.bool,
//     allowClear: PropTypes.bool
// };

export default VehicleProfileSelect;
