import React, {useEffect} from "react";
import PropTypes from "prop-types";
import EditModal from "../../common/modals/CustomModal";
import {Translate} from "react-localize-redux";
import {Grid} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {requestUsers} from "../../../actions/actionUser";


import {List, ListItemText, ListItem, ListItemAvatar, Avatar, Typography} from "@material-ui/core";

const DriverModal = props => {
    const {vehicleId, onClose} = props;
    const dispatch = useDispatch();
    const users = useSelector(state => state.users.entities);
    const filter = useSelector(state => state.filter);
    const {api} = filter;

    useEffect(() => {
        if (vehicleId)
            dispatch(requestUsers({driverFrom: api.from, driverTo: api.to, driverVehicleIds: [vehicleId]}));
    }, [vehicleId]); 

    const contents = [
        <Grid container>
            <Grid item xs={12}>
                <List>
                    {users.map(u => {
                            const fullName = u.firstName + " " + u.lastName;
                            const shortForm = u.firstName ? u.firstName.charAt(0) + u.lastName.charAt(0) : "-";
                            const rolesStr = u.role ? u.role.name : "";

                            return <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar aria-label={fullName}>
                                        {shortForm}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={fullName}
                                    secondary={rolesStr}
                                />
                            </ListItem>
                        }
                    )}

                </List>
            </Grid>
        </Grid>,
    ];


    return <EditModal largeModal
                      onClose={onClose}
                      title="Fahrer"
                      contents={contents}/>;
};

// DriverModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     vehicleId: PropTypes.string.isRequired,
// };

export default DriverModal;
