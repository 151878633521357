import { Translate } from "react-localize-redux";

import FormInput from "../../../common/inputs/FormInput";
import VehicleSelect from "../../../common/selects/specificSelects/VehicleSelect";

import { getRequiredRule } from "../ReservationAddModal.utils";

const ReservationAddModalStepTwo = ({
  vehicleId,
  handleInputChange,
}) => {
  return (
    <FormInput
      name="vehicleId"
      label={
        <Translate id="reservation.addReservationModal.pageTwo.selectVehicle" />
      }
      rules={[
        getRequiredRule(
          <Translate id="reservation.addReservationModal.pageTwo.selectVehicleValidation" />
        ),
      ]}
    >
      <VehicleSelect
        onChange={(value) =>
          handleInputChange("vehicleId", value)
        }
        vehicleId={vehicleId}
        reservationModal={true}
      />
    </FormInput>
  );
};

export default ReservationAddModalStepTwo;
