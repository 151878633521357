import React from 'react'
import CustomModal from '../../common/modals/CustomModal';
import Grid from "@material-ui/core/Grid";
import PeriodDatePicker from '../../common/selects/datePickers/PeriodDatePicker';
import Button from "@material-ui/core/Button";
import { Translate } from "react-localize-redux";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import { updateFilterAPICall } from '../../../actions/actionFilter';
import { periods } from '../../../models/Period';
import { fromToByPeriod } from '../../../managers/timeManager';
import { subMonths } from 'date-fns';
import { requestTripMonthlyPreport } from '../../../actions/actionExport';

const useStyles = makeStyles((theme) => ({
    exportButton: {
      marginTop: theme.spacing(1),
    },
  }));

const TripsRecordModal = props => {
    const dispatch = useDispatch();
    let isButtonDisabled = false;
    const classes = useStyles();
    const {onClose} = props 
    const filter = useSelector((state) => state.filter.api);

    const defaultRange = fromToByPeriod(
        periods.month.id,
        subMonths(new Date(), 1)
      );

    function handleDateChange(period, from, to) {
        dispatch(
          updateFilterAPICall({
            period,
            from: new Date(from).toISOString(),
            to: new Date(to).toISOString(),
          })
        );
      }

    const content = (
        <Grid container spacing={1}>
          <Grid item xs={3}>
            Zeitraum
          </Grid>
    
          <Grid item xs={9}>
            <PeriodDatePicker
              period={filter.period || periods.month.id}
              onDateChange={handleDateChange}
              startDate={
                filter.from
                  ? new Date(filter.from).toISOString()
                  : defaultRange.from
              }
              endDate={
                filter.to ? new Date(filter.to).toISOString() : defaultRange.to
              }
              middleRange={true}
            />
          </Grid>
    
          <Grid item xs={12}>
            <Button
              onClick={handleExport}
              disabled={isButtonDisabled}
              className={classes.exportButton}
              variant="contained"
              color="primary"
              fullWidth
            >
              <Translate id="trip.exportmodal.export" />
            </Button>
          </Grid>
        </Grid>
      );

      function exportMonthlyTripReport () {
        dispatch(
          requestTripMonthlyPreport({
                ...filter,
                from: filter.from ? filter.from : defaultRange.from.toISOString(),
                to: filter.to ? filter.to : defaultRange.to.toISOString(),
            })
        );
        }

      function handleExport (){
        exportMonthlyTripReport()
        onClose()
      }

    return (
      <CustomModal
      onClose={onClose}
      title={<Translate id="trip.exportmodal.title" />}
      contents={[content]}
      />
    )
}

export default TripsRecordModal;