import React, {useEffect} from "react";
import {withLocalize} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Timeline from "./Timeline";
import {requestVehicles} from "../../actions/actionVehicle";
import {requestTrips} from "../../actions/actionTrips";

import moment from "moment";
import ViewTypes from "./ViewTypes";
import PropTypes from "prop-types";
import {customTranslation, getTranslation} from "../../translations/customTranslation";

const VehicleDayTimeline = props => {
    const {filter, from, to, vehicles, trips, viewType, setTrip, onTrip, me} = props;
    const {api, updatedAt} = filter;

    const userSelectedDate = moment(from).format('YYYY-MM-DD');

    useEffect(() => {
        props.requestVehicles({...api, IsArchived: false, PerPage: 1000});
        props.requestTrips({from, to, PerPage: 1000});
    }, [updatedAt, viewType, from, to]);


    const modifiedTrips = trips.map(t => {
        const endDate = moment(t.finishedAt).format('YYYY-MM-DD');
    
        if (endDate > userSelectedDate) {
            return {
                ...t,
                finishedAt: `${userSelectedDate}T22:00:00Z`
            };
        } else {
            return t;
        }
    });

    const calcRuntime = () => modifiedTrips.reduce((acc, {startedAt, finishedAt}) => {
        acc.from = (acc.from === undefined || startedAt < acc.from) ? startedAt : acc.from;
        acc.to = (acc.to === undefined || finishedAt > acc.to) ? finishedAt : acc.to;
        return acc;
    }, {});

    const mappedVehicles = vehicles.map(v => {
        return {id: v.id, name: v.name};
    });

    const runtime = calcRuntime();
    let beginsHour = moment(runtime.from).local().format("HH");
    let endsHour = moment(runtime.to).local().format("HH") === "00" ? moment(runtime.to).local().format("HH") : moment(runtime.to).local().add(1, "hour").format("HH");
    endsHour = endsHour === "00" ? "24" : endsHour;

    let mappedTrips = modifiedTrips.map(t => {
        return {
            trip: t,
            id: t.id,
            resource: t.vehicle.id,
            text: Math.round(t.mileageInMeters / 1000) + "km",
            start: moment(t.startedAt)
                .local()
                .add(1, "minute")
                .format("YYYY-MM-DD HH:mm:ss"),
            end: moment(t.finishedAt)
                .local()
                .subtract(1, "minute")
                .format("YYYY-MM-DD HH:mm:ss"),
            showPopover: false,
            bubbleHtml: triptype(t.type),
        };
    });

    function triptype(type) {
        if (type == 0) return getTranslation(customTranslation.business, me);
        if (type == 1) return props.translate("model.triptype.private");
        if (type == 2) return getTranslation(customTranslation.driveToWork, me);
    }

    return <Timeline setTrip={setTrip}
                     onTrip={onTrip}
                     items={mappedTrips}
                     groups={mappedVehicles}
                     viewType={ViewTypes.Day}
                     beginsHour={beginsHour}
                     endsHour={endsHour}
                     from={from}
    />;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({requestVehicles, requestTrips}, dispatch);
}

function mapStateToProp(state) {
    return {
        me: state.users.me,
        vehicles: state.vehicles.entities,
        trips: state.trips.entities,
        filter: state.filter,
    };
}


// VehicleDayTimeline.propTypes = {
//     from: PropTypes.oneOfType([
//         PropTypes.object,
//         PropTypes.string,
//     ]),
//     to: PropTypes.oneOfType([
//         PropTypes.object,
//         PropTypes.string,
//     ]) 
// };


export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(withLocalize(VehicleDayTimeline));