import React from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "../LoadingIndicator";
import Grid from "@material-ui/core/Grid";
import { Translate } from "react-localize-redux";

function LoadingContainer(props) {
    const { active } = props;
    if (active) return props.children();
    else
        return (
            <Grid container justify="center" direction="column" alignItems="center">
                <Grid>
                    <LoadingIndicator/>
                </Grid>
                <Grid>
                    <h4><Translate id="common.loading"/></h4>
                </Grid>
            </Grid>
        );
}

// LoadingContainer.propTypes = {
//     active: PropTypes.bool.isRequired,
// };

export default LoadingContainer;
