import React, { useEffect, useState, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { Translate } from "react-localize-redux";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Page from "../common/container/Page";
import Toolbar from "../common/toolbar/Toolbar";
import Snackbar from "../common/views/Snackbar";
import CustomModal from "../common/modals/CustomModal";

import {
  deselectAllVehicle,
  requestVehicles,
  requestVehicleToggleArchive,
  requestVehiclesToggleArchive,
  selectAllVehicles,
  selectVehicle,
  requestTracksOfVehicle
} from "../../actions/actionVehicle";

import {
  requestDriveboxReport,
  requestVehicleReport,
} from "../../actions/actionReport";

import { modalActions } from "../common/modals/CustomModal";

import { requestVehiclesExportXlsx } from "../../actions/actionExport";

import { menu } from "../../managers/pathManager";
import { periods } from "../../models/Period";

import VehicleTable from "./VehicleTable";
import HistoryModal from "../common/modals/HistoryModal";
import VehicleModal from "./vehicleModals/VehicleModalNew";
import VehilceEditModal from "./vehicleModals/VehicleModal";
import VehicleAddModal from "./vehicleModals/VehicleAddModal";
import FilterModal from "./vehicleModals/VehicleFilterModal";
import CalibrationModal from "./vehicleModals/VehicleCalibrationModal";
import VehicleBulkEditModal from "./vehicleModals/VehicleBulkEditModal";
import ArchiveModal from "../common/modals/ArchiveModal";
import ActiveVehicleModal from "./vehicleModals/ActiveVehicleModal";

import FilterToolbar from "../common/toolbar/FilterToolbar";

import { setTextArray, updateFilterAPICall } from "../../actions/actionFilter";
import { allowedRoles, isAuthorized } from "../../managers/authManager";
import { roles } from "../../models/Role";
import { usePagination } from "../../hooks/usePagination";
import { useModalStatus, useSnackbar } from "../../hooks/useModalStatus";
import { vehicleProfileArray } from "../../models/VehicleProfile";
import { Grid, makeStyles } from "@material-ui/core";
import VehicleReportCard from "./VehicleReportCard";
import { log } from "../../models/Log";
import FontAwesomeIcon from "../common/views/FontAwesomeIcon";
import ServiceModal from "./vehicleModals/serviceModals/ServiceModal";
import ServiceAddModal from "./vehicleModals/serviceModals/ServiceAddModal";
import InspectionAddModal from "./vehicleModals/serviceModals/InspectionAddModal";
import { getTodayDateRange } from "../common/selects/datePickers/date-picker.utils";

import { requestTree, requestTreesCurrentUser } from "../../actions/actionGroups";

import { requestTrips } from "../../actions/actionTrips";

const useStyles = makeStyles((theme) => ({
  cardBar: {
    margin: "0 3px 4px 3px",
  },
}));

const VehicleListPage = (props) => {
  const dispatch = useDispatch();

  const [vehicle, setVehicle] = useState(undefined);
  const [archive, setArchive] = useState(false);
  const tree = useSelector((state) => state.tree);
  const { page, rowsPerPage, changePage, changeRowPerPage } = usePagination();
  const trips = useSelector(state => state.trips.entities) || 0;
  const [isViewClicked, setIsViewClicked] = useState(false);
  const renderCount = useRef(0);
  const { from , to } = getTodayDateRange();

  const {
    open: exportSnackbar,
    show: showExportSnackbar,
    hide: hideExportSnackbar,
  } = useSnackbar();
  const {
    modal: vehicleModal,
    open: openVehicleModal,
    close: closeVehicleModal,
  } = useModalStatus();
  const {
    modal: infoModal,
    open: openInfoModal,
    close: closeInfoModal,
  } = useModalStatus()
  const {
    modal: editModal,
    open: openEditModal,
    close: closeEditModal,
  } = useModalStatus();

  const {
    modal: addModal,
    open: openAddModal,
    close: closeAddModal,
  } = useModalStatus();
  const {
    modal: bulkEditModal,
    open: openBulkEditModal,
    close: closeBulkEditModal,
  } = useModalStatus();
  const {
    modal: filterModal,
    open: openFilterModal,
    close: closeFilterModal,
  } = useModalStatus();
  const {
    modal: histoyModal,
    open: openHistoryModal,
    close: closeHistoryModal,
  } = useModalStatus();
  const {
    modal: archiveModal,
    open: openArchiveModal,
    close: closeArchiveModal,
  } = useModalStatus();
  const {
    modal: bulkArchiveModal,
    open: openBulkArchiveModal,
    close: closeBulkArchiveModal,
  } = useModalStatus();
  const {
    modal: calibrationModal,
    open: openCalibrationModal,
    close: closeCalibrationModal,
  } = useModalStatus();
  const {
    modal: activeVehicleModal,
    open: openActiveVehicleModal,
    close: closeActiveVehicleModal,
  } = useModalStatus();

  const {
    modal: serviceModal,
    open: openServiceModal,
    close: closeServiceModal,
  } = useModalStatus();
  const {
    modal: serviceAddModal,
    open: openServiceAddModal,
    close: closeServiceAddModal,
  } = useModalStatus();
  const {
    modal: inspectionAddModal,
    open: openInspectionAddModal,
    close: closeInspectionAddModal,
  } = useModalStatus();

  const classes = useStyles();

  const {
    me,
    filter,
    selected,
    loading,
    vehicles,
    vehicleReport,
    driveboxReport,
  } = props;
  const { api, initial, updatedAt } = filter;
  const {
    vehicleCount,
    vehicleWithDriveboxCount,
    activeVehicleCount,
    vehicleErrorCount,
  } = vehicleReport;
  const { driveboxCount } = driveboxReport;
  const vehiclesWithNoPosition = vehicleWithDriveboxCount - activeVehicleCount;

  useEffect(() => {
    if (isAuthorized(me, allowedRoles(roles.AdminViewer))) dispatch(requestTree());
    else dispatch(requestTreesCurrentUser());
}, []);

  useEffect(() => {
    requestVehicles();
  }, [updatedAt, archive, page, rowsPerPage]);

  useEffect(() => {
    const { groups, vehicleProfiles, costcenters } = initial;
    const filterParams = [];
    const filterVehicleProfiles =
      typeof vehicleProfiles === "undefined" ||
      vehicleProfiles.map(
        (vpId) => vehicleProfileArray.find((vp) => vp.id === vpId).name
      );
    const costcenterKeys = [];

    if (typeof groups !== "undefined" && groups.length > 0)
      filterParams.push("Gruppen: " + groups.map((g) => g.label).join(", "));

    if (typeof vehicleProfiles !== "undefined" && vehicleProfiles.length > 0)
      filterParams.push("VehicleProfile: " + filterVehicleProfiles.join(", "));

    if (typeof costcenters !== "undefined" && costcenters.length > 0) {
      for (var i = 0; i < props.costcenters.length; i++)
        for (var j = 0; j < costcenters.length; j++)
          if (costcenters[j] == props.costcenters[i].id)
            costcenterKeys.push(props.costcenters[i].foreignId);
      filterParams.push("Kostenstellen: " + costcenterKeys.join(", "));
    }
    props.setTextArray(filterParams);
  }, [updatedAt]);

  useEffect(() => {
    if(isViewClicked) {
      (trips.length > 0 && renderCount.current > 0) ? openVehicleModal() : openInfoModal();
      setIsViewClicked(false);
    }
    renderCount.current++;
  }, [trips]);

  useEffect(() => {
    if (me.canShowTracks && api.from && api.to && vehicle && api.period === periods.day.id) dispatch(requestTracksOfVehicle({...filter.api, vehicleId: vehicle.id}));
  }, [vehicle?.id])

  function requestVehicles() {
    props.requestVehicleReport({ isArchived: archive });
    props.requestDriveboxReport();
    props.requestVehicles({
      ...api,
      isArchived: archive,
      page: page + 1,
      perPage: rowsPerPage,
    });
  }

  function requestVehicleExport() {
    props.requestVehiclesExportXlsx({ ...api, isArchived: archive });
  }

  function handleSearch(searchTerm) {
    props.updateFilterAPICall({ searchTerm }, false);
    changePage(null, 0);
  }

  const toggles = [
    {
      icon: (
        <FontAwesomeIcon
          fontSize={19}
          color={archive ? "secondary" : undefined}
        >
          far fa-car
        </FontAwesomeIcon>
      ),
      translateKey: "vehicle.page.vehicle",
      active: !archive,
      onClick: () => setArchive(false),
    },
    {
      icon: (
        <FontAwesomeIcon
          fontSize={19}
          color={!archive ? "secondary" : undefined}
        >
          far fa-archive
        </FontAwesomeIcon>
      ),
      translateKey: "vehicle.page.archive",
      active: archive,
      onClick: () => setArchive(true),
    },
  ];

  const actionButtons = [];
  const bulkActionButtons = [];

  if (isAuthorized(me, allowedRoles(roles.Admin))) {
    actionButtons.push({
      icon: <FontAwesomeIcon>far fa-plus</FontAwesomeIcon>,
      translateKey: "vehicle.page.add",
      onClick: openAddModal,
    });
  }

  if (isAuthorized(me, allowedRoles(roles.GroupLeaderViewer))) {
    actionButtons.push({
      icon: (
        <FontAwesomeIcon minWidth={26} fontSize={17}>
          far fa-download
        </FontAwesomeIcon>
      ),
      translateKey: "vehicle.page.export",
      onClick: () => {
        showExportSnackbar();
        requestVehicleExport();
      },
    });
  }

  if (isAuthorized(me, allowedRoles(roles.Admin))) {
    bulkActionButtons.push({
      icon: <FontAwesomeIcon>far fa-pen</FontAwesomeIcon>,
      translateKey: "vehicle.page.bulkedit",
      onClick: openBulkEditModal,
    });
  }

  if (isAuthorized(me, allowedRoles(roles.Admin))) {
    bulkActionButtons.push({
      icon: <FontAwesomeIcon>far fa-archive</FontAwesomeIcon>,
      translateKey: archive
        ? "vehicle.page.bulkunarchive"
        : "vehicle.page.bulkarchive",
      onClick: () =>
        archive
          ? dispatch(
              requestVehiclesToggleArchive({
                ids: vehicles.filter((v) => v.isSelected).map((v) => v.id),
              })
            )
          : openBulkArchiveModal(),
    });
  }

  const toolbar = (
    <Toolbar
      toggles={toggles}
      actionButtons={actionButtons}
      bulkActionButtons={bulkActionButtons}
      onSearch={handleSearch}
      onFilter={openFilterModal}
      numberSelected={selected}
      onDeselect={props.deselectAllVehicle}
    />
  );

  const infoModalContents = [
    <Box className={classes.container}>
      <Typography
        variant="body1"
        component="p"
        className={classes.defaultSpacing}
      >
        <Translate id="vehicle.page.infoModal" />
      </Typography>
    </Box>,
  ];

  const noEntryFound =
    vehicles.length === 0 && Object.keys(filter).length === 0 && !archive;
  return (
    <Page
      loading={loading}
      noEntryFound={noEntryFound}
      onAdd={openAddModal}
      activePage={menu.vehicles.key}
      toolbar={toolbar}
    >
      <FilterToolbar />

      <Grid container spacing={0} className={classes.cardBar}>
        {!archive && (
          <Grid item sm={4} xs={12}>
            <VehicleReportCard
              title={
                <Translate
                  id="vehicle.page.report.driveboxCount"
                  data={{
                    driveboxCount: vehicleWithDriveboxCount,
                    vehicleCount: vehicleCount,
                  }}
                />
              }
              value={
                <Translate
                  id="vehicle.page.report.restDriveboxes"
                  data={{
                    count: driveboxCount - vehicleWithDriveboxCount,
                  }}
                />
              }
              loading={
                typeof vehicleCount === "undefined" ||
                typeof driveboxCount === "undefined"
              }
            />
          </Grid>
        )}
        {!archive && (
          <Grid item sm={4} xs={12}>
            <VehicleReportCard
              title={<Translate id="vehicle.page.report.sendingVehicles" />}
              logType={vehiclesWithNoPosition === 0 ? log.SUCCESS : log.ERROR}
              loading={isNaN(vehiclesWithNoPosition)}
              onClick={
                vehiclesWithNoPosition === 0
                  ? undefined
                  : openActiveVehicleModal
              }
              value={
                vehiclesWithNoPosition === 0 ? (
                  <Translate id="vehicle.page.report.correctSendingVehicles" />
                ) : vehiclesWithNoPosition === 1 ? (
                  <Translate id="vehicle.page.report.notCorrectSendingVehicle" />
                ) : (
                  <Translate
                    id="vehicle.page.report.notCorrectSendingVehicles"
                    data={{ count: vehiclesWithNoPosition }}
                  />
                )
              }
            />
          </Grid>
        )}
        {!archive && (
          <Grid item sm={4} xs={12}>
            <VehicleReportCard
              title={<Translate id="vehicle.page.report.vehicleError" />}
              logType={vehicleErrorCount === 0 ? log.SUCCESS : log.ERROR}
              loading={typeof vehicleErrorCount === "undefined"}
              value={
                vehicleErrorCount === 0 ? (
                  <Translate id="vehicle.page.report.vehicleNoError" />
                ) : (
                  <Translate
                    id="vehicle.page.report.vehicleErrorCount"
                    data={{ count: vehicleErrorCount }}
                  />
                )
              }
            />
          </Grid>
        )}
      </Grid>

      <VehicleTable
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={changePage}
        handleChangeRowsPerPage={changeRowPerPage}
        onView={(vehicle) => {
          setVehicle(vehicle);
          getTodayDateRange();
          props.requestTrips({
            from,
            to,
            vehicleIds: [vehicle.id],
            FilterOptions: 0,
          });
          setIsViewClicked(true);
        }} 
        onEdit={(vehicle) => {
          setVehicle(vehicle);
          openEditModal();
        }}
        onHistory={(vehicle) => {
          setVehicle(vehicle);
          openHistoryModal();
        }}
        onSelect={props.selectVehicle}
        onSelectAll={props.selectAllVehicles}
        onArchive={(vehicle) => {
          setVehicle(vehicle);
          openArchiveModal();
        }}
        onUnArchive={(vehicle) => {
          props.requestVehicleToggleArchive({ id: vehicle.id });
        }}
        onCalibrate={(vehicle) => {
          setVehicle(vehicle);
          openCalibrationModal();
        }}
      />

      {vehicleModal && (
        <VehicleModal
          id={vehicle.id}
          onClose={closeVehicleModal}
          trips={trips}
          onService={openServiceModal}
          onCalibrate={openCalibrationModal}
          onEdit={openEditModal}
        />
      )}

      {infoModal && (
        <CustomModal
        onClose={closeInfoModal}
        title={<Translate id="reservation.addReservationModal.pageOne.noAvailableVehicleTitle" />}
        contents={infoModalContents}
        action={modalActions.ok} />
      )}

      {editModal && (
        <VehilceEditModal tree={tree} vehicle={vehicle} setVehicle={setVehicle} onClose={closeEditModal} />
      )}

      {bulkEditModal && <VehicleBulkEditModal onClose={closeBulkEditModal} />}
      {addModal && <VehicleAddModal onClose={closeAddModal} />}
      {filterModal && <FilterModal onClose={closeFilterModal} />}
      {histoyModal && (
        <HistoryModal id={vehicle.id} onClose={closeHistoryModal} />
      )}
      {calibrationModal && (
        <CalibrationModal vehicle={vehicle} onClose={closeCalibrationModal} />
      )}
      {archiveModal && (
        <ArchiveModal
          title="Vehicle"
          onClose={closeArchiveModal}
          onArchive={(comment) => {
            props.requestVehicleToggleArchive({ id: vehicle.id, comment });
          }}
        />
      )}
      {bulkArchiveModal && (
        <ArchiveModal
          title="Vehicle"
          onClose={closeBulkArchiveModal}
          onArchive={(comment) => {
            dispatch(
              requestVehiclesToggleArchive({
                ids: vehicles.filter((v) => v.isSelected).map((v) => v.id),
                comment,
              })
            );
          }}
        />
      )}
      {activeVehicleModal && (
        <ActiveVehicleModal onClose={closeActiveVehicleModal} />
      )}
      <Snackbar
        translateKey="vehicle.page.snackbarExport"
        onClose={hideExportSnackbar}
        open={exportSnackbar}
      />
      {serviceModal && (
        <ServiceModal
          onClose={closeServiceModal}
          vehicleId={vehicle.id}
          onAddInspection={openInspectionAddModal}
          onAddService={openServiceAddModal}
        />
      )}

      {serviceAddModal && <ServiceAddModal onClose={closeServiceAddModal} />}
      {inspectionAddModal && (
        <InspectionAddModal onClose={closeInspectionAddModal} />
      )}
    </Page>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setTextArray,
      updateFilterAPICall,
      selectVehicle,
      selectAllVehicles,
      deselectAllVehicle,
      requestVehicles,
      requestVehicleToggleArchive,
      requestVehiclesExportXlsx,
      requestVehicleReport,
      requestDriveboxReport,
      requestTrips,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    loading: state.vehicles.pageLoading,
    vehicles: state.vehicles.entities,
    selected: state.vehicles.selected,
    compactMode: state.compactMode,
    me: state.users.me,
    filter: state.filter,
    costcenters: state.costcenters.entities,
    vehicleReport: state.reports.vehicle,
    driveboxReport: state.reports.drivebox,
  };
}

// VehicleListPage.propTypes = {};

export default connect(mapStateToProp, mapDispatchToProps)(VehicleListPage);
