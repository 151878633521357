import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// MUI
import {ThemeProvider} from "@material-ui/core/styles"
import theme from "./styling/theme";

//css
import "antd/dist/antd.css";
import "./styling/globalStyles.css";
import "./styling/fonts.css";
import "./styling/icons.css";
import "./styling/GeoSearch.css";
import "./styling/HelpTooltip.css";
import "./styling/HistoryTooltip.css";
import "./styling/index.css";
import "./styling/MapTooltip.css";
import "./styling/MaterialBackdrop.css";
import "./styling/MetadataDialog.css";
import "./styling/MetadataSearch.css";
import "./styling/RoutingToolBar.css";
import "./styling/leafletFullscreen.css";
import "react-dates/lib/css/_datepicker.css";
import "./fontawesome/css/all.css";

//date init
import "react-dates/initialize";

//redux
import {Provider} from "react-redux";
import {LocalizeProvider} from "react-localize-redux";

//Picker
import {LocalizationProvider} from '@material-ui/pickers';
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";

import {getDateLocale} from "./translations/customTranslation";

import { store } from "./store";



ReactDOM.render(
    <Provider store={store}>
        <LocalizeProvider store={store}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={DateFnsAdapter} locale={getDateLocale()}>
                    <App/>
                </LocalizationProvider>
            </ThemeProvider>
        </LocalizeProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
