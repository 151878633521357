import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import EditModal from "../../common/modals/CustomModal";
import VehicleCalibrationHistoryContent from "./VehicleCalibrationHistoryContent";
import {Grid, makeStyles, Typography} from "@material-ui/core";
import InputField from "../../common/inputs/InputField";

import {requestAddCalibration} from "../../../actions/actionVehicle";
import {requestTrips} from "../../../actions/actionTrips";
import {clearError, setError} from "../../../actions/actionCommon";
import {Translate} from "react-localize-redux";
import CustomDateTimePicker from "../../common/selects/datePickers/CustomDateTimePicker";

const useStyles = makeStyles({
    textInformation: {
        marginTop: "9px",
    },
});

const VehicleCalibrationModal = props => {
    const classes = useStyles();

    const [date, setDate] = useState(new Date());
    const [newMileage, setNewMileage] = useState(0);
    const [canCalibrate, setCanCalibrate] = useState(true);
    const [isValidated, setIsValidated] = useState(false);

    const {onClose, vehicle, trips, setError, clearError} = props;


    useEffect(() => {
        if (trips.length === 0) {
            setCanCalibrate(true);
            clearError();
        } else {
            const trip = trips[0]; //get the first Trip

            if (trip.lockedAt) {
                setError({message: "Callibration is in a locked trip"});
                setCanCalibrate(false);
                return;
            }

            if (trip.agent != 0) {
                setError({message: "Callibration is in a custom trip"});
                setCanCalibrate(false);
                return;
            }
            setError({message: "Callibration is in a trip, trip will be splitted in a callibration"});
            setCanCalibrate(true);
        }

    }, [trips]);

    useEffect(() => {
        requestTrip();
    }, [date]);

    const handleCalibrate = () => {
        if (newMileage > 0) {
            props.requestAddCalibration({
                vehicleId: vehicle.id,
                calibratedAt: date,
                mileageInMeters: newMileage * 1000
            });
            onClose();
        }

    };


    const requestTrip = () => {
        if (date) {
            props.requestTrips({
                vehicleIds: [vehicle.id],
                within: date.toISOString(),
            });
        }
    };

    const calibrationContent = (
        <Grid container>
            <Grid item xs={6}>
                <Typography className={classes.textInformation}><Translate
                    id="vehicle.calibrationModal.currentMileage"/></Typography>
            </Grid>
            <Grid item xs={6}>
                <InputField startAdornment="Kmd" value={(vehicle.mileageInMeters / 1000 || 0)} disabled/>
            </Grid>

            <Grid item xs={6}>
                <Typography className={classes.textInformation}><Translate
                    id="vehicle.calibrationModal.newMileage"/></Typography>
            </Grid>
            <Grid item xs={6}>
                <InputField startAdornment="Km" isRequired onChange={e => {
                    if (e.target.value > 0) {
                        setIsValidated(true);
                        setNewMileage(e.target.value)
                    }
                }} type = "number"
                            value={newMileage} error={!isValidated} helperText={"Neuer kilometerstand muss gesetzt sein"} />
            </Grid>
            <Grid item xs={6}>
                <Typography className={classes.textInformation}><Translate
                    id="vehicle.calibrationModal.date"/></Typography>
            </Grid>
            <Grid item xs={6}>
                <CustomDateTimePicker variant={"standard"} onChange={setDate} value={date}/>
            </Grid>
        </Grid>
    );

    const tabs = [
        <Translate id="vehicle.calibrationModal.calibration"/>,
        <Translate id="vehicle.calibrationModal.history"/>
    ];
    const contents = [calibrationContent, <VehicleCalibrationHistoryContent id={vehicle.id}/>];

    return (
        <EditModal
            action={canCalibrate ? "confirm" : "cancel"}
            onClose={onClose}
            onSave={handleCalibrate}
            title={vehicle.name}
            tabs={tabs}

            contents={contents}
        />
    );

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({requestTrips, requestAddCalibration, setError, clearError}, dispatch);
}

function mapStateToProp(state) {
    return {
        trips: state.trips.entities,
    };
}

// VehicleCalibrationModal.propTypes = {
//     vehicle: PropTypes.string.isRequired,
// };

export default connect(
    mapStateToProp,
    mapDispatchToProps
)(VehicleCalibrationModal);
