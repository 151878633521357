import React, {Component} from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import AddModal from "../../common/modals/AddModal";
import {requestAddGroup} from "../../../actions/actionGroups";
import {Translate} from "react-localize-redux";
import InputField from "../../common/inputs/InputField";

class AddNodeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nodeName: "",
        };
    }

    handleChange = event => {
        this.setState({nodeName: event.target.value});
    };

    handleAddNode = () => {
        const {group, onClose} = this.props;
        const {nodeName} = this.state;
        this.props.requestAddGroup({
            name: nodeName,
            parentId: group.id,
        });
        onClose();
    };

    render() {
        const {onClose} = this.props;
        const {nodeName} = this.state;

        return (
            <AddModal title={<Translate id="group.addGroupModal.title"/>}  onAdd={e => this.handleAddNode()} onClose={onClose}>
                <InputField autoFocus label={<Translate id="group.addGroupModal.nameOfGroup"/>} value={nodeName} onChange={this.handleChange}/>
            </AddModal>
        );
    }
}

// AddNodeModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestAddGroup,
        },
        dispatch
    );
}

function mapStateToProp(state) {
    return {group: state.group};
}

export default connect(
    mapStateToProp,
    mapDispatchToProps
)(AddNodeModal);
