import React, { useEffect, useState, useReducer, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "antd";

import WizardModal from "../../common/modals/wizard-modal/WizardModal";
import Box from "@material-ui/core/Box";

import {
  requestAvailableDrivers,
  requestAvailableVehicles,
  requestAddReservation,
  requestUpdateReservations,
} from "../../../actions/actionReservation";

import ReservationAddModalStepOne from "./steps/ReservationAddModalStepOne";
import ReservationAddModalStepTwo from "./steps/ReservationAddModalStepTwo";
import ReservationAddModalStepThreeAdmin from "./steps/ReservationAddModalStepThreeAdmin";
import ReservationAddModalStepThreeDriver from "./steps/ReservationAddModalStepThreeDriver";

import { roles } from "../../../models/Role";
import { packages } from "../../../models/Package";
import { isAuthorized, allowedRoles } from "../../../managers/authManager";

import {
  getInitialState,
  reservationAddModalReducer,
} from "./reservationAddModal.reducer";
import {
  setInputValue,
  setNewReservation,
  setIsReservationChanged,
  setIsNextButtonTouched,
} from "./reservationAddModal.action";

import { Typography } from "antd";
import { useWizardSteps } from "../../../hooks/useModalStatus";
import { Translate } from "react-localize-redux";
import { WIZARD_TYPES } from "../../common/modals/wizard-modal/WizardModal";
import {
  reservationAddModalStyles,
  createNewValuesObject,
  areObjectsEqual,
  propertiesToCompare,
  getDynamicStepTitle,
} from "./ReservationAddModal.utils";
import { isHigherThanDriverLeader } from "../../../models/Role";
import CustomModal, { modalActions } from "../../common/modals/CustomModal";
import { makeStyles, createStyles } from "@material-ui/core";
import { useModalStatus } from "../../../hooks/useModalStatus";
import { useValidateForm } from "../../../hooks/useValidateForm";
import { useCustomValidation } from "../../../hooks/useDatePickersValidation";

const useStyles = makeStyles((theme) =>
  createStyles(reservationAddModalStyles(theme))
);

const ReservationAddModal = ({ onClose, reservation, allowEdit }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { step, nextStep, prevStep } = useWizardSteps();
  const me = useSelector((state) => state.users.me);
  const availableVehicles = useSelector(
    (state) => state.vehicles.reservation.entities
  );
  const INITIAL_STATE = getInitialState(reservation);

  const [reservationAddState, reservationAddDispatch] = useReducer(
    reservationAddModalReducer,
    INITIAL_STATE
  );

  const { submittable } = useValidateForm(form);
  const { customRule, isValidated, setIsValidated } = useCustomValidation();

  useEffect(() => {
    if (reservation) {
      const {
        pickupDate,
        pickupAddress,
        returnDate,
        returnAddress,
        vehicle,
        costcenter,
        driver,
        purpose,
        comment,
        stopovers,
      } = reservation;
      form.setFieldsValue({
        pickupDate,
        pickupAddress,
        returnDate,
        returnAddress,
        purpose,
        comment,
        vehicleId: vehicle.id,
        driverId: driver.id,
        costcenterId: costcenter?.id,
      });
      stopovers.forEach((stopover, idx) => {
        form.setFieldsValue({ [`stopover${idx + 1}`]: stopover });
      });
    }
  }, []);

  const {
    driverId,
    pickupDate,
    returnDate,
    pickupAddress,
    returnAddress,
    stopoverValues,
    numOfStopovers,
    purpose,
    comment,
    vehicleId,
    newReservation,
    costcenterId,
    isNextButtonTouched,
    isReservationChanged,
  } = reservationAddState;

  const {
    modal: warningModal,
    open: openWarningModal,
    close: closeWarningModal,
  } = useModalStatus();

  const handleInputChange = useCallback(
    (name, value) => {
      if (name === "driverId") {
        value &&
          reservationAddDispatch(setInputValue({ name: "driverId", value }));
        value && form.setFieldsValue({ driverId: value });
      } else {
        reservationAddDispatch(setInputValue({ name, value }));
        form.setFieldsValue({ [name]: value });
      }
    },
    [reservationAddDispatch]
  );

  const handleTextAreaChange = (e) => {
    const { name, value } = e.target;

    reservationAddDispatch(setInputValue({ name, value }));
    form.setFieldsValue({ [name]: value });
  };

  useEffect(() => {
    returnDate && new Date(pickupDate) > new Date(returnDate)
      ? setIsValidated(false)
      : setIsValidated(true);
  }, [pickupDate, returnDate]);

  useEffect(() => {
    if (isNextButtonTouched) {
      availableVehicles.length ? nextStep() : openWarningModal();
    }

    reservationAddDispatch(setIsNextButtonTouched(false));
  }, [availableVehicles]);

  useEffect(() => {
    const currentDriverId = isHigherThanDriverLeader(me) ? driverId : me.id;
    currentDriverId && form.setFieldValue({ driverId: currentDriverId });
  }, [driverId]);

  useEffect(() => {
    if (step === 2 && isHigherThanDriverLeader(me)) {
      if (reservation) {
        dispatch(
          requestAvailableDrivers({
            from: new Date(pickupDate).toISOString(),
            to: new Date(returnDate).toISOString(),
            reservationId: reservation.id,
          })
        );
      } else {
        dispatch(
          requestAvailableDrivers({
            from: new Date(pickupDate).toISOString(),
            to: new Date(returnDate).toISOString(),
          })
        );
      }
    }

    if (reservation && step === 3) {
      const { vehicle, driver, costcenter } = reservation;
      !areObjectsEqual(
        {
          ...reservation,
          vehicleId: vehicle.id,
          driverId: driver.id,
          costcenterId: costcenter && costcenter.id,
        },
        newReservation,
        propertiesToCompare
      )
        ? reservationAddDispatch(setIsReservationChanged(true))
        : reservationAddDispatch(setIsReservationChanged(false));
    }

    if (step === 4) {
      if (reservation) {
        dispatch(
          requestUpdateReservations(
            isHigherThanDriverLeader(me)
              ? { ...newReservation, id: reservation.id }
              : { ...newReservation, id: reservation.id, driverId: me.id }
          )
        );
      } else {
        dispatch(
          requestAddReservation(
            isHigherThanDriverLeader(me)
              ? newReservation
              : { ...newReservation, driverId: me.id }
          )
        );
      }

      onClose();
    }
  }, [step]);

  const handleRequestAvailableVehicles = () => {
    if (reservation) {
      dispatch(
        requestAvailableVehicles({
          from: new Date(pickupDate).toISOString(),
          to: new Date(returnDate).toISOString(),
          reservationId: reservation.id,
        })
      );
    } else {
      dispatch(
        requestAvailableVehicles({
          from: new Date(pickupDate).toISOString(),
          to: new Date(returnDate).toISOString(),
        })
      );
    }
  };

  function handleNext(values) {
    let newValues = {};
    if (step === 0) {
      reservationAddDispatch(setIsNextButtonTouched(true));
      handleRequestAvailableVehicles();
      newValues = createNewValuesObject(values);
      reservationAddDispatch(
        setNewReservation({ ...newReservation, ...newValues })
      );
    } else {
      reservationAddDispatch(
        setNewReservation({ ...newReservation, ...values })
      );
      nextStep();
    }
  }

  const canBack = () => true;

  const renderSteps = (step) => {
    const hasPermission = isAuthorized(me, allowedRoles(roles.Admin), [
      packages.CostCenter,
    ]);

    const commonStepThreeProps = {
      purpose,
      comment,
      handleInputChange,
      handleTextAreaChange,
      costcenterId,
    };

    switch (step) {
      case 0:
        return (
          <ReservationAddModalStepOne
            reservation={reservation}
            pickupDate={pickupDate}
            returnDate={returnDate}
            pickupAddress={pickupAddress}
            returnAddress={returnAddress}
            stopoverValues={stopoverValues}
            numOfStopovers={numOfStopovers}
            isValidated={isValidated}
            reservationAddDispatch={reservationAddDispatch}
            handleInputChange={handleInputChange}
            customRule={customRule}
            form={form}
          />
        );

      case 1:
        return (
          <ReservationAddModalStepTwo
            vehicleId={vehicleId}
            handleInputChange={handleInputChange}
          />
        );
      case 2:
        return isHigherThanDriverLeader(me) ? (
          <ReservationAddModalStepThreeAdmin
            {...commonStepThreeProps}
            driverId={driverId}
            isAuthorised={hasPermission}
          />
        ) : (
          <ReservationAddModalStepThreeDriver
            {...commonStepThreeProps}
            driverId={me.id}
          />
        );
      case 3:
        return;
    }
  };

  // this should move to a component
  const cantEditContent = [
    <div className={classes.container}>
      <p className={classes.defaultSpacing}>
        <Translate id="reservation.addReservationModal.cantEditMessage" />
      </p>
    </div>,
  ];

  // this should move to a component
  const warningModalContents = [
    <Box className={classes.container}>
      <Typography
        variant="body1"
        component="p"
        className={classes.defaultSpacing}
      >
        <Translate id="reservation.addReservationModal.pageOne.noAvailableVehicleContent" />
      </Typography>
    </Box>,
  ];

  return (
    <>
      {allowEdit ? (
        <WizardModal
          onClose={onClose}
          form={form}
          active
          canBack={canBack}
          onNext={handleNext}
          submittable={submittable}
          renderSteps={renderSteps}
          getPageTitle={getDynamicStepTitle}
          onBack={prevStep}
          classList={{ root: classes.root, content: classes.content }}
          isReservationChanged={isReservationChanged}
          step={step}
          wizardType={WIZARD_TYPES.reservation.name}
          reservationAddState={reservationAddState}
          stopoverValues={stopoverValues}
          title={
            <Translate
              id="reservation.addReservationModal.title"
              data={{
                typeInEnglish: reservation ? "Edit" : "Add",
                typeInGerman: reservation ? "bearbeiten" : "hinzufügen",
              }}
            />
          }
        />
      ) : (
        <CustomModal
          onClose={onClose}
          title={
            <Translate id="reservation.addReservationModal.canEditModalTitle" />
          }
          contents={[cantEditContent]}
          action={modalActions.ok}
        />
      )}
      {warningModal && (
        <CustomModal
          onClose={closeWarningModal}
          title={
            <Translate id="reservation.addReservationModal.pageOne.noAvailableVehicleTitle" />
          }
          contents={warningModalContents}
          action={modalActions.ok}
        />
      )}
    </>
  );
};

export default ReservationAddModal;
