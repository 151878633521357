import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import { RequestVehicleInfoById, ReceiveVehicleInfoById } from "../types/actions/actionDriverIdentification";

export enum DRIVER_IDENTIFICATION_TYPES {
  REQUEST_VEHICLE_INFO_BY_ID = "REQUEST_VEHICLE_INFO_BY_ID",
  RECEIVE_VEHICLE_INFO_BY_ID = "RECEIVE_VEHICLE_INFO_BY_ID",
}

// Type "any" needs to change to a more specific type later
export const requestVehicleInfoById = withMatcher(
    (data: string): RequestVehicleInfoById => {
      return createAction(DRIVER_IDENTIFICATION_TYPES.REQUEST_VEHICLE_INFO_BY_ID, data);
    }
  );

export const receiveVehicleInfoById = withMatcher(
  (data: any): ReceiveVehicleInfoById => {
    return createAction(DRIVER_IDENTIFICATION_TYPES.RECEIVE_VEHICLE_INFO_BY_ID, data);
  }
);




