import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {Input, Switch} from "antd";
import {Translate} from "react-localize-redux";
import Label from "../../common/views/Label";
import TreeSelect from "../../common/selects/specificSelects/TreeSelect";
import RoleSelect from "../../common/selects/specificSelects/RoleSelect";
import CostCenterSelect from "../../common/selects/specificSelects/CostCenterSelect"
import FormModal from "../../common/modals/FormModal";
import { requestUpdateCostcenter, requestCostcenterById} from "../../../actions/actionCostcenter";
import FormBuilder from "../../../managers/formBuilder";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";

const getDefaultInputValue = (costcenter) => ({
    foreignId: costcenter?.foreignId,
    description: costcenter?.description,
});

const CostcenterModal = props => {
    const {onClose, costcenter, tree, loading, archive, id, requestUpdateCostcenter, requestCostcenterById} = props;
    const { canCalibrate, archiveComment } = costcenter;
    const { handleInputChange, fields } = useInputChangeHandler(getDefaultInputValue(costcenter));

    const { foreignId, description } = fields;


    function handleOnSave() {
        requestUpdateCostcenter({ ...fields, id: costcenter.id });
        onClose();
    }

    const inputs = [
        new FormBuilder.FormInput(<Translate id="costCenter.editmodal.foreignKey" />, <Input value={foreignId} onChange={(e) => handleInputChange("foreignId", e.target.value)} />)
            .withValue(foreignId)
            .withKey("foreignId")
            .withValidationMessage(<Translate id="costCenter.addmodal.page.idValidation" />)
            .isRequired()
            .build(),
        new FormBuilder.FormInput(<Translate id="costCenter.editmodal.description" />, <Input value={description} onChange={(e) => handleInputChange("description", e.target.value)} />)
            .withValue(description)
            .withKey("description")
            .build(),
    ];

    const archiveInputs = [
        new FormBuilder.FormInput(<Translate id="costCenter.editmodal.foreignKey"/>, <Label/>)
            .withValue(foreignId)
            .withValidationMessage(<Translate id="costCenter.addmodal.page.idValidation" />)
            .isRequired()
            .build(),
        new FormBuilder.FormInput(<Translate id="costCenter.editmodal.description"/>, <Label
            autoSize={{minRows: 3, maxRows: 5}}/>).withValue(description).build(),
    ];

    const title = (
        <>
            <Translate id="costCenter.editmodal.title" />
        </>
    );

    return (
        <FormModal
            loading={loading}
            action={archive ? "ok" : "update"}
            onSave={handleOnSave}
            onClose={onClose}
            title={title}
            inputs={archive ? archiveInputs : inputs}
        />
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestCostcenterById,
            requestUpdateCostcenter,
        },
        dispatch,
    );
}

function mapStateToProp(state) {
    return {
        loading: state.costcenters.loading,
        tree: state.tree,
    };
}

// CostcenterModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     id: PropTypes.string.isRequired,
//     archive: PropTypes.bool,
// };

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(CostcenterModal);
