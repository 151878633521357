import React from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { isViewer } from "../../../managers/authManager";
import { useSelector } from "react-redux";

const TableContextMenu = ({
  anchorEl,
  onOpen,
  onRename,
  onMove,
  onRemove,
  onClose,
  disableOpen,
}) => {
  const me = useSelector((state) => state.users.me);
  const viewer = isViewer(me);

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {onOpen && !disableOpen && (
        <MenuItem onClick={onOpen}>
          <Translate id="group.contextmenu.open" />
        </MenuItem>
      )}

      {!viewer && (
        <MenuItem
          onClick={() => {
            onClose();
            onRename();
          }}
        >
          <Translate id="group.contextmenu.rename" />
        </MenuItem>
      )}
      {!viewer && (
        <MenuItem
          onClick={() => {
            onClose();
            onMove();
          }}
        >
          <Translate id="group.contextmenu.move" />
        </MenuItem>
      )}

      {!viewer && (
        <MenuItem onClick={onRemove}>
          <Translate id="group.contextmenu.remove" />
        </MenuItem>
      )}
    </Menu>
  );
};

// TableContextMenu.propTypes = {
//   anchorEl: PropTypes.object,
//   onOpen: PropTypes.func,
//   onRename: PropTypes.func.isRequired,
//   onMove: PropTypes.func.isRequired,
//   onRemove: PropTypes.func.isRequired,
//   onClose: PropTypes.func.isRequired,
//   disableOpen: PropTypes.bool,
// };

export default TableContextMenu;
