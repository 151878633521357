import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { bindActionCreators } from "redux";
import { Select } from "antd";

import CustomSelect from "../Select";
import { requestUsers, requestUserById } from "../../../../actions/actionUser";
import MultiSelect from "../MultiSelect";
import { getIdsFromGroupValues } from "../../../../managers/groupManager";
import { allowedRoles, isAuthorized } from "../../../../managers/authManager";
import { roles } from "../../../../models/Role";
import { maxRowsPerPage, usePagination } from "../../../../hooks/usePagination";

const { Option } = Select;
const UserSelect = (props) => {
  const availableDrivers = useSelector(state => state.users.reservation.entities);
  const [isPreviousDriverAvailable, setIsPreviousDriverAvailable] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const { page, rowsPerPage } = usePagination(maxRowsPerPage);

  const {
    me,
    filter,
    tree,
    onChange,
    users,
    value,
    disabled,
    multiple,
    allowClear,
    showArchived,
    reservationModal,
    driverId,
  } = props;

  useEffect(() => {
    if (value && isAuthorized(me, allowedRoles(roles.GroupLeaderViewer)) && !multiple)
      props.requestUserById(value);
  }, [value]);

  useEffect(() => {
    if(reservationModal) { 
      setIsPreviousDriverAvailable(true);
    }
  }, [driverId])

  useEffect(() => {
    if (multiple) setSearchTerm("");
  }, [value]);

  useEffect(() => {
    if(reservationModal) {
      setIsPreviousDriverAvailable(Boolean(availableDrivers.find((availableDriver) => availableDriver.id === driverId)));
    }
  }, [availableDrivers]);

  useEffect(() => {
    reservationModal && !isPreviousDriverAvailable && (onChange(null));

 }, [isPreviousDriverAvailable]);

  useEffect(() => {
    if(!reservationModal) {
      fetchRequestedUsers();
    }
  }, [filter, searchTerm, page, rowsPerPage]);

  function fetchRequestedUsers() {
    if (isAuthorized(me, allowedRoles(roles.GroupLeaderViewer))) {
      const groups = filter.groups;
      const filterValueGroup = groups
        ? { ...filter, groupIds: getIdsFromGroupValues(tree, groups) }
        : { ...filter };
      const filterValueGroupSearchTerm = {
        ...filterValueGroup,
        searchTerm,
        IsArchived: showArchived,
        page: page + 1,
        perPage: rowsPerPage,
      }
      props.requestUsers(filterValueGroupSearchTerm);
    }
  }

  function handleSearch(value) {
    setSearchTerm(value);
  }

  const dynamicUsers = reservationModal ? availableDrivers : users;

  const computedUser = dynamicUsers.length && dynamicUsers.map((u) => {
    u.name = u.firstName + " " + u.lastName;
    return u;
  });


  // if (!isAuthorized(me, allowedRoles(roles.GroupLeader))) {
  //   return value ? value : "";
  // }

  if (multiple)
    return (
      <MultiSelect
        placeholder={<Translate id="common.select.users" />}
        selectedArray={value}
        array={computedUser}
        handleChange={onChange}
        disabled={disabled}
        handleSearch={setSearchTerm}
      />
    );

    const userSelectValue = () => {
      if(isPreviousDriverAvailable) {
        if(driverId) return driverId
        else if(value) return value
      }
      return null;
    }

  return (
    <CustomSelect
      value={userSelectValue()}
      placeholder={<Translate id="common.select.user" />}
      handleChange={onChange}
      handleSearch={handleSearch}
      disabled={disabled}
      allowClear={allowClear}
    >
      {computedUser && computedUser.map((user) => (
          <Option key={user.id} value={user.id}>
            {user.name}
          </Option>
      ))}
    </CustomSelect>
  );
};

UserSelect.defaultProps = {
  disabled: false,
  multiple: false,
  filter: {},
  allowClear: true,
  showArchived: false,
};

// UserSelect.propTypes = {
//   multiple: PropTypes.bool,
//   onChange: PropTypes.func.isRequired,
//   value: PropTypes.string.isRequired,
//   disabled: PropTypes.bool,
//   filter: PropTypes.object,
//   placeholder: PropTypes.string,
//   allowClear: PropTypes.bool,
//   showArchived: false,
// };

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ requestUsers, requestUserById }, dispatch);
}

function mapStateToProp(state) {
  return {
    users: state.users.entities,
    tree: state.tree,
    me: state.users.me,
  };
}

export default connect(mapStateToProp, mapDispatchToProps)(UserSelect);