import React from "react";
import { Translate } from "react-localize-redux";
import Grid from "@material-ui/core/Grid";
import SearchField from "./ToolbarSearch";
import className from "classnames";

import {
  Button,
  ButtonGroup,
  Card,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import FilterButton from "../buttons/FilterButton";

import RemoveIcon from "@material-ui/icons/RemoveCircle";
import FontAwesomeIcon from "../views/FontAwesomeIcon";
import FilterIcon from "../../../icons/FilterIcon";
import { customColors } from "../../geofences/generic/StylingOptions";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: theme.spacing(1),
    marginBottom: "4px",
    padding: "10px 20px",
    backgroundColor: "white",
    display: "flex",
    alignItems: "left",
    justifyContent: "flex-start",
    "flex-wrap": "wrap",
  },
  divider: {
    borderRight: "1px solid rgba(0, 0, 0, 0.54) ",
  },
  togglesContainer: {
    marginRight: "1rem",
  },
  toggleIcon: {
    margin: "2px 4px",
  },
  icon: {
    fontSize: "small",
  },
  buttonIcon: {},
  leftIcon: {
    marginRight: "2px",
  },
  space: {
    flexGrow: "1",
    display: "flex",
    justifyContent: "center"
  },
  activeIcon: {
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: 20,
    marginRight: "1rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  clearIcon: {
    marginLeft: "1px",
  },
  controls: {
    width: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "border-color": "black",
    marginTop: "4px",
    marginLeft: "2px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  filterButton: {
    marginTop: "3px",
  },
  button: {
    width: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  toolbarend: {
    display: "flex",
  },
  childrenWrapper:{
    paddingLeft: 5,
    paddingRight: 5,
    width: "100%"
  },
  filterIconWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginRight: 7,
    marginLeft: 12,
  },
  disableToolBarStyle: {
    backgroundColor: "transparent",
    boxShadow: "none",
    marginLeft: 0,
    paddingLeft: 0,
    marginBottom: 15
  },
  filterText: {
    fontSize: 15,
    margin: 0,
    color: "blue",
    cursor: "pointer",
    textDecoration: "none",
    position: "relative",
    borderBottom: "2px solid blue",
    paddingBottom: "1px"
  },
}));
const CustomToolbar = (props) => {
  const {
    actionButtons,
    bulkActionButtons,
    toggles,
    onSearch,
    onFilter,
    onSetting,
    numberSelected,
    title,
    onDeselect,
    controls,
    children,
    filter,
    searchInput,
    disableToolBarStyle,
    activeFilter,
    filterMessage,
  } = props;
  const classes = useStyles();

  const toolbarContent = (
    <Translate>
      {({ translate }) => (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <div className={classes.togglesContainer}>
            <ButtonGroup size="small" variant="text">
              {toggles.map((toggle, i) => (
                <Tooltip key={i} title={translate(toggle.translateKey)}>
                  <Button
                    color={toggle.active ? "primary" : "default"}
                    key={"toggleBtn#" + i + "#" + toggle.key}
                    onClick={toggle.onClick}
                  >
                    {toggle.icon}
                  </Button>
                </Tooltip>
              ))}
            </ButtonGroup>
          </div>
            <Typography component="div" className={className({ [classes.title]: title })}>
              {title}
            </Typography>
          <div>
            {actionButtons.map((button, i) => {
              if (!button.hide)
                return (
                  <Button
                    style={{ marginRight: "3px" }}
                    variant="outlined"
                    color="secondary"
                    key={"actionBtn#" + i + "#" + button.key}
                    className={classes.button}
                    onClick={button.onClick}
                    startIcon={button.icon}
                  >
                    {translate(button.translateKey)}
                  </Button>
                );
            })}
          </div>
          <div> {onSearch && <SearchField onSearch={onSearch} />}</div>
          <div className={classes.space}>{activeFilter && <p className={classes.filterText} onClick={() => filter()}>{filterMessage}</p>}</div>
          <div className={classes.toolbarend}>
          {searchInput && <SearchField onSearch={searchInput} applyStyle/>}
            {controls &&
              controls.length > 0 &&
              controls.map((c, i) => <div key={i} className={classes.controls}>{c}</div>)
              }

            {onFilter && (
              <div className={classes.filterButton}>
                <FilterButton onClick={onFilter} />
              </div>
            )}
            {filter && (<div onClick={filter} className={classes.filterIconWrapper}> <FilterIcon size={30} color={activeFilter ? customColors.ilogsBlue : "white"} /> </div>)}
            {onSetting && (
              <div>
                <Tooltip title="Setting">
                  <IconButton onClick={onSetting}>
                    <FontAwesomeIcon marginTop={-2} fontSize={17}>
                      far fa-cog
                    </FontAwesomeIcon>
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        </Grid>
      )}
    </Translate>
  );

  // TODO translations
  const bulkEditContent = (
    <Translate>
      {({ translate }) => (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item>
            {bulkActionButtons?.map((button) => {
              if (!button.min || numberSelected >= button.min)
                return (
                  <Button
                    color="secondary"
                    key={button.key}
                    style={{ marginRight: "3px" }}
                    variant="outlined"
                    className={classes.button}
                    onClick={button.onClick}
                    startIcon={button.icon}
                  >
                    {translate(button.translateKey)}
                  </Button>
                );
            })}
          </Grid>
          <Grid className={classes.space} />
          <Grid item>
            {numberSelected} <Translate id="common.toolbar.selected" />{" "}
          </Grid>
          {onDeselect && (
            <Grid item>
              <Tooltip title={"Auswahl aufheben"}>
                <IconButton
                  size="small"
                  aria-label="Deselect"
                  className={classes.clearIcon}
                  onClick={onDeselect}
                >
                  <RemoveIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      )}
    </Translate>
  );

  const marginTop = props.marginTop ? props.marginTop : "50px";
  return (
    <Card className={disableToolBarStyle ? classes.disableToolBarStyle : classes.toolbar} style={{ marginTop: marginTop }}>
      {numberSelected == 0 && toolbarContent}
      {numberSelected != 0 && bulkEditContent}
      <div className={classes.childrenWrapper}>
      {children}
      </div>
    </Card>
  );
};

CustomToolbar.defaultProps = {
  toggles: [],
  actionButtons: [],
  bulkActionButtons: [],
  numberSelected: 0,
};

// CustomToolbar.propTypes = {
//   title: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.object,
//   ]),
//   onSearch: PropTypes.func,
//   onFilter: PropTypes.func,
//   onSetting: PropTypes.func,
//   onDeselect: PropTypes.func,
//   actionButtons: PropTypes.array,
//   bulkActionButtons: PropTypes.array,
//   toggles: PropTypes.array,
//   numberSelected: PropTypes.number,
//   controls: PropTypes.array,
//   filter: PropTypes.func,
//   disableStyle: PropTypes.bool
// };

export default CustomToolbar;
