import React from 'react';
import { useDispatch } from "react-redux";
import Toolbar from '@material-ui/core/Toolbar';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import WeekdayMenu from './WeekdayMenu';
import axios from 'axios';
import { customColors } from '../../generic/StylingOptions';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import HelpTooltip from "./HelpTooltip";
import Popper from '@material-ui/core/Popper';
import PolygonColorMenu from './PolygonColorMenu';
import SearchIcon from '@material-ui/icons/Search';
import { Translate, withLocalize } from 'react-localize-redux';
import PresetDialog from './PresetDialog';
import { compareByReference, objAreEqual } from '../../utils/comparisonUtils';
import { requestGeofenceToggleArchive } from "../../../../actions/actionGeofences";
import ArchiveModal from "../../../common/modals/ArchiveModal";
import { useModalStatus } from "../../../../hooks/useModalStatus";

const baseUrl = window.location.origin + "/api";

function GeoFenceButtonBar(props) {
    let { geoFences, setGeoFences, selection, setSelection, removeFromSelection,
        setPolygonColor, colorPickerOpen, setColorPickerOpen,
        dockSize, searchMode, setSearchMode, getGeoFenceData, setDialog,
        geoFenceLocks, setGeoFenceLocks, addGeoFenceInState, currentUser } = props;

    const [showTooltip, setShowTooltip] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popperKey, setPopperKey] = useState(0);

    const dispatch = useDispatch();

    const {
        modal: archiveModal,
        open: openArchiveModal,
        close: closeArchiveModal,
    } = useModalStatus();

    function showHelp(e) {
        setAnchorEl(e.currentTarget);
        setShowTooltip(!showTooltip);
    }

    function resetAnchor() {
        let anchor = anchorEl;
        setAnchorEl(null);
        setAnchorEl(anchor);
    }

    function lockActionMulti(weekday, lockMethod) {
        let weekdaysToLock = [];
        if (weekday === -1){
            weekdaysToLock = [1, 2, 3, 4, 5, 6, 0];
        }
        else
            weekdaysToLock = [weekday];

        let newGeoFenceLocks = geoFenceLocks;
        for (let currentDayToLock of weekdaysToLock) {
            for (let id of selection) {
                switch (lockMethod) {
                    case 0: lockDay(newGeoFenceLocks, id, currentDayToLock);     break;
                    case 1: unlockDay(newGeoFenceLocks, id, currentDayToLock);   break;
                    case 2: toggleDay(newGeoFenceLocks, id, currentDayToLock);   break;
                    default: return;
                }
                callBackendLocking(id, currentDayToLock, lockMethod);
                // was callBackendLocking(id, weekday, lockMethod);
            }
        }
        setGeoFenceLocks({...newGeoFenceLocks});
    }

    function lockDay(newGeoFenceLocks, id, weekday) {
        if (!newGeoFenceLocks[`id_${id}`])
            newGeoFenceLocks[`id_${id}`] = [];

        if (!newGeoFenceLocks[`id_${id}`].includes(weekday))
            newGeoFenceLocks[`id_${id}`].push(weekday);
    }

    function unlockDay(newGeoFenceLocks, id, weekday) {
        let foundIndex = -2;

        if (newGeoFenceLocks[`id_${id}`])
            foundIndex = newGeoFenceLocks[`id_${id}`].indexOf(weekday);
        
        if (foundIndex > -1) {
            newGeoFenceLocks[`id_${id}`].splice(foundIndex, 1);
        }
    }

    function toggleDay(newGeoFenceLocks, id, weekday) {
        let foundIndex = -2;

        if (newGeoFenceLocks[`id_${id}`])
            foundIndex = newGeoFenceLocks[`id_${id}`].indexOf(weekday);

        if (foundIndex === -2) {
            newGeoFenceLocks[`id_${id}`] = [];
            newGeoFenceLocks[`id_${id}`].push(weekday);
        } else if (foundIndex === -1) {
            newGeoFenceLocks[`id_${id}`].push(weekday);
        } else {
            newGeoFenceLocks[`id_${id}`].splice(foundIndex, 1);
        }
    }

    function callBackendLocking(id, weekday, lockMethod) {
        axios({
            method: 'get',
            url: `${baseUrl}/geoFences/${id}/${weekday}/${lockMethod}`,
            headers: {
                Authorization: currentUser
            }
        }).then(res => {
            
        }).catch(err => {
            console.log(`Could not toggle locking for geofence ${id}`);
        })
    }

    function onArchive() {
        let newGeoFences = geoFences;
        let newSelection = [...selection];

        openArchiveModal();
        return (<ArchiveModal
            title="Archive Geofence"
            onClose={closeArchiveModal}
            description={selection.length > 1
                ? props.translate('dialogs.deleteMultiNotification')
                : props.translate('dialogs.deleteNotification')}
            noComment={true}
            onArchive={
                async () => {
                    for (let id of selection) {
                        try {
                            dispatch(
                                requestGeofenceToggleArchive({
                                    geofenceId: id
                                })
                            )
                            removeFromSelection();
                            let newGeoFences = geoFences;
                            newGeoFences.delete(id);
                            setGeoFences(new Map(newGeoFences));
                        } catch (e) {
                            let geoFenceTitle = geoFences.get(id).Title;
                            alert(props.translate('errors.couldNotToggleArchiveGeofence', { title: geoFenceTitle }));
                            console.log(e);
                        };

                    }

                    setSelection([...newSelection]);
                    setGeoFences(new Map(newGeoFences));
                }
             }
        />);
    }

    function toggleSearchMode() {
        switch (searchMode) {
            case 0: setSearchMode(1); break;
            case 1: setSearchMode(0); break;
            case 2: // if a search has already been made
                getGeoFenceData();
                setSearchMode(0); break;
            default: setSearchMode(0);
        }
    }

    function addGeoFenceInStateWithInvertedLatLngs(newGeoFenceId, newTitle, latlngs, isSystemGeoFence, isNotEditable) {
        for (let coordPair of latlngs[0]) {
            let temp = coordPair[0];
            coordPair[0] = coordPair[1];
            coordPair[1] = temp;
        }
        
        addGeoFenceInState(newGeoFenceId, newTitle, latlngs, isSystemGeoFence, isNotEditable);
    }

    return (
        <div className="buttonBarWrapperDiv" style={{ width: `${dockSize}px` }}>
            <Toolbar>
                <Box display='flex' flexGrow={1}>
                    <WeekdayMenu
                        Icon={LockIcon}
                        tooltip={<Translate id="buttonBar.lock"></Translate>}
                        action={lockActionMulti}
                        lockMethod={0} // lock
                        isDisabled={selection.length <= 0}
                        leftMost={true}
                    ></WeekdayMenu>
                    <WeekdayMenu
                        Icon={LockOpenIcon}
                        tooltip={<Translate id="buttonBar.unlock"></Translate>}
                        action={lockActionMulti}
                        lockMethod={1} // unlock
                        isDisabled={selection.length <= 0}
                    ></WeekdayMenu>
                    <WeekdayMenu
                        Icon={SwapVertIcon}
                        tooltip={<Translate id="buttonBar.toggle"></Translate>}
                        action={lockActionMulti}
                        lockMethod={2} // toggle
                        isDisabled={selection.length <= 0}
                    ></WeekdayMenu>
                    <Tooltip enterDelay={500} title={<Translate id="buttonBar.delete"></Translate>}>
                        <IconButton
                            id="menuButton_Delete"
                            style={{ color: (selection.length <= 0 ? customColors.disabled : customColors.danger), margin: '2px', padding: '15px' }}
                            onClick={() => onArchive()}
                            disabled={selection.length <= 0}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                
                <PresetDialog
                    getGeoFenceData={getGeoFenceData}
                    addGeoFenceInState={addGeoFenceInStateWithInvertedLatLngs}
                    currentUser={currentUser}
                ></PresetDialog>
                <Tooltip enterDelay={500} title={<Translate id={ searchMode ? "buttonBar.searchOff" : "buttonBar.searchOn" }></Translate>}>
                    <IconButton
                        id="menuButton_Search"
                        onClick={toggleSearchMode}
                        style={{ color: (searchMode ? customColors.bright : customColors.dark), backgroundColor: (searchMode ? customColors.ilogsBlue : ''), margin: '2px', marginLeft: '-10px', padding: '15px' }}
                    >
                        <SearchIcon />
                    </IconButton>
                </Tooltip>
                <PolygonColorMenu
                    setPolygonColor={setPolygonColor}
                    open={colorPickerOpen}
                    setColorPickerOpen={setColorPickerOpen}
                    tooltip={<Translate id="buttonBar.colorChange"></Translate>}
                >
                </PolygonColorMenu>
                <ClickAwayListener onClickAway={() => {setShowTooltip(false) }}>
                    <div>
                        <Tooltip enterDelay={500} title={<Translate id="buttonBar.help"></Translate>}>
                            <IconButton
                                id="menuButton_Help"
                                onClick={showHelp}
                                style={{ color: customColors.dark, margin: '2px', marginRight:'-18px', marginLeft: '-10px', padding: '15px' }}
                            >
                                <HelpOutlineIcon />
                            </IconButton>
                        </Tooltip>
                        <Popper key={popperKey} transition className="popper-ui" style={{ overflow: "hidden", minWidth: "600px"}} placement="top" popperOptions={{
                            modifiers: {
                                offset: {
                                    offset: "-220, 0",
                                },
                            },
                        }} open={showTooltip} anchorEl={anchorEl}>
                            <HelpTooltip resetAnchor={resetAnchor} setPopperKey={setPopperKey} navValue={popperKey}></HelpTooltip>
                        </Popper>
                    </div>
                </ClickAwayListener>
            </Toolbar>
            {archiveModal && (
                <ArchiveModal
                    title=""
                    noComment={true}
                    description={selection.length > 1
                        ? props.translate('dialogs.deleteMultiNotification')
                        : props.translate('dialogs.deleteNotification')}
                    elementId={1}
                    onClose={closeArchiveModal}
                    onArchive={() => {
                            let newGeoFences = geoFences;
                            let newSelection = [...selection];

                            for (let id of selection) {
                                try {
                                    dispatch(
                                        requestGeofenceToggleArchive({
                                            geofenceId: id
                                        })
                                    )
                                    removeFromSelection();
                                    let newGeoFences = geoFences;
                                    newGeoFences.delete(id);
                                    setGeoFences(new Map(newGeoFences));
                                } catch (e) {
                                    let geoFenceTitle = geoFences.get(id).Title;
                                    alert(props.translate('errors.couldNotToggleArchiveGeofence', { title: geoFenceTitle }));
                                    console.log(e);
                                };

                            }

                            setSelection([...newSelection]);
                            setGeoFences(new Map(newGeoFences));
                        }
                    }
                />
            )}
        </div>
    );
}

export default React.memo(withLocalize(GeoFenceButtonBar), isEqual);

function isEqual(prevProps, nextProps) {
    if (compareByReference(prevProps.selection, nextProps.selection) &&
        objAreEqual(prevProps.selection, nextProps.selection) &&
        objAreEqual(prevProps.dockSize, nextProps.dockSize) &&
        compareByReference(prevProps.searchMode, nextProps.searchMode))
    {
        return true;
    }
    return false;
}