import { Input } from "antd";
import { Translate } from "react-localize-redux";

import FormInput from "../../../common/inputs/FormInput";

import { textFieldStyles, RESERVATION_INPUT_TYPES } from "../ReservationAddModal.utils";

const ReservationAddModalStepThreeDriver = ({ purpose, comment, handleTextAreaChange }) => {
  return (
    <>
      <FormInput
        name={RESERVATION_INPUT_TYPES.purpose}
        label={
          <Translate id="reservation.addReservationModal.pageThree.purposeOfUse" />
        }
        value={purpose}
      >
        <Input.TextArea
          value={purpose}
          autoSize={{ minRows: 2, maxRows: 3 }}
          onChange={handleTextAreaChange}
          style={textFieldStyles}
          maxLength={70}
          name={RESERVATION_INPUT_TYPES.purpose}
        />
      </FormInput>
      <FormInput
        name={RESERVATION_INPUT_TYPES.comment}
        label={
          <Translate id="reservation.addReservationModal.pageThree.comment" />
        }
        value={comment}
      >
        <Input.TextArea
          value={comment}
          autoSize={{ minRows: 3, maxRows: 5 }}
          onChange={handleTextAreaChange}
          style={textFieldStyles}
          maxLength={151}
          name={RESERVATION_INPUT_TYPES.comment}
        />
      </FormInput>
    </>
  );
};

export default ReservationAddModalStepThreeDriver;