import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardContent } from "@material-ui/core";
import { menu } from "../../managers/pathManager";
import { secondsToString } from "../../managers/timeManager";
import { Translate } from "react-localize-redux";
import GenericTable from "../common/table/GenericTable";
import PropTypes from "prop-types";
import {
  customTranslation,
  getTranslation,
} from "../../translations/customTranslation";

const TripUserStatisticTable = ({ users, userMode, vehicles, me }) => {
  const userColumnInformations = [
    { title: <Translate id="dashboard.table.driver" />, key: "user.name" },
    { title: <Translate id="dashboard.table.trips" />, key: "trips" },
    { title: <Translate id="dashboard.table.duration" />, key: "duration" },
    {
      title: getTranslation(customTranslation.business, me),
      key: "businessMileageInMeters",
    },
    {
      title: <Translate id="dashboard.table.private" />,
      key: "privateMileageInMeters",
    },
    {
      title: getTranslation(customTranslation.driveToWork, me),
      key: "driveToWorkMileageInMeters",
    },
  ];

  const vehicleColumnInformations = [
    { title: <Translate id="dashboard.table.vehicle" />, key: "vehicle.name" },
    { title: <Translate id="dashboard.table.trips" />, key: "trips" },
    { title: <Translate id="dashboard.table.duration" />, key: "duration" },
    {
      title: getTranslation(customTranslation.business, me),
      key: "businessMileageInMeters",
    },
    {
      title: <Translate id="dashboard.table.private" />,
      key: "privateMileageInMeters",
    },
    {
      title: getTranslation(customTranslation.driveToWork, me),
      key: "driveToWorkMileageInMeters",
    },
  ];

  const mapData = (u) => {
    u.trips =
      Math.round(u.mileageInMeters / 1000) +
      "km (" +
      (u.businessTripCount + u.privateTripCount + u.driveToWorkTripCount) +
      " Fahrten)";
    u.duration = secondsToString(u.timeInSeconds);
    u.businessMileageInMeters =
      Math.round(u.businessMileageInMeters / 1000) +
      " km (" +
      u.businessTripCount +
      " Fahrten)";
    u.privateMileageInMeters =
      Math.round(u.privateMileageInMeters / 1000) +
      " km (" +
      u.privateTripCount +
      " Fahrten)";
    u.driveToWorkMileageInMeters =
      Math.round(u.driveToWorkMileageInMeters / 1000) +
      " km (" +
      u.driveToWorkTripCount +
      " Fahrten)";
    return u;
  };

  return (
    <Card>
      <GenericTable
        columnInformations={
          userMode ? vehicleColumnInformations : userColumnInformations
        }
        data={
          userMode
            ? vehicles.map((v) => mapData(v))
            : users.map((u) => mapData(u))
        }
      />
    </Card>
  );
};

function mapStateToProp(state) {
  return {
    me: state.users.me,
    users: state.reports.users,
    vehicles: state.reports.vehicles,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

// TripUserStatisticTable.propTypes = {
//   userMode: PropTypes.bool.isRequired,
// };

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(TripUserStatisticTable);
