import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {Select} from "antd";

import CustomSelect from "../Select";
import {requestDriveboxById, requestDriveboxes} from "../../../../actions/actionDriveboxes";
import {requestMandatorDriveboxes} from "../../../../actions/actionMandators";
import {usePagination} from "../../../../hooks/usePagination";
import {Translate} from "react-localize-redux";

const {Option, OptGroup} = Select;
const DriveboxSelect = props => {
    const [searchTerm, setSearchTerm] = useState("");
    const {page, rowsPerPage} = usePagination();
    const {isSuperUser, filter, onChange, driveboxes, value, disabled, imeiAsValue, showGroups, allowClear} = props;

    useEffect(() => {
        if (value && !isSuperUser) props.requestDriveboxById(value);
    }, [value]);

    useEffect(() => {
        requestDriveboxes();
    }, [filter, searchTerm, page, rowsPerPage]);

    function requestDriveboxes() {
        const filterProps = {
            searchTerm,
            page: page + 1,
            perPage: rowsPerPage,
            withoutMandator: false,
        };

        if (isSuperUser) {
            props.requestMandatorDriveboxes(filterProps);
        } else {
            props.requestDriveboxes(filterProps);
        }
    }

    if (showGroups) {
        const driveboxesWithoutCar = driveboxes.filter(d => !d.vehicle);
        const driveboxesWithCar = driveboxes.filter(d => d.vehicle);
        return (
            <CustomSelect value={value}
                          placeholder={<Translate id="common.select.driveboxes"/>}
                          handleChange={onChange}
                          handleSearch={setSearchTerm}
                          disabled={disabled}
                          allowClear={allowClear}>
                <OptGroup label={<Translate id="common.select.withoutCar"/>}>
                    {driveboxesWithoutCar.map(drivebox => (
                        <Option key={imeiAsValue ? drivebox.imei : drivebox.id}
                                value={imeiAsValue ? drivebox.imei : drivebox.id}>
                            {drivebox.imei}
                        </Option>
                    ))}
                </OptGroup>
                <OptGroup label={<Translate id="common.select.withCar"/>}>
                    {driveboxesWithCar.map(drivebox => (
                        <Option key={imeiAsValue ? drivebox.imei : drivebox.id}
                                value={imeiAsValue ? drivebox.imei : drivebox.id}>
                            {drivebox.imei}
                        </Option>
                    ))}
                </OptGroup>
            </CustomSelect>
        );
    }
    return (
        <CustomSelect value={value} 
                      placeholder={<Translate id="common.select.driveboxes"/>}
                      handleChange={onChange}
                      handleSearch={setSearchTerm}
                      disabled={disabled}>
            {driveboxes.map(drivebox => (
                <Option key={imeiAsValue ? drivebox.imei : drivebox.id}
                        value={imeiAsValue ? drivebox.imei : drivebox.id}>
                    {drivebox.imei}
                </Option>
            ))}
        </CustomSelect>
    );
};

DriveboxSelect.defaultProps = {
    disabled: false,
    imeiAsValue: false,
    isSuperUser: false,
    showGroups: false,
    allowClear: false
};

// DriveboxSelect.propTypes = {
//     onChange: PropTypes.func.isRequired,
//     value: PropTypes.string.isRequired,
//     imeiAsValue: PropTypes.bool,
//     isSuperUser: PropTypes.bool,
//     showGroups: PropTypes.bool,
//     disabled: PropTypes.bool,
//     allowClear: PropTypes.bool
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({requestDriveboxes, requestMandatorDriveboxes, requestDriveboxById}, dispatch);
}

function mapStateToProp(state) {
    return {
        driveboxes: state.driveboxes.entities,
        drivebox: state.driveboxes.current,
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(DriveboxSelect);
