import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import classNames from "classnames";
import { menu } from "../../../managers/pathManager";
// react-router
import { Link } from "react-router-dom";
// material-ui
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

// Icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { allowedRoles, isAuthorized } from "../../../managers/authManager";
import { roles } from "../../../models/Role";
import { packages } from "../../../models/Package";
import { Tooltip } from "antd";
import { Translate } from "react-localize-redux";
import FontAwesomeIcon from "../views/FontAwesomeIcon";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCartOutlined";
import StreetviewIcon from "@material-ui/icons/Streetview";

const drawerWidth = 240;

const styles = (theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
  },
  drawerTitleContainer: {
    width: "100%",
  },
  drawerTitle: {
    textAlign: "left",
    marginLeft: "64px",
  },
  activeLink: {
    backgroundColor: "#EBEBEB",
    //color: 'rgba(0, 0, 0, 0.87)',
    color: theme.palette.primary.main,
    fontSize: "1rem",
    fontWeight: "500",
    fontFamily: "Roboto",
    lineHeight: "1.5em",
  },
  activeIcon: {
    color: theme.palette.primary.main,
  },
  link: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1rem",
    fontWeight: "400",
    fontFamily: "Roboto",
    lineHeight: "1.5em",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  infoItem: {
    marginTop: "auto",
    "& .MuiListItemText-root": {
      fontSize: ".8rem",
    },
  },
});

const AppBarDrawer = (props) => {
  const { classes, activePage, me, open, onDrawerClose, status } = props;

  function renderRowData() {
    if (isAuthorized(me, [roles.SuperAdmin]))
      return renderListItem(
        menu.rowData,
        <FontAwesomeIcon>far fa-database</FontAwesomeIcon>,
        "Rowdata"
      );
  }

  function renderMandatorSuperUser() {
    if (isAuthorized(me, [roles.SuperAdmin]))
      return renderListItem(
        menu.mandatorSuperUser,
        <FontAwesomeIcon>far fa-user-tie</FontAwesomeIcon>,
        <Translate id="common.menu.superUser" />
      );
  }

  function renderMandatorUsers() {
    if (isAuthorized(me, [roles.SuperAdmin]))
      return renderListItem(
        menu.mandatorUser,
        <FontAwesomeIcon>far fa-user</FontAwesomeIcon>,
        <Translate id="common.menu.user" />
      );
  }

  function renderMandatorDrivebox() {
    if (isAuthorized(me, [roles.SuperAdmin]))
      return renderListItem(
        menu.mandatorDrivebox,
        <FontAwesomeIcon>far fa-sim-card</FontAwesomeIcon>,
        <Translate id="common.menu.driveboxes" />
      );
  }

  function renderMandator() {
    if (isAuthorized(me, [roles.SuperAdmin]))
      return renderListItem(
        menu.mandator,
        <FontAwesomeIcon>far fa-building</FontAwesomeIcon>,
        <Translate id="common.menu.mandator" />
      );
  }

  function renderHWNODashBoard() {
    if (me.isHWNOE && isAuthorized(me, allowedRoles(roles.DriverViewer)))
      return renderListItem(
        menu.hwnodashboard,
        <FontAwesomeIcon>far fa-chart-bar</FontAwesomeIcon>,
        <Translate id="common.menu.hwnodashboard" />
      );
  }

  function renderDashBoard() {
    if (isAuthorized(me, allowedRoles(roles.DriverViewer)))
      return renderListItem(
        menu.dashboard,
        <FontAwesomeIcon>far fa-chart-pie</FontAwesomeIcon>,
        <Translate id="common.menu.dashboard" />
      );
  }

  function renderCalendar() {
    if (
      isAuthorized(me, allowedRoles(roles.GroupLeaderViewer), [
        packages.Logbook,
      ])
    )
      return renderListItem(
        menu.calendar,
        <FontAwesomeIcon>far fa-calendar-alt</FontAwesomeIcon>,
        <Translate id="common.menu.calendar" />
      );
  }

  function renderTrips() {
    if (isAuthorized(me, allowedRoles(roles.DriverViewer), [packages.Logbook]))
      return renderListItem(
        menu.trips,
        <FontAwesomeIcon>far fa-book</FontAwesomeIcon>,
        <Translate id="common.menu.logbook" />
      );
  }

  function renderDayView() {
    if (isAuthorized(me, allowedRoles(roles.DriverViewer), [packages.Tracking]))
      return renderListItem(
        menu.dayView,
        <FontAwesomeIcon>far fa-calendar-day</FontAwesomeIcon>,
        <Translate id="common.menu.dayview" />
      );
  }

  function renderLivelocation() {
    if (
      isAuthorized(me, allowedRoles(roles.GroupLeaderViewer), [
        packages.Tracking,
      ])
    )
      return renderListItem(
        menu.livelocation,
        <FontAwesomeIcon>far fa-search-location</FontAwesomeIcon>,
        <Translate id="common.menu.liveLocation" />
      );
  }

  function renderTimeReporting() {
    if (isAuthorized(me, allowedRoles(roles.Driver), [packages.TimeLog]))
      return renderListItem(
        menu.timeReporting,
        <FontAwesomeIcon>far fa-clock</FontAwesomeIcon>,
        "Lenkprotokoll"
      );
  }

  function renderReports() {
    if (isAuthorized(me, allowedRoles(roles.AdminViewer)))
      return renderListItem(
        menu.reporting,
        <FontAwesomeIcon>far fa-file-chart-pie</FontAwesomeIcon>,
        <Translate id="common.menu.reporting" />
      );
  }

  function renderUser() {
    if (isAuthorized(me, allowedRoles(roles.DriverLeader)))
      return renderListItem(
        menu.users,
        <FontAwesomeIcon>far fa-user</FontAwesomeIcon>,
        <Translate id="common.menu.user" />
      );
  }

  function renderVehicle() {
    if (isAuthorized(me, allowedRoles(roles.DriverLeader)))
      return renderListItem(
        menu.vehicles,
        <FontAwesomeIcon>far fa-car</FontAwesomeIcon>,
        <Translate id="common.menu.vehicles" />
      );
  }

  function renderAdminsettings() {
    if (isAuthorized(me, allowedRoles(roles.AdminViewer)))
      return renderListItem(
        menu.groups,
        <FontAwesomeIcon fontSize={19} minWidth={20}>
          far fa-cog
        </FontAwesomeIcon>,
        <Translate id="common.menu.adminsettings" />
      );
  }

  function renderDrivebox() {
    if (isAuthorized(me, allowedRoles(roles.AdminViewer)))
      return renderListItem(
        menu.driveboxes,
        <FontAwesomeIcon>far fa-sim-card</FontAwesomeIcon>,
        <Translate id="common.menu.driveboxes" />
      );
  }

  function renderOrder() {
    if (isAuthorized(me, allowedRoles(roles.Admin)))
      return renderListItem(
        menu.order,
        <ShoppingCartIcon color="secondary" />,
        <Translate id="common.menu.order" />
      );
  }

  function renderInfo() {
    return renderListItem(
      menu.help,
      <FontAwesomeIcon>far fa-code-branch</FontAwesomeIcon>,
      "Version: " + status.version,
      true
    );
  }

  function renderGeofences() {
    if (
      isAuthorized(me, allowedRoles([roles.Admin, roles.Driver]), [
        packages.Geofences,
      ]) && (me.role.name !== roles.DriverLeader)
    ) {
      let menuData = menu.geofences;
      if (me.role.name == roles.Driver) menuData = menu.geofencesReport;
      return renderListItem(
        menuData,
        <StreetviewIcon />,
        <Translate id="common.menu.geofences" />
      );
    }
  }

  function renderReservation() {
    if (isAuthorized(me, allowedRoles(roles.DriverViewer), [packages.Reservations]))
    {
      return renderListItem(
        menu.reservation,
        <FontAwesomeIcon>far fa-calendar</FontAwesomeIcon>,
        <Translate id="common.menu.reservation" />
      );
    }
  }

  function renderQualityCharging() {
    if (isAuthorized(me, allowedRoles(roles.Admin), [packages.QualityCharging]))
    {
      return renderListItem(
        menu.qualityCharging,
        <FontAwesomeIcon>fa fa-bolt</FontAwesomeIcon>,
        <Translate id="common.menu.qualityCharging" />
      );
    }
  }

  function renderFaultyTrips() {
    if (isAuthorized(me, [roles.SuperAdmin]))
    {
      return renderListItem(
        menu.faultyTrips,
        <FontAwesomeIcon>fa fa-exclamation-circle</FontAwesomeIcon>,
        <Translate id="common.menu.faultyTrips" />
      );
    }
  }

  function renderListItem(menu, icon, text, disable) {
    const listItem = (
      <ListItem
        className={classNames({
          [classes.activeLink]: activePage === menu.key,
          [classes.link]: activePage !== menu.key,
        })}
        component={disable ? "li" : Link}
        to={menu.path}
        button={!disable}
        key={menu.key}
      >
        <ListItemIcon
          className={classNames({
            [classes.activeIcon]: activePage === menu.key,
          })}
        >
          {icon}
        </ListItemIcon>

        <ListItemText disableTypography primary={text} />
      </ListItem>
    );

    return !open ? (
      <Tooltip title={text} placement="right">
        {listItem}
      </Tooltip>
    ) : (
      listItem
    );
  }
  return (
    <Drawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
    >
      <div className={classes.toolbar}>
        <Fade in={open}>
          <div className={classes.drawerTitleContainer}>
            <Typography
              className={classes.drawerTitle}
              variant="h6"
              color="inherit"
              noWrap
            >
              DRiVEBOX
            </Typography>
          </div>
        </Fade>
        <IconButton onClick={onDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {renderDashBoard()}
        {renderHWNODashBoard()}
        {renderTrips()}
        {renderDayView()}
        {renderCalendar()}
        {renderLivelocation()}
        {renderTimeReporting()}
        {renderReports()}

        {renderMandator()}
        {renderMandatorDrivebox()}
        {renderMandatorUsers()}

        {renderGeofences()}
      </List>
      <Divider />

      <List>
        {renderUser()}
        {renderVehicle()}
        {renderAdminsettings()}
        {renderDrivebox()}

        {renderRowData()}
        {renderMandatorSuperUser()}
        {renderReservation()}
        {renderQualityCharging()}
        {renderFaultyTrips()}
      </List>
      <Divider />
      <List className={classes.infoItem}>
        {/*renderOrder()*/}
        {renderInfo()}
      </List>
    </Drawer>
  );
};

function mapStateToProp(state) {
  return {
    me: state.users.me,
    status: state.status,
  };
}

export default connect(mapStateToProp)(withStyles(styles)(AppBarDrawer));
