import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import EditModal from "../../common/modals/CustomModal";
import {Translate} from "react-localize-redux";
import Map from "../../common/map/Map";
import {useSelector} from "react-redux";
import {
    Card,
    CardContent,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Typography
} from "@material-ui/core";
import useTrackModalStyle from "./useTrackModalStyle";
import OriginDestinationTimeline from "../OriginDestinationTimeline";
import {allowedRoles, isAuthorized} from "../../../managers/authManager";
import {roles} from "../../../models/Role";
import EditIcon from "@material-ui/icons/Edit";
import SplitIcon from "@material-ui/icons/CallSplit";
import Slider from "../../common/inputs/Slider";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";


const OpenTrackModal = props => {
    const classes = useTrackModalStyle();
    const {onClose} = props;
    const trip = useSelector(state => state.vehicles.openTrip);
    const error = useSelector(state => state.error);
    const me = useSelector(state => state.users.me);
 
    const [showTrack, setShowTrack] = useState(me.canShowTracks);

    const track = trip.positions || [];

    const startEndTrack = [
        {latitude: trip.startLatitude, longitude: trip.startLongitude},
        {latitude: trip.destinationLatitude, longitude: trip.destinationLongitude},
    ];

    const mappedTrip = trip;
    mappedTrip.positions = showTrack ? track : startEndTrack;
    mappedTrip.driver = trip.currentDriver;

    useEffect(() => {
        if (error.status === 404) {
            setShowTrack(false);
            props.clearError();
        }
    }, [error]);

    const viewContent = translate => (
        <Grid container className={classes.innerContainer}>
            <Grid item xs={12}>
                <OriginDestinationTimeline trip={mappedTrip} showDetailInfo/>
            </Grid>
        </Grid>
    );

    const leftColumn = translate => viewContent(translate);
    const map = <Map trips={[mappedTrip]} automaticHeight={false} showTrackStartStopMarker/>;

    const contents = [
        <Translate>
            {({translate}) =>
                <Grid container>
                    <Grid item xs={3} className={classes.leftColumn}>
                        {leftColumn(translate)}
                    </Grid>
                    <Grid item xs={9} style={{height: "100%"}}>
                        {map}
                    </Grid>
                </Grid>}
        </Translate>,
    ];
    return <EditModal largeModal
                      onClose={onClose}
                      title="Offene Fahrt"
                      contents={contents}
                      className={classes.modal}/>;
};

// OpenTrackModal.propTypes = {
//     onClose: PropTypes.func.isRequired,

// };

export default OpenTrackModal;
