import React from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";

import PropTypes from "prop-types";

import FormModal from "../../common/modals/FormModal";

import TreeSelect from "../../common/selects/specificSelects/TreeSelect";
import { setFilter } from "../../../actions/actionFilter";
import { getIdsFromGroupValues } from "../../../managers/groupManager";

import FormBuilder from "../../../managers/formBuilder";
import { Translate } from "react-localize-redux";

import {allowedRoles, isAuthorized, isViewer} from "../../../managers/authManager";
import {packages} from "../../../models/Package";
import {roles} from "../../../models/Role";
import CostCenterSelect from "../../common/selects/specificSelects/CostCenterSelect";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";
import { MODAL_TYPES } from "../../common/modals/modal.utils";

const getDefaultInputValues = (filter) => ({
    groups: filter ? filter.initial.groups : "",
    costcenters: filter ? filter.initial.costcenters : "",
});

const UserFilterModal = props => {
    const {onClose, tree, filter} = props;

    const { handleInputChange, fields } = useInputChangeHandler(getDefaultInputValues(filter));

    function handleFilter() {
        const apiFilter = {
            groupIds: getIdsFromGroupValues(tree, fields.groups),
            costcenterIds: fields.costcenters,
        };
        props.setFilter(fields, apiFilter);
        props.onClose();
    }

    const me = useSelector(state => state.users.me);

    const inputs = [
        new FormBuilder.FormInput(<Translate id="user.filtermodal.groups"/>, <TreeSelect value={fields.groups} onChange={(value) => handleInputChange("groups", value)} />)
            .withValue(fields.groups)
            .withKey("groups")
            .build(),
    ];

    if (isAuthorized(me, allowedRoles(roles.Driver), [packages.CostCenter])) 
        inputs.push(new FormBuilder.FormInput(<Translate id="user.filtermodal.costCenters"/>, <CostCenterSelect value={fields.costcenters} onChange={(value) => handleInputChange("costcenters", value)} multiple showArchived={false}/>)
            .withValue(fields.costcenters)
            .withKey("costcenters")
            .build());

    return <FormModal action="filter" formType={MODAL_TYPES.withoutValidation} inputs={inputs} onSave={handleFilter} onClose={onClose} title="Benutzer filtern"/>;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setFilter}, dispatch);
}

function mapStateToProp(state) {
    return {tree: state.tree, filter: state.filter};
}

// UserFilterModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
// };

export default connect(
    mapStateToProp,
    mapDispatchToProps
)(UserFilterModal);
